import { Formik } from 'formik';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import GetStartedForm from '@/components/forms/getStarted/GetStartedForm';
import GetStartedModifyForm from '@/components/forms/getStarted/GetStartedModifyForm';
import ThreeDotsLoader from '@/components/loaders/ThreeDotsLoader';
import RedirectAfterErrorModal from '@/components/modals/RedirectAfterErrorModal';
import useHandleSubmit from '@/hooks/useHandleSubmit';
import GetStartedFormModel, {
    getStartedFormSchema,
} from '@/models/GetStartedFormModel';
import GetStartedModifyFormModel, {
    getStartedModifyFormSchema,
} from '@/models/GetStartedModifyFormModel';
import { useTrackStartedSubscriptionProcess } from '@/services/dataLayer';
import {
    useModifyGetStarted,
    useNewSubcriptionGetStarted,
    useSubscribeGetStarted,
} from '@/services/deliveryZoneService';
import {
    useKlaviyoStartedSubscriptionProcess, // useKlaviyoSubmitGetStartedForm,
} from '@/services/klaviyo';
import {
    selectLoading,
    setLoading,
} from '@/store/getStarted/getStartedLoadingSlice';
import { setStep } from '@/store/getStarted/getStartedSlice';
import { useUser } from '@/store/user/userSlice';

const OGImage = `${window.cdn}smo/SMO-homepage.png`;

export default function GetStartedPage() {
    const [user] = useUser();
    const dispatch = useDispatch();
    const loading = useSelector(selectLoading);
    const { mutateAsync: subscribeGetStarted } = useSubscribeGetStarted();
    const { mutateAsync: modifyGetStarted } = useModifyGetStarted(
        user?.subscription?.id
            ? user?.subscription?.id
            : localStorage.getItem('subscriptionId')!
    );
    const { mutateAsync: newSubscriptionGetStarted } =
        useNewSubcriptionGetStarted();

    const [cookies, , removeCookie] = useCookies(['promo']);

    const promo = cookies?.promo?.toUpperCase();

    const trackStartedSubscriptionProcess = useTrackStartedSubscriptionProcess;
    const klaviyoStartedSubscriptionProcess =
        useKlaviyoStartedSubscriptionProcess;
    // const klaviyoSubmitGetStartedForm = useKlaviyoSubmitGetStartedForm;

    window.onpageshow = function resetLoading(event) {
        if (event.persisted) {
            dispatch(setLoading(false));
        }
    };

    const handleSubmit = useHandleSubmit(
        getStartedFormSchema,
        subscribeGetStarted,
        () => {
            // klaviyoSubmitGetStartedForm();
            if (cookies?.promo) {
                removeCookie('promo', {
                    path: '/',
                });
            }
            window.location.href = '/get-started/your-box';
        },
        dispatch
    );

    const handleModifySubmit = useHandleSubmit(
        getStartedModifyFormSchema,
        modifyGetStarted,
        () => {
            // klaviyoSubmitGetStartedForm();
            window.location.href = '/get-started/your-box';
        },
        dispatch
    );

    const handleNewSubscriptionSubmit = useHandleSubmit(
        getStartedModifyFormSchema,
        newSubscriptionGetStarted,
        () => {
            // klaviyoSubmitGetStartedForm();
            window.location.href = '/get-started/your-box';
        },
        dispatch
    );

    useEffect(() => {
        dispatch(setStep({ index: 0, label: 'Welcome' }));
        // console.log('user::', user);
        if (!user.hasPassword) {
            trackStartedSubscriptionProcess();
            klaviyoStartedSubscriptionProcess(user);
        }

        localStorage.setItem('newuser', `${!user.hasPassword}`);
    }, [
        dispatch,
        user,
        trackStartedSubscriptionProcess,
        klaviyoStartedSubscriptionProcess,
    ]);

    return (
        <div className="relative">
            <Helmet>
                <title>Get started | ButcherCrowd</title>
                <meta
                    name="description"
                    content="Curated or customised meat & seafood boxes delivered for your everyday cooking essentials"
                />
                <meta
                    property="og:title"
                    content="Get started | ButcherCrowd"
                />
                <meta
                    property="og:description"
                    content="Curated or customised meat & seafood boxes delivered for your everyday cooking essentials"
                />
                <meta property="og:image" content={OGImage} />
            </Helmet>
            <ThreeDotsLoader visible={loading} />
            <div className="flex flex-col items-center max-w-3xl px-4 py-12 mx-auto sm:px-8 lg:py-28">
                <h1 className="text-4xl font-medium uppercase mb-7 lg:text-5xl">
                    {user.email ? 'Delivery' : 'Get started'}
                </h1>
                <p className="mb-4 max-w-[600px] text-center text-xl font-medium sm:text-2xl lg:mb-6 lg:max-w-[800px] lg:text-3xl">
                    Curated or customised meat & seafood boxes delivered for
                    your everyday cooking essentials.
                </p>
                <p className="mb-10 text-lg text-center md:mx-32">
                    Please help us with a few details on your order, then you
                    can begin making your box selections.
                </p>
                {user?.email &&
                    user?.subscription === null &&
                    !localStorage.getItem('subscriptionId') && (
                        <Formik<Partial<GetStartedModifyFormModel>>
                            validationSchema={getStartedModifyFormSchema}
                            initialValues={{
                                shippingDateStartedAt: '',
                                shippingStartedAt: '',
                                deliveryScheduleId: '',
                            }}
                            onSubmit={handleNewSubscriptionSubmit}
                        >
                            <GetStartedModifyForm />
                        </Formik>
                    )}

                {user?.email &&
                    (user?.subscription !== null ||
                        localStorage.getItem('subscriptionId')) && (
                        <Formik<Partial<GetStartedModifyFormModel>>
                            validationSchema={getStartedModifyFormSchema}
                            initialValues={{
                                shippingDateStartedAt: '',
                                shippingStartedAt: '',
                                deliveryScheduleId: '',
                            }}
                            onSubmit={handleModifySubmit}
                        >
                            <GetStartedModifyForm />
                        </Formik>
                    )}

                {!user?.email && (
                    <Formik<Partial<GetStartedFormModel>>
                        validationSchema={getStartedFormSchema}
                        initialValues={{
                            shippingDateStartedAt: '',
                            shippingStartedAt: '',
                            deliveryScheduleId: '',
                            code: promo,
                        }}
                        onSubmit={handleSubmit}
                    >
                        <GetStartedForm />
                    </Formik>
                )}
            </div>
            <RedirectAfterErrorModal />
        </div>
    );
}
