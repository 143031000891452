import { useState } from 'react';
import Button from '@/components/common/Button';
import { CheckPrimarySolid } from '@/components/common/Icons';
import useCopyToClipboard from '@/hooks/useCopyToClipboard';

interface Props {
    label: string;
}

export default function ShareButton({ label }: Props) {
    const [, copy] = useCopyToClipboard();
    const [currentHref] = useState<string>(window.location.href);

    const [showSuccessMessage, setShowSuccessMessage] =
        useState<boolean>(false);

    const handleCopy = () => {
        copy(`${currentHref}`);
        setShowSuccessMessage(true);
        setTimeout(() => {
            setShowSuccessMessage(false);
        }, 3000);
    };
    return (
        <div className="relative mt-16 mb-16 flex justify-center">
            <Button onClick={handleCopy}>{label}</Button>
            {showSuccessMessage && (
                <p className="absolute bottom-[-40px] left-0 inline-flex w-full items-center justify-center space-x-4 uppercase">
                    <CheckPrimarySolid />
                    <span>URL Copied !</span>
                </p>
            )}
        </div>
    );
}
