// import Swiper core and required modules
import { Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/grid';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import ProductCard from '@/components/cards/ProductCard';

type Product = {
    id: string | number;
    name: string;
    img: string;
    alt: string;
    isNew: boolean;
};

type ProductCarrouselProps = {
    products: Product[];
};

export default function ProductCarrousel({ products }: ProductCarrouselProps) {
    return (
        <div>
            {/* Desktop swiper */}
            <Swiper
                modules={[Autoplay]}
                slidesPerView="auto"
                loopedSlides={8}
                loop
                spaceBetween={15}
                speed={4000}
                centeredSlides
                grabCursor
                autoplay={{
                    delay: 1,
                    disableOnInteraction: false,
                }}
                className="linear-swiper px-8 pt-2 pb-1"
            >
                {products.map((item) => (
                    <SwiperSlide
                        key={item.id}
                        className="!w-5/6 max-w-sm md:!w-60 lg:!w-72 lg:max-w-none"
                    >
                        <ProductCard
                            name={item.name}
                            imgSrc={item.img}
                            imgAlt={item.alt}
                            isNew={item.isNew}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}
