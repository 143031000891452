import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ProductSelectCard from '@/components/cards/ProductSelectCard';
import SummaryCard from '@/components/cards/SummaryCard';
import ThreeDotsLoader from '@/components/loaders/ThreeDotsLoader';
import RedirectAfterErrorModal from '@/components/modals/RedirectAfterErrorModal';
import ProductModel from '@/models/ProductModel';
import { useTrackRemoveCart, useTrackViewItemList } from '@/services/dataLayer';
import { useChildPackages, useProducts } from '@/services/deliveryZoneService';
import {
    useKlaviyoInitiateChooseItems,
    useKlaviyoRequestError,
} from '@/services/klaviyo';
import {
    setUnauthenticated,
    setUnexpectedError,
} from '@/store/getStarted/getStartedErrorSlice';
import {
    selectLoading,
    setLoading,
} from '@/store/getStarted/getStartedLoadingSlice';
import { setStep } from '@/store/getStarted/getStartedSlice';
import { useUser } from '@/store/user/userSlice';
import { selectItemsIds } from '@/store/yourItems/yourItemsSlice';
import { privateListen } from '@/utils/broadCastEvent';
import queryClient from '@/utils/queryClient';

export default function ChooseItemsPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [user] = useUser();
    const newUser = localStorage.getItem('newuser');
    const [packageId, setPackageId] = useState(user?.subscription?.package);
    const loading = useSelector(selectLoading);
    const { productsResult, error: getProductsError } = useProducts(
        user?.subscription?.id,
        packageId
    );
    const { childPackagesResult } = useChildPackages(
        user?.subscription?.id,
        user?.subscription?.root
    );
    const currentPackage = childPackagesResult?.packages?.filter(
        (e: any) => e.id === user?.subscription?.package
    );

    const itemsById = useMemo(
        () =>
            productsResult?.reduce(
                (obj, item) => ({ ...obj, [item.id]: item }),
                {} as { [id: number]: any }
            ),
        [productsResult]
    );
    const selectedItemIds = useSelector(selectItemsIds);
    const selectedItems = useMemo(
        () => selectedItemIds.map((id: any) => itemsById?.[id]),
        [itemsById, selectedItemIds]
    );

    const [maxCount, setMaxCount] = useState(0);

    const trackRemoveCart = useTrackRemoveCart;
    const trackViewItemList = useTrackViewItemList;
    const klaviyoInitiateChooseItems = useKlaviyoInitiateChooseItems;
    const klaviyoRequestError = useKlaviyoRequestError;

    window.onpageshow = function resetLoading(event) {
        if (event.persisted) {
            dispatch(setLoading(false));
            privateListen(
                user?.subscription?.id,
                'UpdatedSubscriptionChoices',
                '/get-started/addons',
                'UpdatedSubscriptionChoices'
            );
            privateListen(
                user?.subscription?.id,
                'SubscriptionCheckedOut',
                `/dashboard/my-deliveries?newuser=${newUser}`,
                'SubscriptionCheckedOut'
            );
            privateListen(
                user?.subscription?.id,
                'SubscriptionCheckoutError',
                `/dashboard/my-deliveries?newuser=${newUser}`,
                'SubscriptionCheckoutError',
                dispatch
            );
        }
    };

    useEffect(() => {
        // Redirect to Your box if page is not customizable
        if (
            childPackagesResult &&
            !childPackagesResult?.selection?.customizable
        ) {
            navigate('/get-started/your-box');
        } else if (
            childPackagesResult &&
            childPackagesResult?.selection?.customizable
        ) {
            klaviyoInitiateChooseItems(user);
        }

        queryClient.invalidateQueries(['products']);
        setMaxCount(currentPackage?.[0]?.selection?.maximum);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [childPackagesResult, navigate, user, klaviyoInitiateChooseItems]);

    const productsByCategory = useMemo(
        () =>
            productsResult?.reduce<{ [category: string]: ProductModel[] }>(
                (obj: any, product: any) => {
                    if (product.inventory === null || product.inventory > 0) {
                        return {
                            ...obj,
                            [product.category]: [
                                ...(obj[product.category] || []),
                                product,
                            ].sort((a, b) => a.name.localeCompare(b.name)),
                        };
                    }
                    return obj;
                },
                {}
            ),
        [productsResult]
    );

    const handleSizeChange = (value: any) => {
        setMaxCount(value);
    };

    useEffect(() => {
        if (productsResult) {
            trackViewItemList(productsResult, 'Products');
        }
    }, [productsResult, trackViewItemList]);

    useEffect(() => {
        if ((getProductsError as any)?.response?.status) {
            klaviyoRequestError(user, getProductsError);
            if (
                (getProductsError as any)?.response?.status === 401 ||
                (getProductsError as any)?.response?.status === 419
            ) {
                dispatch(setUnauthenticated(true));
            } else {
                dispatch(setUnexpectedError(true));
            }
        }
        privateListen(
            user?.subscription?.id,
            'UpdatedSubscriptionChoices',
            '/get-started/addons',
            'UpdatedSubscriptionChoices'
        );
        dispatch(setStep({ index: 2, label: 'Your items' }));
    }, [
        dispatch,
        user?.subscription?.id,
        getProductsError,
        user,
        klaviyoRequestError,
    ]);

    useEffect(() => {
        if (!user?.subscription) navigate('/get-started');
        if (localStorage.getItem('packageId')) {
            if (user?.subscription?.id !== localStorage.getItem('packageId')) {
                localStorage.removeItem('packageId');
                window.location.reload();
            }
        }
    }, [user?.subscription, navigate]);

    // Do not render the page, we will navigate back shortly.
    if (!user?.subscription) return null;

    return (
        <>
            <Helmet>
                <title>Custom Box | ButcherCrowd</title>
                <meta
                    name="description"
                    content="Curated or customised meat & seafood boxes delivered for your everyday cooking essentials"
                />
                <meta property="og:title" content="Custom Box | ButcherCrowd" />
            </Helmet>
            <div className="relative px-8 py-12 xs:px-4 sm:px-8 lg:py-28">
                <ThreeDotsLoader visible={loading} />
                <header className="mx-auto flex max-w-3xl flex-col items-center ">
                    <h1 className="mb-7 text-4xl font-medium uppercase lg:text-5xl">
                        Custom box
                    </h1>
                    {productsByCategory && (
                        <div className="flex flex-wrap justify-center">
                            {Object.keys(productsByCategory).map((category) => (
                                <a
                                    key={category}
                                    href={`#${category}`}
                                    className="m-1.5 bg-secondary px-5 py-1.5 font-agrandir text-sm font-bold uppercase text-white transition-colors hover:bg-darkenPrimary"
                                >
                                    {category}
                                </a>
                            ))}
                        </div>
                    )}

                    <p className="mb-16 mt-14 text-center lg:text-lg">
                        Build your own box that suits your lifestyle. From meal
                        prep to entertaining, we&apos;ve got you covered.
                        You&apos;ll have endless variety when you select from
                        40+ cuts, with the option to switch it up every order.
                    </p>
                </header>
                <div className="flex w-full items-start justify-end">
                    {productsByCategory && (
                        <div className="grow lg:mr-6">
                            {Object.entries(productsByCategory).map(
                                ([key, products]) => (
                                    <section key={key} id={key}>
                                        <h4 className="border-b-[5px] border-secondary py-3 font-agrandir text-sm font-bold uppercase tracking-wide">
                                            {key}
                                        </h4>
                                        {user?.subscription?.postcode?.startsWith(
                                            '7'
                                        ) ? (
                                            <div className="grid grid-cols-1 gap-6 py-12 xs:grid-cols-2 xs:gap-2.5 sm:gap-6 xl:grid-cols-3 2xl:grid-cols-4">
                                                {products.map(
                                                    (product: any) => {
                                                        if (
                                                            (product?.inventory ===
                                                                null ||
                                                                product?.inventory >
                                                                    0) &&
                                                            !product?.name
                                                                .toLowerCase()
                                                                .includes(
                                                                    'salmon'
                                                                )
                                                        ) {
                                                            return (
                                                                <ProductSelectCard
                                                                    key={
                                                                        product.id
                                                                    }
                                                                    product={
                                                                        product
                                                                    }
                                                                    maxCount={
                                                                        maxCount
                                                                    }
                                                                    removeCart={
                                                                        trackRemoveCart
                                                                    }
                                                                />
                                                            );
                                                        }
                                                        return null;
                                                    }
                                                )}
                                            </div>
                                        ) : (
                                            <div className="grid grid-cols-1 gap-6 py-12 xs:grid-cols-2 xs:gap-2.5 sm:gap-6 xl:grid-cols-3 2xl:grid-cols-4">
                                                {products.map(
                                                    (product: any) => {
                                                        if (
                                                            product?.inventory ===
                                                                null ||
                                                            product?.inventory >
                                                                0
                                                        ) {
                                                            return (
                                                                <ProductSelectCard
                                                                    key={
                                                                        product.id
                                                                    }
                                                                    product={
                                                                        product
                                                                    }
                                                                    maxCount={
                                                                        maxCount
                                                                    }
                                                                    removeCart={
                                                                        trackRemoveCart
                                                                    }
                                                                />
                                                            );
                                                        }

                                                        return null;
                                                    }
                                                )}
                                            </div>
                                        )}
                                    </section>
                                )
                            )}
                        </div>
                    )}
                    <SummaryCard
                        title="Your box"
                        size={maxCount}
                        items={selectedItems}
                        allItems={itemsById}
                        boxPrice={
                            childPackagesResult?.packages?.filter(
                                (e: any) => e.id === packageId
                            )?.[0]?.price?.value
                        }
                        disabled={!productsByCategory}
                        packages={childPackagesResult?.packages}
                        onSizeChange={handleSizeChange}
                        subscriptionId={user?.subscription?.id}
                        packageId={packageId}
                        setPackageId={setPackageId}
                        removeCart={trackRemoveCart}
                        btnText="Next step"
                    />
                </div>
            </div>
            <RedirectAfterErrorModal />
        </>
    );
}
