import clsx from 'clsx';
import { PropsWithChildren } from 'react';

interface Thumbnail {
    url: string;
    alt: string;
}

interface BlogCardProps {
    title: string;
    slug: string;
    thumbnail: Thumbnail;
    fullName?: string;
    avatar?: string;
    postType: string;
    postUrl: string;
    categories?: Object[];
}

export default function BlogCard({
    title,
    slug,
    thumbnail,
    fullName,
    avatar,
    postType,
    postUrl,
    categories,
}: PropsWithChildren<BlogCardProps>) {
    return (
        <div className={clsx('relative mx-0 mt-7 border border-black md:mx-2')}>
            <div className="absolute mt-1 flex">
                <div className="ml-1 border border-black bg-black py-1 px-2.5 font-agrandir text-sm font-bold uppercase text-white">
                    {postType}
                </div>
                {categories &&
                    categories.map((category: any) => (
                        <div
                            key={category.id}
                            className="ml-1 border border-black bg-white py-1 px-2.5 font-agrandir text-sm font-bold uppercase text-black"
                        >
                            {category?.name}
                        </div>
                    ))}
            </div>
            <a href={`/${postUrl}/${slug}`}>
                <img
                    src={thumbnail.url}
                    alt={thumbnail.alt}
                    className="h-[250px] w-full object-cover"
                />
            </a>
            <div className="flex w-full flex-col items-start justify-between px-4 py-4 sm:flex-row md:flex-col md:py-6 lg:flex-row lg:px-6 xl:py-8 xl:px-8">
                <div className="w-full sm:w-[calc(100%_-_200px)] md:w-full lg:w-[calc(100%_-_190px)] xl:w-[calc(100%_-_200px)]">
                    <div className="sm:text-4-xl font-bold uppercase md:text-2xl lg:text-3xl">
                        {title}
                    </div>
                    <a
                        className="mt-2.5 inline-flex text-sm font-normal uppercase tracking-wide underline transition-colors hover:text-red-600"
                        href={`/${postUrl}/${slug}`}
                    >
                        Read the article
                    </a>
                </div>
                {fullName && (
                    <div className="mt-2 flex w-full items-center justify-end text-[14px] sm:mt-0 sm:w-[200px] md:mt-2 md:w-full lg:mt-0 lg:w-[180px] xl:w-[200px]">
                        <div>By {fullName}</div>
                        {avatar && (
                            <img
                                src={avatar}
                                alt={`${fullName} avatar`}
                                className="ml-2 w-[30px] rounded-[50%] xl:w-[50px]"
                            />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}
