import { Link } from 'react-router-dom';
import Button from '@/components/common/Button';
import HowItWorksBoxSizes from '@/components/common/HowItWorksBoxSizes';
import { MostPopularIcon } from '@/components/common/Icons';

type Props = {
    title: string;
    bestValue?: Boolean;
    name?: string;
    description?: string;
    moreTitle?: string;
    moreDescription?: string;
    note?: string;
    smallPackageId?: string;
    largePackageId?: string;
};

export default function HowItWorksBoxes({
    title,
    bestValue,
    name,
    description,
    moreTitle,
    moreDescription,
    note,
    smallPackageId,
    largePackageId,
}: Props) {
    return (
        <div className="flex flex-col items-center">
            <div className="col-start-1">
                <div className="flex items-start space-x-6">
                    <h3 className="text-2xl font-bold uppercase tracking-wide">
                        {title}
                    </h3>
                    {bestValue && (
                        <MostPopularIcon className="!-mr-5 !ml-2 sm:!ml-6" />
                    )}
                </div>
                <div className="lg:grid lg:grid-cols-2">
                    <div>
                        <p className="pb-6 pt-3 text-lg">{name}</p>
                        {description && (
                            <div
                                className="prose col-span-2 max-w-none text-sm font-normal uppercase leading-6 text-black sm:!leading-7"
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                    __html: description,
                                }}
                            />
                        )}
                    </div>
                    <div className="pt-6 lg:-mt-6 lg:ml-7 lg:pt-0">
                        <h4 className="mb-3 font-agrandir text-xs font-bold uppercase tracking-widest">
                            {moreTitle}
                        </h4>
                        {(smallPackageId || largePackageId) && (
                            <ul>
                                {smallPackageId && (
                                    <li>
                                        <HowItWorksBoxSizes
                                            key="small"
                                            id={smallPackageId}
                                            name={name}
                                            title={title}
                                            label="Small Box"
                                        />
                                    </li>
                                )}
                                {largePackageId && (
                                    <li>
                                        <HowItWorksBoxSizes
                                            key="large"
                                            id={largePackageId}
                                            name={name}
                                            title={title}
                                            label="Large Box"
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {moreDescription && (
                            <div
                                className="prose col-span-2 max-w-none list-disc text-sm font-normal uppercase leading-6 text-black sm:!leading-7"
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                    __html: moreDescription,
                                }}
                            />
                        )}

                        {note && (
                            <p className="pt-3 text-xs font-bold">{note}</p>
                        )}
                    </div>
                </div>
            </div>
            <Button asChild>
                <Link
                    to="/get-started"
                    className="m-0 mt-8 py-4 text-center sm:px-14"
                >
                    Choose&nbsp;your&nbsp;plan
                </Link>
            </Button>
        </div>
    );
}
