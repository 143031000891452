import { Link } from 'react-router-dom';
import Button from '@/components/common/Button';
import { CheckPrimarySolid } from '@/components/common/Icons';
import AsSeenOnIcon from '@svg/footer/as-seen-on.svg';
import UnderlineSvg from '@svg/footer/underline.svg';
import VerticalLine from '@svg/footer/vertical-line.svg';

const LogoPedestrian = `${window.cdn}footer/logo-pedestrian.png`;
const LogoSunrise = `${window.cdn}footer/logo-sunrise.png`;
const LogoNewsRed = `${window.cdn}footer/logo-news-red.png`;
const LogoNewsComAu = `${window.cdn}footer/logo-news-com-au.png`;

export default function AsSeenOn() {
    return (
        <div className="bg-squared h-50 flex">
            <div className="mx-auto w-full max-w-[1512px]">
                <div className="relative grid space-y-40 py-14 lg:grid-cols-2 lg:space-y-0 lg:py-44">
                    <div className="flex flex-col justify-center space-y-5 px-4 text-center sm:px-20">
                        <h4 className="font-marydale text-4xl">
                            ButcherCrowd{' '}
                            <em className="relative flex justify-center self-center break-words pr-5 lg:inline-block">
                                is better
                                <UnderlineSvg className="absolute -bottom-2 lg:right-0" />
                            </em>
                        </h4>
                        <p className="font-bold">
                            BETTER FOR THE FARMING &amp; FISHING FAMILIES, THE
                            ANIMALS, THE ENVIRONMENT AND BETTER FOR YOU. A
                            BETTER WAY OF EATING MEAT.
                        </p>
                        <div>
                            <Button asChild>
                                <Link to="/get-started">Build your box</Link>
                            </Button>
                        </div>
                        <div className="flex flex-row justify-center space-x-4">
                            <CheckPrimarySolid
                                role="img"
                                aria-label="Checkmark"
                            />
                            <p>SKIP, PAUSE OR CANCEL ANYTIME</p>
                        </div>
                    </div>
                    <div className="m-auto">
                        <div className="relative grid grid-cols-2 content-center justify-items-center gap-10 px-10">
                            <AsSeenOnIcon className="absolute right-10 -top-20" />
                            <img
                                src={LogoPedestrian}
                                alt="Pedestrian"
                                className="m-auto max-h-[25px] lg:max-h-[32px]"
                            />
                            <img
                                src={LogoSunrise}
                                alt="Sunrise"
                                className="m-auto max-h-[48px] lg:max-h-[61px]"
                            />
                            <img
                                src={LogoNewsRed}
                                alt="NewsRed"
                                className="m-auto max-h-[39px] lg:max-h-[50px]"
                            />
                            <img
                                src={LogoNewsComAu}
                                alt="NewsComAu"
                                className="m-auto max-h-[42px] lg:max-h-[54px]"
                            />
                        </div>
                    </div>
                    <div className="top-1/4 left-1/2 hidden lg:absolute lg:block">
                        <VerticalLine />
                    </div>
                </div>
            </div>
        </div>
    );
}
