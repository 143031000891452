import { object, string, InferType } from 'yup';
import messages from '@/utils/messages';
import passwordConfirmation from './rules/passwordConfirmation';

export const passwordResetFormSchema = object({
    token: string().label('Token').required(),
    email: string().label('Email').email().required(),
    password: string().label('Password').required(),
    passwordConfirmation: string()
        .label('Password confirmation')
        .required()
        .test(
            passwordConfirmation(
                'password',
                messages.errors.passwordConfirmation
            )
        ),
});

type PasswordResetFormModel = InferType<typeof passwordResetFormSchema>;

export default PasswordResetFormModel;
