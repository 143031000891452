import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import Button from '@/components/common/Button';
import DeliveryDateSelect from '@/components/inputs/DeliveryDateSelect';
import DeliveryTimeSelect from '@/components/inputs/DeliveryTimeSelect';
import InputGroup from '@/components/inputs/InputGroup';
import TextInput from '@/components/inputs/TextInput';
import AlertModal from '@/components/modals/AlertModal';
import GetStartedFormModel from '@/models/GetStartedFormModel';
import { getDeliverySchedule } from '@/services/deliveryZoneService';
import { makeDot } from '@/types/Path';
import { isOvernight } from '@/utils/luxonFormats';
import Form from '../Form';

const dot = makeDot<GetStartedFormModel>();

export default function GetStartedForm() {
    const formik = useFormikContext();
    const { shippingPostcode } = formik.values as Partial<GetStartedFormModel>;
    const { values, setFieldValue } = formik;
    const [postCode, setPostCode] = useState(shippingPostcode);
    const [confirmOvernightModal, setConfirmOvernightModal] =
        useState<any>(false);
    const { data, isFetching } = useQuery(
        ['deliverySchedule', postCode],
        () =>
            getDeliverySchedule(
                postCode?.charAt(0) === '0' ? postCode?.substring(1) : postCode!
            ).catch(e => e),
        {
            enabled: /^\d{3,4}$/.test(postCode ?? ''),
            retry: false,
        }
    );

    useEffect(() => {
        setFieldValue(dot('deliveryScheduleId'), '');
        setFieldValue(dot('shippingDateStartedAt'), '');
        setFieldValue(dot('shippingStartedAt'), '');
        const timeoutId = setTimeout(() => setPostCode(shippingPostcode), 500);
        return () => clearTimeout(timeoutId);
    }, [setFieldValue, shippingPostcode]);

    return (
        <>
            <AlertModal
                open={confirmOvernightModal}
                onClose={setConfirmOvernightModal}
                title="Warning"
            >
                <div className="mt-4 flex flex-col">
                    You&apos;ve chosen an overnight silent delivery, so you
                    won&apos;t get an SMS or driver notification once the box is
                    delivered. If you require a notification, please switch to a
                    daytime delivery option.
                    <Button
                        type="button"
                        className="mt-2 flex w-full justify-center rounded bg-black !font-neue text-base font-medium normal-case text-white hover:text-white"
                        onClick={() => {
                            setConfirmOvernightModal(false);
                        }}
                    >
                        Change my delivery time
                    </Button>
                    <Button
                        type="button"
                        className="mt-2 flex justify-center rounded !font-neue text-base font-medium normal-case"
                        onClick={() => {
                            formik.submitForm();
                            setConfirmOvernightModal(false);
                        }}
                    >
                        Accept
                    </Button>
                </div>
            </AlertModal>
            <Form className="flex w-full flex-col space-y-6 lg:w-3/4">
                <InputGroup name={dot('email')} title="My email address">
                    <TextInput />
                </InputGroup>
                <InputGroup name={dot('shippingPostcode')} title="My postcode">
                    <TextInput />
                    {((data as AxiosError)?.response?.status === 404 ||
                        (data as AxiosError)?.response?.status === 400) && (
                            <div className="mt-2 text-xs text-error">
                                I&apos;m sorry, it doesn&apos;t look like we can
                                deliver to your postcode yet.
                            </div>
                        )}
                </InputGroup>
                <InputGroup
                    name={dot('shippingStartedAt')}
                    title="Our next delivery in your area"
                >
                    <DeliveryDateSelect data={data} isFetching={isFetching} />
                </InputGroup>
                <InputGroup
                    name={dot('deliveryScheduleId')}
                    title="Delivery time"
                >
                    <DeliveryTimeSelect data={data} />
                </InputGroup>
                <InputGroup name={dot('code')} className="hidden">
                    <TextInput type="text" />
                </InputGroup>
                <div className="mt-12 flex flex-col items-center">
                    {isOvernight((values as any).shippingStartedAt) ? (
                        <Button
                            wide
                            onClick={() => setConfirmOvernightModal(true)}
                        >
                            Next step
                        </Button>
                    ) : (
                        <Button type="submit" wide>
                            Next step
                        </Button>
                    )}
                    <div className="mt-4 text-xs font-medium">
                        By providing my email address, I agree to receive
                        exclusive offers and marketing emails from ButcherCrowd.
                        You can unsubscribe at any time.
                    </div>
                </div>
            </Form>
        </>
    );
}
