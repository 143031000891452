import * as RadixAccordion from '@radix-ui/react-accordion';
import { ChevronDownIcon } from './Icons';

interface Props {
    children: React.ReactNode;
    defaultValue?: string;
}

export default function Accordion({ children, defaultValue }: Props) {
    return (
        <RadixAccordion.Root
            type="single"
            defaultValue={defaultValue}
            collapsible
            className="wrapper mx-auto w-[90%] max-w-[934px] border-2 border-b-0 border-black bg-white md:w-[66%]"
        >
            {children}
        </RadixAccordion.Root>
    );
}

interface SectionProps {
    title: string;
    value: string;
    left?: React.ReactNode;
    children: React.ReactNode;
}

Accordion.Section = function AccordionSection({
    title,
    children,
    left,
    value,
}: SectionProps) {
    return (
        <RadixAccordion.Item value={value}>
            <div>
                <RadixAccordion.Header>
                    <RadixAccordion.Trigger className="group w-full bg-white text-black transition-colors hover:text-red-600 open:bg-black open:text-white">
                        <div className="relative flex items-center justify-between border-b-2 border-black px-4 py-5 sm:px-8 ">
                            {left}
                            <h2 className="text-left font-agrandir text-sm font-bold uppercase tracking-widest sm:text-base">
                                {title}
                            </h2>
                            <ChevronDownIcon className="transformh-3 w-3 transform transition-transform group-open:rotate-180" />
                        </div>
                    </RadixAccordion.Trigger>
                </RadixAccordion.Header>
                <RadixAccordion.Content>
                    <div className="border-b-2 border-black px-6 py-10 text-left text-sm text-black sm:px-8">
                        {children}
                    </div>
                </RadixAccordion.Content>
            </div>
        </RadixAccordion.Item>
    );
};
