import Button from '@/components/common/Button';
import InputGroup from '@/components/inputs/InputGroup';
import TextInput from '@/components/inputs/TextInput';
import ActivateGiftCardFormModel from '@/models/ActivateGiftCardFormModel';
import { makeDot } from '@/types/Path';
import Form from '../Form';

const dot = makeDot<ActivateGiftCardFormModel>();

export default function ActivateGiftCardForm() {
    return (
        <>
            <div className="mt-12 flex flex-col items-center">
                <p className="text-lg font-bold">Activate your gift card</p>
                <p className="text-center">
                    Have you received a Butchercrowd e-gift card? Activate it
                    here!
                </p>
            </div>
            <Form className="mt-12 flex flex-col items-center">
                <div className="border border-gray-200 px-10 py-10">
                    <span className="mx-auto flex items-center justify-center">
                        Enter your activation code
                    </span>
                    <InputGroup
                        className="flex flex-col pt-1.5 md:w-96 md:pt-0"
                        name={dot('activationCode')}
                    >
                        <TextInput
                            placeholder="A0A0A0A0A0A0A0"
                            height={14}
                            center
                        />
                    </InputGroup>
                </div>
                <div className="mt-8 flex flex-col items-center">
                    <Button type="submit" wide>
                        Activate
                    </Button>
                </div>
            </Form>
        </>
    );
}
