import { InferType, boolean, mixed, number, object, string } from 'yup';
import StateType from '@/types/StateType';
import expiration from './rules/creditCard';
import passwordConfirmation from './rules/passwordConfirmation';
import phoneNumber from './rules/phoneNumber';
import postcode from './rules/postCode';

export const checkoutGiftCardFormSchema = object({
    firstName: string().label('First Name').required(),
    lastName: string().label('Surname').required(),
    phone: string()
        .label('Phone')
        .test(phoneNumber('Phone is invalid'))
        .required(),
    password: string()
        .label('Password')
        .when('hasPassword', {
            is: true,
            then: (s) => s.strip(),
            otherwise: (s) => s.required(),
        }),
    passwordConfirmation: string()
        .label('Password Confirmation')
        .test(passwordConfirmation('password', 'Password do not match'))
        .when('hasPassword', {
            is: true,
            then: (s) => s.strip(),
            otherwise: (s) => s.required(),
        }),
    email: string()
        .label('Email address')
        .email()
        .when('hasPassword', {
            is: undefined,
            then: (s) => s.required(),
            otherwise: (s) => s.strip(),
        }),
    sms: boolean()
        .label('SMS')
        .when('hasSms', {
            is: true,
            then: (s) => s.strip(),
        })
        .default(false),
    newsletter: boolean()
        .label('Newsletter')
        .when('hasNewsletter', {
            is: true,
            then: (s) => s.strip(),
        })
        .default(false),
    billingAddressLine_1: string().label('Street address').required(),
    suite: string().label('Apartment, unit, or suite number'),
    billingCity: string().label('Suburb').required(),
    postcode: string().test(postcode('Post code invalid')).required(),
    country: string().label('Country').default('Australia').required(),
    billingState: mixed<StateType>().label('State').required(),
    existingCard: boolean().default(false),
    paymentMethod: string().when('existingCard', {
        is: true,
        then: (s) => s.required(),
        otherwise: (s) => s.strip(),
    }),
    number: string()
        .label('Credit card')
        .when('existingCard', {
            is: false,
            then: (s) => s.required(),
            otherwise: (s) => s.strip(),
        }),
    expiration: string().when('existingCard', {
        is: false,
        then: (s) => s.test(expiration()).required(),
        otherwise: (s) => s.strip(),
    }),
    cvc: string()
        .label('CVC')
        .when('existingCard', {
            is: false,
            then: (s) => s.required(),
            otherwise: (s) => s.strip(),
        }),
    hasPassword: boolean(),
    hasSms: boolean(),
    hasNewsletter: boolean(),
    // Gift card personalize data
    senderName: string().required(),
    senderEmail: string().required(),
    isAnotherReceiver: boolean(),
    receiverName: string().required(),
    receiverEmail: string().required(),
    receiverMessage: string(),
    receiverDeliveryDate: string().required(),
    bonusReceiverName: string().when('isAnotherReceiver', {
        is: true,
        then: (s) => s.required(),
        otherwise: (s) => s.strip(),
    }),
    bonusReceiverEmail: string().when('isAnotherReceiver', {
        is: true,
        then: (s) => s.required(),
        otherwise: (s) => s.strip(),
    }),
    bonusReceiverMessage: string(),
    bonusReceiverDeliveryDate: string().when('isAnotherReceiver', {
        is: true,
        then: (s) => s.required(),
        otherwise: (s) => s.strip(),
    }),
    value: number().required(),
});

type CheckoutGiftCardFormModel = InferType<typeof checkoutGiftCardFormSchema>;

export default CheckoutGiftCardFormModel;
