import { useMutation } from '@tanstack/react-query';
import clsx from 'clsx';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Spring, animated } from 'react-spring';
import BillingDetailsCard from '@/components/cards/BillingDetailsCard';
import Button from '@/components/common/Button';
import { ChevronDownIcon } from '@/components/common/Icons';
import Wrapper from '@/components/common/Wrapper';
import CheckoutGiftCardForm from '@/components/forms/giftCard/CheckoutGiftCardForm';
import SignInFormModal from '@/components/forms/signIn/SignInFormModal';
import ThreeDotsLoader from '@/components/loaders/ThreeDotsLoader';
import AlertModal from '@/components/modals/AlertModal';
import RedirectAfterErrorModal from '@/components/modals/RedirectAfterErrorModal';
import Breadcrumbs from '@/components/navigation/Breadcrumbs';
import useHandleSubmit from '@/hooks/useHandleSubmit';
import useWindowDimensions from '@/hooks/useWindowDimensions';
import CheckoutGiftCardFormModel, {
    checkoutGiftCardFormSchema,
} from '@/models/CheckoutGiftCardFormModel';
import { makeCheckoutGiftCardRequest } from '@/models/CheckoutGiftCardRequestModel';
import LoginFormModel, { loginFormSchema } from '@/models/SignInFormModel';
import { SignInParams, postSignIn } from '@/services/authService';
import {
    useGiftCardCheckout,
    useGiftCardSettings,
} from '@/services/giftCardService';
import { useKlaviyoCheckoutError } from '@/services/klaviyo';
import { useCreditBalance } from '@/services/profileService';
import {
    selectLoading,
    setLoading,
} from '@/store/getStarted/getStartedLoadingSlice';
import {
    resetGiftCardsError,
    selectCheckout,
    selectError,
} from '@/store/giftCards/giftCardsErrorSlice';
import {
    resetGiftCardsSuccess,
    selectSuccess,
    setSuccess,
} from '@/store/giftCards/giftCardsSuccessSlice';
import { useUser } from '@/store/user/userSlice';
import { giftCardsPrivateListen } from '@/utils/broadCastEvent';
import formatDollarAmount from '@/utils/formatDollarAmount';
import { isPassOrToday } from '@/utils/luxonFormats';
import queryClient from '@/utils/queryClient';

const routes = [
    {
        route: '/gift-cards',
        title: 'Gift cards',
    },
    {
        route: '/gift-cards/personalise',
        title: 'Personalise',
    },
];

export default function GiftCardCheckoutPage() {
    const [user] = useUser();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { height: windowHeight } = useWindowDimensions();
    const [cookies] = useCookies([
        'giftCardAmount',
        'personalizeGiftCardValues',
    ]);
    const { giftCardSettingsResult } = useGiftCardSettings();
    const { creditBalanceResult } = useCreditBalance(!!user.hasPassword);
    const error = useSelector(selectError);
    const loading = useSelector(selectLoading);
    const success = useSelector(selectSuccess);
    const [open, setOpen] = useState<any>(false);
    const [openLoginModal, setOpenLoginModal] = useState<any>(false);
    const attemptedCheckout = useSelector(selectCheckout);
    const [errorMessage, setErrorMessage] = useState<any>(null);
    const [expanded, setExpanded] = useState(false);
    const klaviyoCheckoutError = useKlaviyoCheckoutError;
    const { mutateAsync: giftCardCheckout, error: giftCardCheckoutError } =
        useGiftCardCheckout();
    const { mutateAsync: signIn } = useMutation(
        async (payload: SignInParams) => {
            const { data, status } = await postSignIn(payload);
            if (status !== 200) {
                throw Error('Password or username is incorrect');
            }
            return data;
        }
    );
    const handleSubmit = useHandleSubmit(
        checkoutGiftCardFormSchema,
        (v) => giftCardCheckout(makeCheckoutGiftCardRequest(v)),
        ({ response, helpers }) => {
            helpers.setTouched({}, false);
            helpers.setFieldValue('expiration', '');
            helpers.setFieldValue('cvc', '');
            helpers.setFieldValue('number', '');
            queryClient.invalidateQueries(['paymentMethods']);
            if (!user.email) {
                giftCardsPrivateListen(
                    response?.user,
                    'PurchasedGiftCard',
                    'PurchasedGiftCard',
                    dispatch
                );
                giftCardsPrivateListen(
                    response?.user,
                    'PurchaseGiftCardError',
                    'PurchaseGiftCardError',
                    dispatch
                );
            }
            if (!window.Echo.socketId()) {
                dispatch(setLoading(false));
                dispatch(setSuccess(true));
            }
        },
        dispatch
    );

    const handleSubmitLogin = useHandleSubmit(
        loginFormSchema,
        signIn,
        () => {
            window.location.href = '/gift-cards/checkout';
        },
        dispatch
    );

    useEffect(() => {
        if (!cookies.giftCardAmount) {
            navigate('/gift-cards');
        } else if (!cookies.personalizeGiftCardValues) {
            navigate('/gift-cards/personalise');
        } else if (user.email) {
            giftCardsPrivateListen(
                user.id,
                'PurchasedGiftCard',
                'PurchasedGiftCard',
                dispatch
            );
            giftCardsPrivateListen(
                user.id,
                'PurchaseGiftCardError',
                'PurchaseGiftCardError',
                dispatch
            );
        }
    }, [navigate, cookies, user, dispatch]);

    useEffect(() => {
        if (giftCardCheckoutError) {
            klaviyoCheckoutError(user, giftCardCheckoutError);
            if (
                (giftCardCheckoutError as any)?.response?.data?.errors
                    ?.email?.[0] === 'The email has already been taken.'
            ) {
                setErrorMessage(
                    (giftCardCheckoutError as any).response.data.errors.email[0]
                );
                setOpenLoginModal(true);
            } else {
                setErrorMessage(
                    (giftCardCheckoutError as any).response.data.message
                );
                setOpen(true);
            }
        } else if (error) {
            klaviyoCheckoutError(user, error.error);
            if (error.error[Object.keys(error.error)[0]][0]) {
                setErrorMessage(error.error[Object.keys(error.error)[0]]);
                setOpen(true);
            } else {
                setOpen(false);
            }
        } else {
            setOpen(false);
        }
    }, [
        giftCardCheckoutError,
        user,
        klaviyoCheckoutError,
        error,
        setOpenLoginModal,
    ]);

    if (!cookies.personalizeGiftCardValues || !cookies.giftCardAmount)
        return null;

    return (
        <Wrapper className="relative md:w-[calc(100%_-_40px)] md:max-w-[1054px]">
            <Helmet>
                <title>Gifts card | ButcherCrowd</title>
                <meta
                    name="description"
                    content="Gift the joy of ethical, high-quality, sustainably sourced meat & wild-caught seafood, with a ButcherCrowd gift card. Perfect for any occasion, our gift cards are valid for use on all ButcherCrowd boxes."
                />
            </Helmet>
            <ThreeDotsLoader visible={loading} />
            <div className="mx-3.5 pt-8 pb-8 sm:block lg:mx-4">
                <Breadcrumbs crumbs={routes} />
            </div>
            <div className="pt-12 text-center text-4xl font-medium uppercase">
                Gift card payment
            </div>
            <div>
                <Formik<Partial<CheckoutGiftCardFormModel>>
                    initialValues={{
                        firstName:
                            user?.firstName ||
                            cookies.personalizeGiftCardValues.senderFirstName,
                        lastName: user?.lastName,
                        phone: user?.phone ? user?.phone.replace('+', '') : '',
                        email:
                            user?.email ||
                            cookies.personalizeGiftCardValues.senderEmail,
                        country: 'Australia',
                        billingCity: '',
                        hasPassword: user?.hasPassword,
                        hasSms: user?.sms,
                        hasNewsletter: user?.newsletter,
                        receiverName:
                            cookies.personalizeGiftCardValues.receiverFirstName,
                        receiverEmail:
                            cookies.personalizeGiftCardValues.receiverEmail,
                        receiverMessage:
                            cookies.personalizeGiftCardValues.receiverMessage,
                        receiverDeliveryDate:
                            cookies.personalizeGiftCardValues.deliveryAt,
                        value: cookies.giftCardAmount,
                        isAnotherReceiver:
                            cookies.personalizeGiftCardValues.isAnotherReceiver,
                        bonusReceiverName:
                            cookies.personalizeGiftCardValues
                                .bonusReceiverFirstName,
                        bonusReceiverEmail:
                            cookies.personalizeGiftCardValues
                                .bonusReceiverEmail,
                        bonusReceiverMessage:
                            cookies.personalizeGiftCardValues
                                .bonusReceiverMessage,
                        bonusReceiverDeliveryDate:
                            cookies.personalizeGiftCardValues.bonusDeliveryAt,
                        senderName:
                            cookies.personalizeGiftCardValues.senderFirstName,
                        senderEmail:
                            cookies.personalizeGiftCardValues.senderEmail,
                    }}
                    validationSchema={checkoutGiftCardFormSchema}
                    onSubmit={handleSubmit}
                >
                    <div className="flex w-full items-start pt-12">
                        <div className="grow lg:mr-2">
                            <CheckoutGiftCardForm
                                creditCardInvalid={error}
                                attemptedCheckout={attemptedCheckout}
                            />
                        </div>
                        {/* Mobile summary card */}
                        <aside className="fixed right-2 left-2 bottom-[-2px] z-50 flex flex-col border border-secondary bg-white p-4 shadow-block lg:hidden">
                            <button
                                className="flex h-[30px] items-center justify-between"
                                type="button"
                                onClick={() => setExpanded((s) => !s)}
                            >
                                <div className="py-1 text-3xl font-bold uppercase ">
                                    Your order
                                </div>
                                <ChevronDownIcon
                                    className={clsx(
                                        'h-5 w-8',
                                        !expanded && 'rotate-180'
                                    )}
                                />
                            </button>
                            <div className="pt-2">
                                <div
                                    className="inset-0 flex items-center"
                                    aria-hidden="true"
                                >
                                    <div className="w-full border-t-4 border-black" />
                                </div>
                            </div>
                            <Spring
                                to={{
                                    height: expanded ? windowHeight - 118 : 0,
                                    opacity: expanded ? 1 : 0,
                                }}
                            >
                                {({ height, opacity }: any) => (
                                    <animated.div
                                        style={{
                                            height,
                                            opacity,
                                        }}
                                        className="flex min-h-0 flex-col overflow-auto"
                                    >
                                        <div
                                            role="separator"
                                            className="my-4 h-[5px] bg-secondary"
                                        />
                                        <BillingDetailsCard
                                            name="Gift card"
                                            price={cookies.giftCardAmount}
                                        />
                                        {giftCardSettingsResult?.bonus > 0 && (
                                            <BillingDetailsCard
                                                name={`Gift Card $${Math.round(
                                                    ((giftCardSettingsResult.bonus /
                                                        100) *
                                                        (cookies.giftCardAmount /
                                                            100)) /
                                                        100
                                                ).toFixed(2)}`}
                                                price={0}
                                            />
                                        )}
                                        {creditBalanceResult?.balance > 0 && (
                                            <div className="grid grid-cols-2 gap-6">
                                                <span className="text-base font-medium uppercase">
                                                    Credit
                                                </span>
                                                {creditBalanceResult?.balance >
                                                cookies.giftCardAmount ? (
                                                    <span className="text-end text-base font-bold">
                                                        {formatDollarAmount(
                                                            cookies.giftCardAmount
                                                        )}
                                                    </span>
                                                ) : (
                                                    <span className="text-end text-base font-bold">
                                                        {formatDollarAmount(
                                                            creditBalanceResult?.balance
                                                        )}
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                        <div
                                            role="separator"
                                            className="my-4 h-[1px] bg-secondary"
                                        />
                                        {creditBalanceResult?.balance > 0 ? (
                                            <div>
                                                {creditBalanceResult?.balance >
                                                cookies.giftCardAmount ? (
                                                    <BillingDetailsCard
                                                        name="Total"
                                                        price={0}
                                                    />
                                                ) : (
                                                    <BillingDetailsCard
                                                        name="Total"
                                                        price={
                                                            cookies.giftCardAmount -
                                                            Number(
                                                                creditBalanceResult?.balance
                                                            )
                                                        }
                                                    />
                                                )}
                                            </div>
                                        ) : (
                                            <BillingDetailsCard
                                                name="Total"
                                                price={
                                                    cookies.giftCardAmount > 0
                                                        ? cookies.giftCardAmount
                                                        : 0
                                                }
                                            />
                                        )}
                                        <div
                                            role="separator"
                                            className="my-4 h-[5px] bg-secondary"
                                        />
                                    </animated.div>
                                )}
                            </Spring>
                        </aside>
                        {/* Desktop summary card */}
                        <aside className="mb-12 mr-3.5 hidden w-1/5 min-w-[300px] flex-col border border-secondary bg-white p-4 shadow-block lg:flex">
                            <h3 className="text-3xl font-bold uppercase">
                                Your order
                            </h3>
                            <div
                                role="separator"
                                className="my-4 h-[5px] bg-secondary"
                            />
                            <BillingDetailsCard
                                name="Gift card"
                                price={cookies.giftCardAmount}
                            />
                            {giftCardSettingsResult?.bonus > 0 && (
                                <BillingDetailsCard
                                    name={`Gift Card $${Math.round(
                                        ((giftCardSettingsResult.bonus / 100) *
                                            (cookies.giftCardAmount / 100)) /
                                            100
                                    ).toFixed(2)}`}
                                    price={0}
                                />
                            )}
                            {creditBalanceResult?.balance > 0 && (
                                <div className="grid grid-cols-2 gap-6">
                                    <span className="text-base font-medium uppercase">
                                        Credit
                                    </span>
                                    {creditBalanceResult?.balance >
                                    cookies.giftCardAmount ? (
                                        <span className="text-end text-base font-bold">
                                            {formatDollarAmount(
                                                cookies.giftCardAmount
                                            )}
                                        </span>
                                    ) : (
                                        <span className="text-end text-base font-bold">
                                            {formatDollarAmount(
                                                creditBalanceResult?.balance
                                            )}
                                        </span>
                                    )}
                                </div>
                            )}
                            <div
                                role="separator"
                                className="my-4 h-[1px] bg-secondary"
                            />
                            {creditBalanceResult?.balance > 0 ? (
                                <div>
                                    {creditBalanceResult?.balance >
                                    cookies.giftCardAmount ? (
                                        <BillingDetailsCard
                                            name="Total"
                                            price={0}
                                        />
                                    ) : (
                                        <BillingDetailsCard
                                            name="Total"
                                            price={
                                                cookies.giftCardAmount -
                                                Number(
                                                    creditBalanceResult?.balance
                                                )
                                            }
                                        />
                                    )}
                                </div>
                            ) : (
                                <BillingDetailsCard
                                    name="Total"
                                    price={
                                        cookies.giftCardAmount > 0
                                            ? cookies.giftCardAmount
                                            : 0
                                    }
                                />
                            )}
                            <div
                                role="separator"
                                className="my-4 h-[5px] bg-secondary"
                            />
                        </aside>
                    </div>
                </Formik>
            </div>
            <RedirectAfterErrorModal />
            {open && errorMessage && (
                <AlertModal open={open} onClose={setOpen} title={errorMessage}>
                    <div className="mt-8 flex flex-col">
                        <Button
                            type="button"
                            className="mt-2 flex justify-center rounded bg-black !font-neue text-base font-medium normal-case"
                            onClick={() => {
                                dispatch(resetGiftCardsError());
                                setOpen(false);
                            }}
                        >
                            Ok
                        </Button>
                    </div>
                </AlertModal>
            )}
            {openLoginModal && errorMessage && (
                <AlertModal
                    open={openLoginModal}
                    onClose={setOpenLoginModal}
                    title={errorMessage}
                >
                    <div className="mt-8 flex flex-col">
                        <div className="mt-2 py-2 sm:mx-auto sm:w-full sm:max-w-lg">
                            <Formik<LoginFormModel>
                                initialValues={{
                                    email: '',
                                    password: '',
                                }}
                                validationSchema={loginFormSchema}
                                onSubmit={handleSubmitLogin}
                            >
                                <SignInFormModal />
                            </Formik>
                        </div>
                        <Button
                            type="button"
                            className="mt-1 flex justify-center rounded bg-black !font-neue text-base font-medium normal-case"
                            onClick={() => {
                                dispatch(resetGiftCardsError());
                                setOpenLoginModal(false);
                            }}
                        >
                            Cancel
                        </Button>
                    </div>
                </AlertModal>
            )}
            {success && (
                <AlertModal open={success} onClose={setSuccess} title="Success">
                    <div className="mt-4 flex flex-col">
                        <span className="text-sm">
                            Your purchase has been successfully completed. You
                            will receive a confirmation by email. Please note
                            that it may take up to{' '}
                            {isPassOrToday(
                                cookies.personalizeGiftCardValues.deliveryAt
                            )
                                ? '5'
                                : '15'}{' '}
                            minutes for your gift card to arrive in your email
                            inbox.
                        </span>
                        <Button
                            type="button"
                            className="mt-2 flex justify-center rounded bg-black !font-neue text-base font-medium normal-case"
                            onClick={() => {
                                dispatch(resetGiftCardsSuccess());
                                window.location.href = '/';
                            }}
                        >
                            Ok
                        </Button>
                    </div>
                </AlertModal>
            )}
        </Wrapper>
    );
}
