import { useQuery } from '@tanstack/react-query';
import client from './client';

export const fetchTerms = () => client.get(`/api/pages/terms`);

export function useTerms() {
    const query = useQuery(
        ['terms'],
        async () => {
            const { data } = await fetchTerms();
            return data;
        },
        {
            retry: false,
        }
    );
    const { data: termsData, ...queryResult } = query;
    return { termsData, ...queryResult };
}
