import { object, string, InferType } from 'yup';

export const loginFormSchema = object({
    // Credentials
    email: string().label('Email Address').email().required(),
    password: string().label('Password').required(),
});

type SignInFormModel = InferType<typeof loginFormSchema>;

export default SignInFormModel;
