import { useMutation } from '@tanstack/react-query';
import { Formik } from 'formik';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import PasswordResetForm from '@/components/forms/passwordRecovery/PasswordResetForm';
import useHandleSubmit from '@/hooks/useHandleSubmit';
import PasswordResetFormModel, {
    passwordResetFormSchema,
} from '@/models/ResetPasswordFormModel';
import {
    ResetPasswordParams,
    postResetPassword,
} from '@/services/passwordService';

const OGImage = `${window.cdn}smo/SMO-homepage.png`;

export default function PasswordResetPage() {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const { mutateAsync: passwordReset } = useMutation(
        async (payload: ResetPasswordParams) => {
            const { data, status } = await postResetPassword(payload);
            if (status !== 200) {
                throw Error('Password is incorrect');
            }
            return data;
        }
    );
    const handleSubmit = useHandleSubmit(
        passwordResetFormSchema,
        passwordReset,
        ({ helpers }) => {
            helpers.setStatus({
                success: 'Password changed successfully',
            });
            setTimeout(() => {
                window.location.href = '/sign-in';
            }, 3000);
        },
        dispatch
    );

    return (
        <>
            <Helmet>
                <title>Password reset | ButcherCrowd</title>
                <meta
                    name="description"
                    content="Curated or customised meat & seafood boxes delivered for your everyday cooking essentials"
                />
                <meta
                    property="og:title"
                    content="Password reset | ButcherCrowd"
                />
                <meta
                    property="og:description"
                    content="Curated or customised meat & seafood boxes delivered for your everyday cooking essentials"
                />
                <meta property="og:image" content={OGImage} />
            </Helmet>
            <div className="flex flex-col justify-center px-6 py-24 lg:px-8">
                <h1 className="mb-4 text-4xl font-medium text-center uppercase">
                    Customer login
                </h1>
                <h2 className="mb-4 text-center">Reset your password</h2>
                <div className="py-4 sm:mx-auto sm:w-full sm:max-w-md">
                    <Formik<PasswordResetFormModel>
                        initialValues={{
                            token: searchParams.get('token')!,
                            email: searchParams.get('email')!,
                            password: '',
                            passwordConfirmation: '',
                        }}
                        validationSchema={passwordResetFormSchema}
                        onSubmit={handleSubmit}
                    >
                        <PasswordResetForm />
                    </Formik>
                </div>
            </div>
        </>
    );
}
