type Breadcrumb = {
    route: string;
    title: string;
};
interface Props {
    crumbs: Breadcrumb[];
}

export default function Breadcrumbs({ crumbs }: Props) {
    return (
        <nav className="flex" aria-label="Breadcrumb">
            <ol className="inline-flex items-stretch space-x-1 md:space-x-3">
                <li className="inline-flex">
                    <a
                        href="/"
                        className="dark:text-black-400 inline-flex text-[16px] hover:underline"
                    >
                        Home
                    </a>
                </li>
                {crumbs.map((item) => (
                    <li aria-current="page" key={item.title}>
                        <div className="flex items-center">
                            <svg
                                className="text-black-400 h-6 w-6"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                />
                            </svg>

                            <a
                                href={item.route}
                                className="dark:text-black-400 ml-1 inline-flex items-center text-[16px]  hover:underline"
                            >
                                {item.title}
                            </a>
                        </div>
                    </li>
                ))}
            </ol>
        </nav>
    );
}
