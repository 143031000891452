import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import AsSeenOn from '@/components/common/AsSeenOn';
import { LongVerticalLine, StepsLine } from '@/components/common/Icons';
import ShareButton from '@/components/common/ShareButton';
import Breadcrumbs from '@/components/navigation/Breadcrumbs';
import { useSingleRecipePost } from '@/services/blogService';

export default function SingleRecipePage() {
    const { slug } = useParams();
    const navigate = useNavigate();
    const { singleRecipePostResult } = useSingleRecipePost(slug);
    const routes = [
        {
            route: '/blog',
            title: 'Blog',
        },
        {
            route: `${singleRecipePostResult?.slug}`,
            title: `${singleRecipePostResult?.title}`,
        },
    ];

    return (
        <>
            <Helmet>
                <title>
                    {`${singleRecipePostResult?.title} | Blog | ButcherCrowd`}
                </title>
                <meta
                    name="description"
                    content="Curated or customised meat & seafood boxes delivered for your everyday cooking essentials"
                />
                <meta
                    property="og:title"
                    content={`${singleRecipePostResult?.title} | Blog | ButcherCrowd`}
                />
            </Helmet>
            <div>
                <div className="hidden px-8 pt-8 sm:flex sm:justify-between lg:mx-7">
                    <Breadcrumbs crumbs={routes} />
                    {singleRecipePostResult?.author?.name && (
                        <div className="flex">
                            <div>By {singleRecipePostResult?.author?.name}</div>
                            {singleRecipePostResult?.author?.avatar && (
                                <div>
                                    <img
                                        src={
                                            singleRecipePostResult?.author
                                                ?.avatar
                                        }
                                        alt={`${singleRecipePostResult?.author?.name} avatar`}
                                        className="ml-2 w-[30px] rounded-2xl"
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className="sm:flex sm:flex-col-reverse">
                    <div className="mt-12 text-5xl font-medium text-center uppercase sm:mt-8">
                        {singleRecipePostResult?.title}
                    </div>
                    {singleRecipePostResult?.categories && (
                        <div className="flex flex-wrap justify-center mt-4 space-x-2 sm:mt-9">
                            {singleRecipePostResult?.categories?.map(
                                (e: any) => (
                                    <button
                                        key={e.id}
                                        type="button"
                                        className="bg-secondary px-5 py-1.5 font-agrandir text-sm font-bold uppercase text-white transition-colors hover:bg-red-600"
                                        onClick={() => {
                                            navigate(`/blog-recipes/${e.slug}`);
                                        }}
                                    >
                                        {e.name}
                                    </button>
                                )
                            )}
                        </div>
                    )}
                    <div className="flex flex-wrap justify-center mt-5">
                        <div className="px-3 py-1 text-sm font-bold text-center uppercase border border-black font-agrandir">
                            Recipe
                        </div>
                    </div>
                </div>
                <div className="flex flex-col items-center mt-5 sm:flex-row sm:justify-center">
                    <div className="text-xs font-bold tracking-wide uppercase font-agrandir">
                        Preparation {singleRecipePostResult?.preparationTime}
                    </div>
                    <StepsLine className="w-10 h-1 text-primary sm:px-2" />
                    <div className="text-xs font-bold tracking-wide uppercase font-agrandir">
                        Cooking {singleRecipePostResult?.cookingTime}
                    </div>
                </div>
                <img
                    src={singleRecipePostResult?.thumbnail.url}
                    alt={singleRecipePostResult?.thumbnail.alt}
                    className="my-12 mx-auto h-auto w-[1069px] max-w-[100%] xl:w-[1200px] 2xl:w-[1450px]"
                />
                <div className="mx-auto w-[880px] max-w-[calc(100%_-_40px)]">
                    <div
                        className="mb-8 tiny"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: singleRecipePostResult?.content,
                        }}
                    />
                    <div className="relative flex flex-col justify-between w-full md:flex-row">
                        <div className="mb-6 w-full md:w-[calc(50%_-_30px)]">
                            <div className="text-xl font-bold text-primary">
                                Ingredients
                            </div>
                            <div
                                className="mt-4 text-black tiny tiny--tight col-span-2 max-w-none"
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                    __html: singleRecipePostResult?.ingredients,
                                }}
                            />
                        </div>
                        <div className="left-[50%] hidden h-full w-[10px] translate-x-[-50%] overflow-hidden md:absolute md:block">
                            <div className="absolute top-0 left-0 flex items-end max-h-full">
                                <LongVerticalLine className="h-[500px]" />
                            </div>
                        </div>
                        <div className="mb-4 w-full md:w-[calc(50%_-_30px)]">
                            <div className="mt-4 text-xl font-bold text-primary sm:mt-0">
                                Directions
                            </div>
                            <div
                                className="mt-4 text-black tiny tiny--tight prose prose-sm col-span-2 max-w-none sm:mr-2"
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                    __html: singleRecipePostResult?.directions,
                                }}
                            />
                        </div>
                    </div>
                    <ShareButton label="Share this recipe" />
                </div>
                <AsSeenOn />
            </div>
        </>
    );
}
