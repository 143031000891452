import { Link } from 'react-router-dom';
import Button from '@/components/common/Button';
import InputGroup from '@/components/inputs/InputGroup';
import TextInput from '@/components/inputs/TextInput';
import SignInFormModel from '@/models/SignInFormModel';
import { makeDot } from '@/types/Path';
import Form from '../Form';

const dot = makeDot<SignInFormModel>();

export default function SignInFormModal() {
    return (
        <Form>
            <div className="flex flex-col space-y-2 pb-8">
                <InputGroup name={dot('email')}>
                    <TextInput
                        id="email"
                        placeholder="Enter your email address"
                        type="email"
                        autoComplete="email"
                    />
                </InputGroup>
                <InputGroup name={dot('password')}>
                    <TextInput
                        id="password"
                        placeholder="Enter your password"
                        type="password"
                        autoComplete="password"
                    />
                </InputGroup>
            </div>
            <div className="flex flex-col items-center justify-between sm:flex-row sm:space-x-4">
                <div className="mt-6 text-sm sm:order-first sm:mt-0">
                    <Link
                        className="hover:cursor font-medium text-primary underline"
                        to="/forgot-password"
                    >
                        Forgot your password?
                    </Link>
                </div>
                <div className="order-first w-full sm:w-auto">
                    <Button
                        type="submit"
                        size="md"
                        className="hidden px-12 normal-case sm:flex"
                    >
                        Sign in
                    </Button>
                    <Button
                        type="submit"
                        className="flex px-12 normal-case sm:hidden"
                        full
                    >
                        Sign in
                    </Button>
                </div>
            </div>
        </Form>
    );
}
