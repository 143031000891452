import { Link } from 'react-router-dom';
import InputGroup from '@/components/inputs/InputGroup';
import Button from '@/components/common/Button';
import ModifyPasswordModel from '@/models/ModifyPassword';
import TextInput from '@/components/inputs/TextInput';
import { makeDot } from '@/types/Path';
import Form from '../Form';
import FormStatus from '../FormStatus';

const dot = makeDot<ModifyPasswordModel>();

export default function PasswordResetForm() {
    return (
        <Form>
            <div className="flex flex-col space-y-8 pb-12">
                <InputGroup name={dot('password')} title="New password">
                    <TextInput
                        placeholder="Enter your password"
                        type="password"
                        autoComplete="password"
                    />
                </InputGroup>
                <InputGroup
                    name={dot('passwordConfirmation')}
                    title="Confirm password"
                >
                    <TextInput
                        placeholder="Enter your password again"
                        type="password"
                        autoComplete="password"
                    />
                </InputGroup>
            </div>
            <FormStatus />
            <div className="flex flex-col items-center justify-center space-y-6 sm:flex-row sm:space-y-0 sm:space-x-4">
                <Button type="submit" className="hidden px-12 sm:flex">
                    Reset
                </Button>
                <Button type="submit" className="flex px-12 sm:hidden" full>
                    Reset
                </Button>
                <div className="flex sm:space-x-2">
                    <span>or</span>
                    <Link to="/sign-in" className="ml-2 text-primary underline">
                        Cancel
                    </Link>
                </div>
            </div>
        </Form>
    );
}
