import clsx from 'clsx';

type Props = {
    title: string;
    price: string;
    svg?: boolean;
    children?: React.ReactNode;
};

export default function BoxSizeCTACard({ title, price, svg, children }: Props) {
    return (
        <div className="m-auto my-2 w-[calc(100%_-_30px)] border border-black sm:w-[calc(100%_-_50px)] lg:my-4">
            <div className="flex w-full items-center justify-between bg-black py-1">
                <h3 className="ml-4 font-neue text-lg uppercase text-white">
                    {title}
                </h3>
                <span className="mr-2 hidden font-neue text-xs font-bold uppercase tracking-widest text-white md:flex">
                    {price}
                </span>
            </div>
            <ul
                className={clsx(
                    'relative my-4 ml-12 list-disc font-neue text-[12px]',
                    svg &&
                        'after:absolute after:right-[0px] after:top-[70%] after:h-[120px] after:w-[150px] after:bg-[url("../svg/best-value2.svg")] after:bg-cover after:bg-no-repeat after:content-[""] sm:after:right-[13px]'
                )}
            >
                {children}
            </ul>
        </div>
    );
}
