import { InferType, object, string } from 'yup';
import postcode from './rules/postCode';

export const getStartedModifyFormSchema = object({
    shippingPostcode: string()
        .label('Postcode')
        .test(
            postcode(
                "I'm sorry, it doesn't look like we can deliver to your postcode yet."
            )
        )
        .required(),
    shippingDateStartedAt: string()
        .label('Delivery date')
        .when('shippingStartedAt', {
            is: true,
            then: (s) => s.required(),
            otherwise: (s) => s.strip(),
        }),
    shippingStartedAt: string().label('Delivery date').required(),
    deliveryScheduleId: string().label('Delivery time').required(),
});

type GetStartedModifyFormModel = InferType<typeof getStartedModifyFormSchema>;

export default GetStartedModifyFormModel;
