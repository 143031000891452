import { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ItemSelectCard from '@/components/cards/ItemSelectCard';
import SummaryCard from '@/components/cards/SummaryCard';
import ThreeDotsLoader from '@/components/loaders/ThreeDotsLoader';
import RedirectAfterErrorModal from '@/components/modals/RedirectAfterErrorModal';
import ProductModel from '@/models/ProductModel';
import {
    useTrackRemoveCart,
    useTrackSelection,
    useTrackViewItemList,
} from '@/services/dataLayer';
import {
    useGetStartedCart,
    useProductsAddons,
} from '@/services/deliveryZoneService';
import {
    useKlaviyoInitiateChooseAddons,
    useKlaviyoRequestError,
} from '@/services/klaviyo';
import {
    setUnauthenticated,
    setUnexpectedError,
} from '@/store/getStarted/getStartedErrorSlice';
import {
    selectLoading,
    setLoading,
} from '@/store/getStarted/getStartedLoadingSlice';
import { setStep } from '@/store/getStarted/getStartedSlice';
import { useUser } from '@/store/user/userSlice';
import { selectItemsIds } from '@/store/yourAddons/yourAddonsSlice';
import { privateListen } from '@/utils/broadCastEvent';
import queryClient from '@/utils/queryClient';

export default function AddonsPage() {
    const [user] = useUser();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loading = useSelector(selectLoading);
    const { cartResult } = useGetStartedCart(user?.subscription?.id, true);
    const { productsAddonsResult, error: getAddonsError } = useProductsAddons(
        user?.subscription?.id
    );
    const newUser = localStorage.getItem('newuser');
    const itemsById = useMemo(
        () =>
            productsAddonsResult?.reduce(
                (obj, item) => ({ ...obj, [item.id]: item }),
                {} as { [id: number]: any }
            ),
        [productsAddonsResult]
    );
    const addonCountById: any = useSelector(selectItemsIds);
    const selectedAddons = useMemo(
        () => addonCountById?.map((id: any) => itemsById?.[id]),
        [itemsById, addonCountById]
    );

    const trackRemoveCart = useTrackRemoveCart;
    const trackSelection = useTrackSelection;
    const trackViewItemList = useTrackViewItemList;
    const klaviyoInitiateChooseAddons = useKlaviyoInitiateChooseAddons;
    const klaviyoRequestError = useKlaviyoRequestError;

    // eslint-disable-next-line func-names
    window.onpageshow = function (event) {
        if (event.persisted) {
            dispatch(setLoading(false));
            privateListen(
                user?.subscription?.id,
                'UpdatedSubscriptionAddOns',
                '/get-started/checkout',
                'UpdatedSubscriptionAddOns'
            );
        }
    };

    useEffect(() => {
        queryClient.invalidateQueries(['productsaddons']);
    }, [user?.subscription?.id]);

    const addonsByCategory = useMemo(
        () =>
            productsAddonsResult?.reduce<{
                [category: string]: ProductModel[];
            }>((obj: any, product: any) => {
                if (product.inventory === null || product.inventory > 0) {
                    return {
                        ...obj,
                        [product.category]: [
                            ...(obj[product.category] || []),
                            product,
                        ].sort((a, b) => a.name.localeCompare(b.name)),
                    };
                }
                return obj;
            }, {}),
        [productsAddonsResult]
    );

    useEffect(() => {
        if (
            cartResult?.products?.length > 0 &&
            localStorage.getItem('addProductsToCart') === 'true'
        ) {
            trackSelection(cartResult?.products, 'Products');
            localStorage.removeItem('addProductsToCart');
        }
        if (cartResult?.package?.name) {
            klaviyoInitiateChooseAddons(user, cartResult?.package?.name);
        }
    }, [user, klaviyoInitiateChooseAddons, cartResult, trackSelection]);

    useEffect(() => {
        if (productsAddonsResult) {
            trackViewItemList(productsAddonsResult, 'Add-Ons');
        }
    }, [productsAddonsResult, trackViewItemList]);

    useEffect(() => {
        if ((getAddonsError as any)?.response?.status) {
            klaviyoRequestError(user, getAddonsError);
            if (
                (getAddonsError as any)?.response?.status === 401 ||
                (getAddonsError as any)?.response?.status === 419
            ) {
                dispatch(setUnauthenticated(true));
            } else {
                dispatch(setUnexpectedError(true));
            }
        }
        privateListen(
            user?.subscription?.id,
            'UpdatedSubscriptionAddOns',
            '/get-started/checkout',
            'UpdatedSubscriptionAddOns'
        );
        privateListen(
            user?.subscription?.id,
            'SubscriptionCheckedOut',
            `/dashboard/my-deliveries?newuser=${newUser}`,
            'SubscriptionCheckedOut'
        );
        privateListen(
            user?.subscription?.id,
            'SubscriptionCheckoutError',
            `/dashboard/my-deliveries?newuser=${newUser}`,
            'SubscriptionCheckoutError',
            dispatch
        );
        dispatch(setStep({ index: 3, label: 'Addons' }));
    }, [
        dispatch,
        user?.subscription?.id,
        getAddonsError,
        user,
        klaviyoRequestError,
        newUser,
    ]);

    useEffect(() => {
        if (!user?.subscription) navigate('/get-started');
    }, [user?.subscription, navigate]);

    // Do not render the page, we will navigate back shortly.
    if (!user?.subscription) return null;

    return (
        <div className="relative px-8 py-12 xs:px-4 sm:px-8 lg:py-28">
            <Helmet>
                <title>
                    Add-ons | ButcherCrowd | Meat & Seafood Delivery
                    Subscription
                </title>
                <meta
                    name="description"
                    content="Curated or customised meat & seafood boxes delivered for your everyday cooking essentials"
                />
            </Helmet>
            <ThreeDotsLoader visible={loading} />
            <header className="mx-auto flex max-w-3xl flex-col items-center ">
                <h1 className="mb-7 text-4xl font-medium uppercase lg:text-5xl">
                    Add-ons
                </h1>
                {addonsByCategory && (
                    <div className="flex flex-wrap justify-center">
                        {Object.keys(addonsByCategory).map((category) => (
                            <a
                                key={category}
                                href={`#${category}`}
                                className="m-1.5 bg-secondary px-5 py-1.5 font-agrandir text-sm font-bold uppercase text-white"
                            >
                                {category}
                            </a>
                        ))}
                    </div>
                )}
                <p className="mb-16 mt-14 text-center lg:text-lg">
                    Add-ons are recurring and can be changed each order. Fill up
                    your box and benefit from added value.
                </p>
            </header>
            <div className="flex w-full items-start justify-end">
                {addonsByCategory && (
                    <div className="grow lg:mr-6">
                        {Object.entries(addonsByCategory).map(
                            ([key, addons]) => (
                                <section key={key} id={key}>
                                    <h4 className="border-b-[5px] border-secondary py-3 font-agrandir text-sm font-bold uppercase tracking-wide">
                                        {key}
                                    </h4>
                                    {user?.subscription?.postcode?.startsWith(
                                        '7'
                                    ) ? (
                                        <div className="grid grid-cols-1 gap-6 py-12 xs:grid-cols-2 xs:gap-2.5 sm:gap-6 xl:grid-cols-3 2xl:grid-cols-4">
                                            {addons.map((addon: any) => {
                                                if (
                                                    (addon?.inventory ===
                                                        null ||
                                                        addon?.inventory > 0) &&
                                                    !addon?.name
                                                        .toLowerCase()
                                                        .includes('salmon')
                                                )
                                                    return (
                                                        <ItemSelectCard
                                                            key={addon.id}
                                                            product={addon}
                                                            showPrice
                                                            removeCart={
                                                                trackRemoveCart
                                                            }
                                                        />
                                                    );
                                                return null;
                                            })}
                                        </div>
                                    ) : (
                                        <div className="grid grid-cols-1 gap-6 py-12 xs:grid-cols-2 xs:gap-2.5 sm:gap-6 xl:grid-cols-3 2xl:grid-cols-4">
                                            {addons.map((addon: any) => {
                                                if (
                                                    addon?.inventory === null ||
                                                    addon?.inventory > 0
                                                ) {
                                                    return (
                                                        <ItemSelectCard
                                                            key={addon.id}
                                                            product={addon}
                                                            showPrice
                                                            removeCart={
                                                                trackRemoveCart
                                                            }
                                                        />
                                                    );
                                                }
                                                return null;
                                            })}
                                        </div>
                                    )}
                                </section>
                            )
                        )}
                    </div>
                )}
                <SummaryCard
                    title="ADD-ONS"
                    items={selectedAddons}
                    packageInfo={cartResult?.package}
                    products={cartResult?.products}
                    allItems={itemsById}
                    disabled={!addonsByCategory}
                    subscriptionId={user?.subscription?.id}
                    removeCart={trackRemoveCart}
                    btnText="Next step"
                />
            </div>
            <RedirectAfterErrorModal />
        </div>
    );
}
