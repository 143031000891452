import { QueryClientProvider } from '@tanstack/react-query';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import {
    RouterProvider,
    createBrowserRouter,
    createRoutesFromElements,
} from 'react-router-dom';
import webRoutes from './router/webRoutes';
import store from './store';
import Compose from './utils/Compose';
import config from './utils/config';
import queryClient from './utils/queryClient';

window.cdn = 'https://cdn.butchercrowd.com.au/static/';
const root = ReactDOM.createRoot(document.getElementById('root')!);

const router = createBrowserRouter(createRoutesFromElements(webRoutes));
const { env } = JSON.parse(config);

const tagManagerArgs = {
    gtmId: 'GTM-TGKVNRQ',
};
if (env && env === 'production') {
    TagManager.initialize(tagManagerArgs);
}

root.render(
    <Compose
        items={[
            [Provider, { store }],
            [QueryClientProvider, { client: queryClient }],
            [RouterProvider, { router }],
        ]}
    />
);
