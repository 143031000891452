import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import AsSeenOn from '@/components/common/AsSeenOn';
import ShareButton from '@/components/common/ShareButton';
import Breadcrumbs from '@/components/navigation/Breadcrumbs';
import { useSingleBlogPost } from '@/services/blogService';

export default function SingleBlogPage() {
    const { slug } = useParams();
    const { singleBlogPostResult } = useSingleBlogPost(slug);
    const routes = [
        {
            route: '/blog',
            title: 'Blog',
        },
        {
            route: `${singleBlogPostResult?.slug}`,
            title: `${singleBlogPostResult?.title}`,
        },
    ];

    return (
        <>
            <Helmet>
                <title>
                    {`${singleBlogPostResult?.title} | Blog | ButcherCrowd`}
                </title>
                <meta
                    name="description"
                    content="Curated or customised meat & seafood boxes delivered for your everyday cooking essentials"
                />
                <meta
                    property="og:title"
                    content={`${singleBlogPostResult?.title} | Blog | ButcherCrowd`}
                />
            </Helmet>
            <div>
                <div className="hidden px-8 pt-8 sm:flex sm:justify-between lg:mx-7">
                    <Breadcrumbs crumbs={routes} />
                    {singleBlogPostResult?.author?.name && (
                        <div className="flex">
                            <div>By {singleBlogPostResult?.author?.name}</div>
                            {singleBlogPostResult?.author?.avatar && (
                                <div>
                                    <img
                                        src={
                                            singleBlogPostResult?.author?.avatar
                                        }
                                        alt={`${singleBlogPostResult?.author?.name} avatar`}
                                        className="ml-2 w-[30px] rounded-2xl"
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className="sm:flex sm:flex-col-reverse">
                    <div className="mt-12 text-5xl font-medium text-center sm:mt-8">
                        {singleBlogPostResult?.title}
                    </div>
                    <div className="flex flex-wrap justify-center mt-5">
                        <div className="px-3 py-1 text-sm font-bold font-extrabold text-center uppercase border border-black font-agrandir">
                            News
                        </div>
                    </div>
                </div>
                <img
                    src={singleBlogPostResult?.thumbnail.url}
                    alt={singleBlogPostResult?.thumbnail.alt}
                    className="my-12 mx-auto h-auto w-[1069px] max-w-[100%] xl:w-[1200px] 2xl:w-[1450px]"
                />
                <div className="mx-auto w-[680px] max-w-[calc(100%_-_40px)]">
                    <div
                        className="tiny"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: singleBlogPostResult?.content,
                        }}
                    />
                    <ShareButton label="Share this article" />
                </div>
                <AsSeenOn />
            </div>
        </>
    );
}
