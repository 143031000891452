import { useQuery } from '@tanstack/react-query';
import client from './client';

export const fetchHomepage = () => client.get(`/api/pages/homepage`);

export function useHomepage() {
    const query = useQuery(
        ['homepage'],
        async () => {
            const { data } = await fetchHomepage();
            return data;
        },
        {
            retry: false,
        }
    );
    const { data: homepageData, ...queryResult } = query;
    return { homepageData, ...queryResult };
}
