import * as RadixAccordion from '@radix-ui/react-accordion';
import { MinusIcon, PlusIcon } from '@/components/common/Icons';

interface Props {
    children: React.ReactNode;
    defaultValue?: string;
}

export default function FAQAccordion({ children, defaultValue }: Props) {
    return (
        <RadixAccordion.Root
            type="single"
            defaultValue={defaultValue}
            collapsible
            className="w-full"
        >
            {children}
        </RadixAccordion.Root>
    );
}

interface SectionProps {
    title: string;
    value: string;
    left?: React.ReactNode;
    children: React.ReactNode;
}

FAQAccordion.Section = function AccordionSection({
    title,
    children,
    left,
    value,
}: SectionProps) {
    return (
        <RadixAccordion.Item
            className="mb-6 border border-black shadow-[-3px_4px_0px_rgba(0,_0,_0,_0.74)] open:border-red-500 open:shadow-[-3px_4px_0px_rgb(239,_68,_68)]"
            value={value}
        >
            <div>
                <RadixAccordion.Header>
                    <RadixAccordion.Trigger className="group w-full bg-white text-black transition-colors hover:text-red-500">
                        <div className="relative flex items-center justify-between px-4 py-4 sm:px-6 ">
                            {left}
                            <h2 className="w-[calc(100%_-_30px)] text-left font-agrandir text-sm group-open:font-bold sm:text-base">
                                {title}
                            </h2>
                            <PlusIcon className="block w-[21px] transform transition-transform group-open:hidden" />
                            <MinusIcon className="hidden w-[21px] transform transition-transform group-open:block" />
                        </div>
                    </RadixAccordion.Trigger>
                </RadixAccordion.Header>
                <RadixAccordion.Content>
                    <div className="px-4 pb-6 text-left text-sm text-black sm:px-6">
                        {children}
                    </div>
                </RadixAccordion.Content>
            </div>
        </RadixAccordion.Item>
    );
};
