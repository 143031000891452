import { useMutation } from '@tanstack/react-query';
import { Formik } from 'formik';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import ForgotPasswordForm from '@/components/forms/passwordRecovery/ForgotPasswordForm';
import useHandleSubmit from '@/hooks/useHandleSubmit';
import ForgotPasswordFormModel, {
    forgotPasswordFormSchema,
} from '@/models/ForgotPasswordFormModel';
import { postForgotPassword } from '@/services/passwordService';

const OGImage = `${window.cdn}smo/SMO-homepage.png`;

export default function ForgotPasswordPage() {
    const dispatch = useDispatch();

    const { mutateAsync: passwordRecovery } = useMutation(postForgotPassword);
    const handleSubmit = useHandleSubmit(
        forgotPasswordFormSchema,
        passwordRecovery,
        ({ helpers }) => {
            helpers.setStatus({
                success:
                    'An email was sent to your address to reset your password.',
            });
            setTimeout(() => {
                window.location.href = '/sign-in';
            }, 3000);
        },
        dispatch
    );

    return (
        <>
            <Helmet>
                <title>Forgot password | ButcherCrowd</title>
                <meta
                    name="description"
                    content="Curated or customised meat & seafood boxes delivered for your everyday cooking essentials"
                />
                <meta
                    property="og:title"
                    content="Forgot password | ButcherCrowd"
                />
                <meta
                    property="og:description"
                    content="Curated or customised meat & seafood boxes delivered for your everyday cooking essentials"
                />
                <meta property="og:image" content={OGImage} />
            </Helmet>
            <div className="flex flex-col justify-center px-6 py-24 lg:px-8">
                <h1 className="mb-4 text-4xl font-medium text-center uppercase">
                    Customer login
                </h1>
                <h2 className="mb-4 text-center">Forgot password</h2>
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="py-4">
                        <Formik<ForgotPasswordFormModel>
                            initialValues={{
                                email: '',
                            }}
                            validationSchema={forgotPasswordFormSchema}
                            onSubmit={handleSubmit}
                        >
                            <ForgotPasswordForm />
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    );
}
