import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import client from './client';

const fetchBlogPosts = async ({ pageParam = 1 }) => {
    const res = await fetch(
        `//${window.location.host}/api/posts?page=${pageParam}`
    );
    return res.json();
};

export function useBlogPosts() {
    const {
        data: blogPostsResult,
        fetchNextPage: fetchNextBlogPage,
        hasNextPage: hasNextBlogPage,
    } = useInfiniteQuery({
        queryKey: ['posts'],
        queryFn: fetchBlogPosts,
        retry: false,
        getNextPageParam: (lastPage, page) => {
            if (lastPage.meta.last_page === page.length) return false;
            return lastPage.meta.current_page + 1;
        },
    });

    return { blogPostsResult, fetchNextBlogPage, hasNextBlogPage };
}

export const fetchSingleBlogPost = (slug?: string) =>
    client.get(`/api/posts/${slug}`);

export function useSingleBlogPost(slug?: string) {
    const query = useQuery(
        ['singleposts', slug],
        async () => {
            const { data } = await fetchSingleBlogPost(slug);
            return data;
        },
        {
            retry: false,
        }
    );
    const { data: singleBlogPostResult, ...queryResult } = query;
    return { singleBlogPostResult, ...queryResult };
}

export const fetchRecipesCategories = () => client.get<any>('/api/categories');

export function useRecipesCategories() {
    const query = useQuery(['recipescategories'], async () => {
        const { data } = await fetchRecipesCategories();
        return data;
    });
    const { data: recipesCategories, ...queryResult } = query;
    return { recipesCategories, ...queryResult };
}

const fetchRecipesByCategory = async (category: any, { pageParam = 1 }) => {
    const res = await fetch(
        `//${window.location.host}/api/recipes/category/${category}/?page=${pageParam}`
    );
    return res.json();
};

export function useRecipesByCategory(category: any) {
    const {
        data: recipesByCategoryPostsResult,
        fetchNextPage: fetchNextRecipesByCategoryPage,
        hasNextPage: hasNextRecipesByCategoryPage,
    } = useInfiniteQuery({
        queryKey: ['recipesbycategories', category],
        queryFn: () => fetchRecipesByCategory(category, {}),
        retry: false,
        getNextPageParam: (lastPage, page) => {
            if (lastPage.meta.last_page === page.length) return false;
            return lastPage.meta.current_page + 1;
        },
    });

    return {
        recipesByCategoryPostsResult,
        fetchNextRecipesByCategoryPage,
        hasNextRecipesByCategoryPage,
    };
}

const fetchRecipePosts = async ({ pageParam = 1 }) => {
    const res = await fetch(
        `//${window.location.host}/api/recipes?page=${pageParam}`
    );
    return res.json();
};

export function useRecipePosts() {
    const {
        data: recipePostsResult,
        fetchNextPage: fetchNextRecipePage,
        hasNextPage: hasNextRecipePage,
    } = useInfiniteQuery({
        queryKey: ['recipes'],
        queryFn: fetchRecipePosts,
        retry: false,
        getNextPageParam: (lastPage, page) => {
            if (lastPage.meta.last_page === page.length) return false;
            return lastPage.meta.current_page + 1;
        },
    });

    return { recipePostsResult, fetchNextRecipePage, hasNextRecipePage };
}

const fetchFeatureRecipePosts = async () => {
    const res = await fetch(
        `//${window.location.host}/api/recipes/featured/list`
    );
    return res.json();
};

export function useFeatureRecipePosts() {
    const query = useQuery(
        ['featuredrecipes'],
        async () => {
            const { data } = await fetchFeatureRecipePosts();
            return data;
        },
        {
            retry: false,
        }
    );
    const { data: featureRecipePostsResult, ...queryResult } = query;
    return { featureRecipePostsResult, ...queryResult };
}

const fetchHighlightRecipePost = async () => {
    const res = await fetch(
        `//${window.location.host}/api/recipes/highlight/details`
    );
    return res.json();
};

export function useHighlightRecipePost() {
    const query = useQuery(
        ['highlightrecipes'],
        async () => {
            const { data } = await fetchHighlightRecipePost();
            return data;
        },
        {
            retry: false,
        }
    );
    const { data: highlightRecipePostResult, ...queryResult } = query;
    return { highlightRecipePostResult, ...queryResult };
}

export const fetchSingleRecipePost = (slug?: string) =>
    client.get(`/api/recipes/${slug}`);

export function useSingleRecipePost(slug?: string) {
    const query = useQuery(
        ['singlerecipes', slug],
        async () => {
            const { data } = await fetchSingleRecipePost(slug);
            return data;
        },
        {
            retry: false,
        }
    );
    const { data: singleRecipePostResult, ...queryResult } = query;
    return { singleRecipePostResult, ...queryResult };
}
