import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import Button from '@/components/common/Button';

interface Thumbnail {
    url: string;
    alt: string;
}

interface RecipeCardProps {
    title: string;
    slug: string;
    thumbnail: Thumbnail;
}

export default function RecipeCard({
    title,
    slug,
    thumbnail,
}: PropsWithChildren<RecipeCardProps>) {
    return (
        <div className="relative mx-2 -mt-8 border border-black bg-white pb-16 font-extrabold shadow-block xs:mx-4 sm:mt-7">
            <div className="absolute mt-1 ml-1 h-0 w-0 border-t-[60px] border-r-[60px] border-t-primary border-r-transparent font-agrandir font-bold uppercase text-white">
                <span className="absolute left-1 -top-14 text-[9px]">New</span>
            </div>
            <img
                src={thumbnail.url}
                alt={thumbnail.alt}
                className="h-[220px] w-full object-cover"
            />
            <div className="mt-8 text-center font-agrandir text-sm font-bold font-extrabold uppercase">
                Highlight recipe
            </div>
            <div className="mx-5 mt-3.5 text-center text-2xl font-bold uppercase lg:text-3xl">
                {title}
            </div>
            <div className="mt-3.5 flex justify-center">
                <Button asChild className="bg-black">
                    <Link to={`/recipe-post/${slug}`}>See the recipe</Link>
                </Button>
            </div>
        </div>
    );
}
