import { useQuery } from '@tanstack/react-query';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CheckoutSummaryCard from '@/components/cards/CheckoutSummaryCard';
import Button from '@/components/common/Button';
import Wrapper from '@/components/common/Wrapper';
import CheckoutForm from '@/components/forms/getStarted/CheckoutForm';
import ThreeDotsLoader from '@/components/loaders/ThreeDotsLoader';
import AlertModal from '@/components/modals/AlertModal';
import RedirectAfterErrorModal from '@/components/modals/RedirectAfterErrorModal';
import useHandleSubmit from '@/hooks/useHandleSubmit';
import CheckoutFormModel, {
    checkoutFormSchema,
} from '@/models/CheckoutFormModel';
import { makeCheckoutRequest } from '@/models/CheckoutRequestModel';
import {
    useTrackInitiateCheckout,
    useTrackSelection,
} from '@/services/dataLayer';
import {
    useDeliveryFrequency,
    useGetStartedCart,
    useGetStartedCheckout,
} from '@/services/deliveryZoneService';
import { getDiscountMethods } from '@/services/discountService';
import { useActivateGiftCard } from '@/services/giftCardService';
import {
    useKlaviyoCheckoutError,
    useKlaviyoInitiateCheckout,
    useKlaviyoRequestError,
    useKlaviyoSubmitCheckoutRequest,
} from '@/services/klaviyo';
import {
    resetCheckoutError,
    selectCheckout,
    selectError,
    setUnauthenticated,
    setUnexpectedError,
} from '@/store/getStarted/getStartedErrorSlice';
import {
    selectLoading,
    setLoading,
} from '@/store/getStarted/getStartedLoadingSlice';
import { setStep } from '@/store/getStarted/getStartedSlice';
import { selectData } from '@/store/giftCards/giftCardsDataSlice';
import {
    resetGiftCardsError,
    selectGiftCardsError,
    setGiftCardsError,
} from '@/store/giftCards/giftCardsErrorSlice';
import {
    resetGiftCardsSuccess,
    selectSuccess,
    setSuccess,
} from '@/store/giftCards/giftCardsSuccessSlice';
import { useUser } from '@/store/user/userSlice';
import { giftCardsPrivateListen, privateListen } from '@/utils/broadCastEvent';
import queryClient from '@/utils/queryClient';

export default function CheckoutPage() {
    const [user] = useUser();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [availableDiscount, setAvailableDiscount] = useState<any>(true);
    const [discountModal, setDiscountModal] = useState<any>(false);
    const [deliveryFrequency, setDeliveryFrequency] = useState<any>([]);
    const [errorMessage, setErrorMessage] = useState<any>(null);
    const [stockErrorMessage, setStockErrorMessage] = useState<any>([]);
    const [open, setOpen] = useState<any>(false);
    const { mutateAsync: getStartedCheckout, error: checkoutError } =
        useGetStartedCheckout(user?.subscription?.id);
    const { deliveryFrequencyResult } = useDeliveryFrequency(
        user?.subscription?.id,
        !!(user?.subscription?.id && user?.subscription?.package)
    );
    const [frequency, setFrequency] = useState<any>(
        user?.subscription?.frequency
    );
    const [discountCode, setDiscountCode] = useState<any>('');
    const {
        cartResult,
        error: getCartError,
        isSuccess: getCartSuccess,
    } = useGetStartedCart(
        user?.subscription?.id,
        !!(user?.subscription?.id && user?.subscription?.package)
    );

    const {
        mutateAsync: activateGiftCard,
        isLoading: actviateGiftCardIsFetching,
        error: activateGiftCardError,
        isSuccess: activateGiftCardSuccess,
    } = useActivateGiftCard();

    const error = useSelector(selectError);
    const loading = useSelector(selectLoading);
    const giftCardActivateSuccess = useSelector(selectSuccess);
    const giftCardActivateError = useSelector(selectGiftCardsError);
    const giftCardsData = useSelector(selectData);
    const attemptedCheckout = useSelector(selectCheckout);
    const [cookies, , removeCookie] = useCookies([
        'promo',
        'giftCardActivationCode',
    ]);
    const newUser = localStorage.getItem('newuser');

    const trackSelection = useTrackSelection;
    const trackInitiateCheckout = useTrackInitiateCheckout;
    const klaviyoInitiateCheckout = useKlaviyoInitiateCheckout;
    const klaviyoCheckoutError = useKlaviyoCheckoutError;
    const klaviyoRequestError = useKlaviyoRequestError;
    const klaviyoSubmitCheckoutRequest = useKlaviyoSubmitCheckoutRequest;

    useEffect(() => {
        if (cartResult) {
            if (
                cartResult?.addOns?.length > 0 &&
                localStorage.getItem('addAddOnsToCart') === 'true'
            ) {
                trackSelection(cartResult.addOns, 'Add-Ons');
                localStorage.removeItem('addAddOnsToCart');
            }
            trackInitiateCheckout(newUser, cartResult);
            klaviyoInitiateCheckout(user);
        }
    }, [
        user,
        newUser,
        cartResult,
        trackInitiateCheckout,
        klaviyoInitiateCheckout,
        trackSelection,
    ]);

    useEffect(() => {
        if (!user?.subscription && user?.ordered) {
            window.location.href = '/dashboard/my-deliveries';
        } else if (!user?.subscription?.id || !user?.subscription?.package) {
            navigate('/get-started/your-box');
        } else {
            privateListen(
                user?.subscription?.id,
                'SubscriptionCheckedOut',
                `/dashboard/my-deliveries?newuser=${newUser}`,
                'SubscriptionCheckedOut'
            );
            privateListen(
                user?.subscription?.id,
                'SubscriptionCheckoutError',
                `/dashboard/my-deliveries?newuser=${newUser}`,
                'SubscriptionCheckoutError',
                dispatch
            );
            giftCardsPrivateListen(
                user?.id,
                'ActivatedGiftCard',
                '',
                dispatch,
                queryClient
            );
        }
    }, [dispatch, user, newUser, klaviyoInitiateCheckout, navigate]);

    const { data: discountData, status: discountStatus } = useQuery(
        ['discount', discountCode || user?.subscription?.code],
        () =>
            getDiscountMethods(
                discountCode! || user?.subscription?.code,
                user?.subscription?.id
            ),
        {
            enabled: discountCode !== '' || user?.subscription?.code !== null,
            retry: false,
        }
    );

    useEffect(() => {
        if (cookies.giftCardActivationCode) {
            activateGiftCard(cookies.giftCardActivationCode);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (actviateGiftCardIsFetching) {
            dispatch(setLoading(true));
        }
        if (activateGiftCardSuccess) {
            removeCookie('giftCardActivationCode', {
                path: '/',
            });
        }
        if (activateGiftCardError) {
            dispatch(setGiftCardsError(true));
            dispatch(setLoading(false));
            removeCookie('giftCardActivationCode', {
                path: '/',
            });
        }
    }, [
        actviateGiftCardIsFetching,
        activateGiftCardSuccess,
        activateGiftCardError,
        dispatch,
        removeCookie,
    ]);

    useEffect(() => {
        setDeliveryFrequency(
            deliveryFrequencyResult?.map((e: any, index: number) => ({
                ...e,
                id: index + 1,
                label: `${e.days / 7} weeks`,
                value: `${e.days}`,
            }))
        );

        dispatch(setStep({ index: 4, label: 'Checkout' }));
    }, [dispatch, deliveryFrequencyResult]);

    useEffect(() => {
        if (availableDiscount) {
            if (discountData?.data?.code) {
                setDiscountCode(discountData?.data?.code);
            }
            if (user?.subscription?.postcode?.startsWith('7')) {
                if (
                    discountData?.data?.items.some((item: any) =>
                        item.product.name.toLowerCase().includes('salmon')
                    ) &&
                    (discountData?.data.chooseItems === 0 ||
                        discountData?.data.chooseItems === null)
                ) {
                    setDiscountCode('');
                    setAvailableDiscount(false);
                    setDiscountModal(true);
                }
            }
        } else if (
            discountData?.data?.items.some((item: any) =>
                item.product.name.toLowerCase().includes('salmon')
            ) &&
            (discountData?.data.chooseItems === 0 ||
                discountData?.data.chooseItems === null) &&
            user?.subscription?.postcode?.startsWith('7')
        ) {
            setDiscountCode('');
            setDiscountModal(true);
        }
    }, [
        user?.subscription?.code,
        discountData,
        user?.subscription?.postcode,
        availableDiscount,
        discountCode,
    ]);

    useEffect(() => {
        if (cookies?.promo) {
            setDiscountCode(cookies.promo);
        }
    }, [cookies]);

    useEffect(() => {
        localStorage.setItem('giftCard', JSON.stringify(giftCardsData));
    }, [giftCardsData]);

    useEffect(() => {
        if (discountStatus === 'error') {
            if (cookies?.promo) {
                removeCookie('promo', {
                    path: '/',
                });
            }
        }
    }, [discountStatus, cookies, removeCookie]);

    useEffect(() => {
        if ((getCartError as any)?.response?.status) {
            klaviyoRequestError(user, getCartError);
            if (
                (getCartError as any)?.response?.status === 401 ||
                (getCartError as any)?.response?.status === 419
            ) {
                dispatch(setUnauthenticated(true));
            } else {
                dispatch(setUnexpectedError(true));
            }
        }
    }, [dispatch, getCartError, user, klaviyoRequestError]);

    const handleSubmit = useHandleSubmit(
        checkoutFormSchema,
        (v) => getStartedCheckout(makeCheckoutRequest(v)),
        ({ helpers, values }) => {
            helpers.setTouched({}, false);
            helpers.setFieldValue('expiration', '');
            helpers.setFieldValue('cvc', '');
            helpers.setFieldValue('number', '');
            queryClient.invalidateQueries(['paymentMethods']);
            klaviyoSubmitCheckoutRequest(user, values);
            if (!window.Echo.socketId()) {
                window.location.href = `/dashboard/my-deliveries?newuser=${newUser}`;
            }
        },
        dispatch
    );

    useEffect(() => {
        if (checkoutError) {
            klaviyoCheckoutError(user, checkoutError);
            setErrorMessage((checkoutError as any).response.data.message);
            setOpen(true);
        }
        if (error) {
            klaviyoCheckoutError(user, error.error);
            if (Object.keys(error?.error)[0].split('.', 1)[0] === 'choices') {
                setStockErrorMessage(Object.entries(error.error));
                setOpen(true);
            } else if (error.error[Object.keys(error.error)[0]][0]) {
                setErrorMessage(error.error[Object.keys(error.error)[0]]);
                setOpen(true);
            } else {
                setOpen(false);
            }
        }
    }, [checkoutError, user, klaviyoCheckoutError, error]);

    // Do not render the page, we will navigate back shortly.
    if (!user?.subscription?.id) return null;

    return (
        <Wrapper className="relative  md:w-[calc(100%_-_40px)] md:max-w-[1054px]">
            <Helmet>
                <title>Checkout | ButcherCrowd</title>
                <meta
                    name="description"
                    content="Curated or customised meat & seafood boxes delivered for your everyday cooking essentials"
                />
            </Helmet>
            <ThreeDotsLoader visible={loading || !getCartSuccess} />
            <div className="pt-12 text-center text-4xl font-medium uppercase">
                Checkout
            </div>
            <p className="pt-2 text-center text-lg font-normal">
                Order Now & Get FREE Shipping
            </p>
            <div>
                {user?.hasPassword ? (
                    <Formik<Partial<CheckoutFormModel>>
                        initialValues={{
                            firstName: user?.firstName,
                            lastName: user?.lastName,
                            phone: user?.phone.replace('+', ''),
                            email: user?.email,
                            postcode: user?.subscription?.postcode,
                            country: 'Australia',
                            shippingCity: '',
                            useShippingAddress: true,
                            billingCountry: 'AU',
                            hasSms: user?.sms,
                            hasNewsletter: user?.newsletter,
                            hasPassword: user?.hasPassword,
                        }}
                        validationSchema={checkoutFormSchema}
                        onSubmit={handleSubmit}
                    >
                        <div className="flex w-full items-start pt-12">
                            <div className="grow lg:mr-2">
                                <CheckoutForm
                                    frequency={frequency}
                                    discount={discountData}
                                    creditCardInvalid={error}
                                    attemptedCheckout={attemptedCheckout}
                                    hasKnife={
                                        cartResult?.addOns?.some(
                                            (item: any) =>
                                                item.name ===
                                                'ButcherCrowd Chefs Knife'
                                        ) ||
                                        discountData?.data?.items?.some(
                                            (item: any) =>
                                                item.product.name ===
                                                'ButcherCrowd Chefs Knife'
                                        )
                                    }
                                />
                            </div>
                            <CheckoutSummaryCard
                                subscriptionId={user?.subscription?.id}
                                packageId={user?.subscription?.package}
                                startedAt={user?.subscription?.startedAt}
                                frequency={frequency}
                                setFrequency={setFrequency}
                                discountCode={discountCode}
                                setDiscountCode={setDiscountCode}
                                giftCardsData={giftCardsData}
                                deliveryFrequencyResult={deliveryFrequency}
                                packageInfo={cartResult?.package}
                                products={cartResult?.products}
                                addOns={cartResult?.addOns}
                                postcode={user?.subscription?.postcode}
                            />
                        </div>
                    </Formik>
                ) : (
                    <Formik<Partial<CheckoutFormModel>>
                        initialValues={{
                            email: user?.email,
                            postcode: user?.subscription?.postcode,
                            country: 'Australia',
                            shippingCity: '',
                            useShippingAddress: true,
                            billingCountry: 'AU',
                            hasPassword: user?.hasPassword,
                            hasSms: user?.sms,
                            hasNewsletter: user?.newsletter,
                        }}
                        validationSchema={checkoutFormSchema}
                        onSubmit={handleSubmit}
                    >
                        <div className="flex w-full items-start pt-12">
                            <div className="grow lg:mr-2">
                                <CheckoutForm
                                    frequency={frequency}
                                    discount={discountData}
                                    creditCardInvalid={error}
                                    attemptedCheckout={attemptedCheckout}
                                    hasKnife={
                                        cartResult?.addOns?.some(
                                            (item: any) =>
                                                item.name ===
                                                'ButcherCrowd Chefs Knife'
                                        ) ||
                                        discountData?.data?.items?.some(
                                            (item: any) =>
                                                item.product.name ===
                                                'ButcherCrowd Chefs Knife'
                                        )
                                    }
                                />
                            </div>
                            <CheckoutSummaryCard
                                subscriptionId={user?.subscription?.id}
                                packageId={user?.subscription?.package}
                                startedAt={user?.subscription?.startedAt}
                                frequency={frequency}
                                setFrequency={setFrequency}
                                discountCode={discountCode}
                                setDiscountCode={setDiscountCode}
                                giftCardsData={giftCardsData}
                                deliveryFrequencyResult={deliveryFrequency}
                                packageInfo={cartResult?.package}
                                products={cartResult?.products}
                                addOns={cartResult?.addOns}
                                postcode={user?.subscription?.postcode}
                            />
                        </div>
                    </Formik>
                )}
            </div>
            <RedirectAfterErrorModal />
            {open && stockErrorMessage?.length > 0 && (
                <AlertModal
                    open={open}
                    onClose={setOpen}
                    title="Some selections just went out of stock, please make a different selection."
                >
                    <div className="mt-8 flex flex-col">
                        {stockErrorMessage && (
                            <div className="flex flex-col">
                                {stockErrorMessage.map((e: any, i: any) => (
                                    <span
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={i}
                                        className="mb-2 text-xs text-primary"
                                    >
                                        {e[1]}
                                    </span>
                                ))}
                            </div>
                        )}
                        <Button
                            type="button"
                            className="mt-2 flex justify-center rounded bg-black !font-neue text-base font-medium normal-case"
                            onClick={() => {
                                setOpen(false);
                                navigate('/get-started/your-items');
                            }}
                        >
                            Ok
                        </Button>
                    </div>
                </AlertModal>
            )}
            {open && errorMessage && (
                <AlertModal open={open} onClose={setOpen} title={errorMessage}>
                    <div className="mt-8 flex flex-col">
                        <Button
                            type="button"
                            className="mt-2 flex justify-center rounded bg-black !font-neue text-base font-medium normal-case"
                            onClick={() => {
                                dispatch(resetCheckoutError());
                                setOpen(false);
                                setErrorMessage(null);
                            }}
                        >
                            Ok
                        </Button>
                    </div>
                </AlertModal>
            )}
            {(giftCardActivateSuccess || giftCardActivateError) && (
                <AlertModal
                    open={giftCardActivateSuccess || giftCardActivateError}
                    onClose={setSuccess || setGiftCardsError}
                    title={giftCardActivateSuccess ? 'Success' : 'Error'}
                >
                    <div className="mt-4 flex flex-col">
                        <span className="text-sm">
                            {giftCardActivateSuccess
                                ? 'Your card has been successfully activated.'
                                : 'Sorry, this gift card does not exist or has already been claimed.'}
                        </span>
                        <Button
                            type="button"
                            className="mt-2 flex justify-center rounded bg-black !font-neue text-base font-medium normal-case"
                            onClick={() => {
                                dispatch(resetGiftCardsError());
                                dispatch(resetGiftCardsSuccess());
                            }}
                        >
                            Ok
                        </Button>
                    </div>
                </AlertModal>
            )}
            {discountModal && (
                <AlertModal
                    open={discountModal}
                    onClose={setDiscountModal}
                    title="Warning"
                >
                    <div className="mt-4 flex flex-col">
                        <span className="text-sm">
                            This promotion is not applicable to Tasmania
                            customers due quarantine restrictions with our wild
                            salmon.
                        </span>
                        <Button
                            type="button"
                            className="mt-2 flex justify-center rounded bg-black !font-neue text-base font-medium normal-case"
                            onClick={() => {
                                setDiscountModal(false);
                            }}
                        >
                            Ok
                        </Button>
                    </div>
                </AlertModal>
            )}
        </Wrapper>
    );
}
