import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Wrapper from '@/components/common/Wrapper';
import Breadcrumbs from '@/components/navigation/Breadcrumbs';
import { useTerms } from '@/services/termsService';

const OGImage = `${window.cdn}smo/SMO-homepage.png`;

const routes = [
    {
        route: '/terms',
        title: 'Terms and Conditions',
    },
];

export default function TermsConditionsPage() {
    const { termsData } = useTerms();

    useEffect(() => {
        localStorage.clear();
    }, []);

    return (
        <>
            <Helmet>
                <title>Terms and Conditions | ButcherCrowd</title>
                <meta
                    name="description"
                    content="Curated or customised meat & seafood boxes delivered for your everyday cooking essentials"
                />
                <meta
                    property="og:title"
                    content="Terms and Conditions | ButcherCrowd"
                />
                <meta
                    property="og:description"
                    content="Curated or customised meat & seafood boxes delivered for your everyday cooking essentials"
                />
                <meta property="og:image" content={OGImage} />
            </Helmet>
            <Wrapper>
                <div className="hidden px-8 pt-8 sm:block lg:mx-7">
                    <Breadcrumbs crumbs={routes} />
                </div>
                <div className="mx-4 my-12 text-2xl font-medium sm:mt-8 md:mx-16 md:text-5xl lg:mx-40 xl:mx-80">
                    <div
                        className="text-center text-3xl font-bold sm:text-4xl sm:normal-case"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: termsData?.header }}
                    />
                    <div
                        className="w-full pt-12 font-neue text-lg"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: termsData?.content }}
                    />
                </div>
            </Wrapper>
        </>
    );
}
