import { InferType, number, object } from 'yup';

export const giftCardFormSchema = object({
    amount: number().label('Amount'),
    customAmount: number().label('Amount'),
});

type GiftCardFormModel = InferType<typeof giftCardFormSchema>;

export default GiftCardFormModel;
