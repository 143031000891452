import { useMemo } from 'react';
import DeliveryRegionsGroups from '@/types/DeliveryRegionsGroups';
import DeliveryRegionsOptionType from '@/types/DeliveryRegionsOptionType';

export default function useDeliveryRegionsGroups() {
    const deliveryRegionsGroupsOptions: DeliveryRegionsOptionType<DeliveryRegionsGroups>[] =
        useMemo(
            () => [
                {
                    id: 1,
                    label: 'NSW regions',
                    value: 'nsw-regions',
                    data: [
                        {
                            name: 'SYDNEY METRO',
                            days: 'Monday – Saturday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'SYDNEY REGIONAL',
                            days: 'Tuesday – Saturday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'SYDNEY SOUTHWEST',
                            days: 'Monday – Thursday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'WOLLONGONG',
                            days: 'Monday, Tuesday & Saturday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'CENTRAL COAST',
                            days: 'Monday, Tuesday & Saturday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'SOUTHERN HIGHLANDS',
                            days: 'Tuesday & Saturday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'SOUTH COAST',
                            days: 'Tuesday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'BLUE MOUNTAINS',
                            days: 'Monday & Wednesday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'LITHGOW',
                            days: 'Wednesday & Saturday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'BATHURST',
                            days: 'Wednesday & Saturday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'ORANGE',
                            days: 'Wednesday & Saturday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'DUBBO',
                            days: 'Wednesday & Saturday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'FORBES',
                            days: 'Wednesday & Saturday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'MUDGEE',
                            days: 'Wednesday & Saturday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'NEWCASTLE AREA',
                            days: 'Monday, Tuesday & Saturday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'PORT MACQUARIE',
                            days: 'Wednesday & Saturday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'COFFS HARBOUR ',
                            days: 'Wednesday & Saturday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'TAREE',
                            days: 'Wednesday & Saturday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'GRAFTON',
                            days: 'Wednesday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'LISMORE',
                            days: 'Wednesday & Saturday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'SINGLETON',
                            days: 'Wednesday & Saturday ',
                            time: 'Between 8am – 6pm',
                        },
                    ],
                },
                {
                    id: 2,
                    label: 'Canberra & nearby regions',
                    value: 'canberra-and-nearby-regions',
                    data: [
                        {
                            name: 'GOULBURN',
                            days: 'Tuesday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'SOUTH COAST REGIONAL',
                            days: 'Tuesday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'CANBERRA REGIONAL',
                            days: 'Wednesday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'CANBERRA METRO',
                            days: 'Monday – Wednesday & Friday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'YASS',
                            days: 'Wednesday & Saturday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'GRIFFITH',
                            days: 'Wednesday',
                            time: 'Between 8am – 6pm',
                        },
                    ],
                },
                {
                    id: 3,
                    label: 'SA region',
                    value: 'sa-region',
                    data: [
                        {
                            name: 'ADELAIDE & MT GAMBIER',
                            days: 'Monday – Wednesday, Friday & Saturday',
                            time: 'Between 8am – 6pm',
                        },
                    ],
                },
                {
                    id: 4,
                    label: 'NT region',
                    value: 'nt-region',
                    data: [
                        {
                            name: 'DARWIN AREA',
                            days: 'Third Monday of each month',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'KATHERINE',
                            days: 'Fourth Sunday of each month',
                            time: 'Between 8am – 6pm',
                        },
                    ],
                },
                {
                    id: 5,
                    label: 'WA region',
                    value: 'wa-region',
                    data: [
                        {
                            name: 'PERTH TO MARGARET RIVER',
                            days: 'Friday - Wednesday',
                            time: 'Between 8am – 6pm',
                            note: '(deliveries are fortnightly. Please enquire for specific dates if needed)',
                        },
                    ],
                },
                {
                    id: 6,
                    label: 'TAS region',
                    value: 'tas-region',
                    data: [
                        {
                            name: 'HOBART AREA',
                            days: 'Wednesday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'LAUNCESTON AREA',
                            days: 'Tuesday',
                            time: 'Between 8am – 6pm',
                        },
                    ],
                },
                {
                    id: 7,
                    label: 'Victoria regions',
                    value: 'victoria-regions',
                    data: [
                        {
                            name: 'MELBOURNE METRO',
                            days: 'Monday – Wednesday, Friday & Saturday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'GEELONG',
                            days: 'Monday, Tuesday, Friday & Saturday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'MORNINGTON PENINSULA',
                            days: 'Tuesday & Friday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'MELBOURNE REGIONAL',
                            days: 'Monday – Wednesday, Friday & Saturday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'CENTRAL HIGHLANDS',
                            days: 'Monday – Wednesday & Saturday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'YARRA RANGES',
                            days: 'Tuesday, Wednesday, Friday & Saturday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'GIPPSLAND',
                            days: 'Tuesday & Sunday ',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'GIPPSLAND SOUTH',
                            days: 'Tuesday & Sunday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'BENDIGO',
                            days: 'Monday & Wednesday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'BALLARAT',
                            days: 'Monday & Wednesday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'SHEPPARTON',
                            days: 'Monday, Wednesday & Saturday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'WEST VIC',
                            days: 'Wednesday & Saturday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'SOUTH WEST VIC',
                            days: 'Wednesday & Saturday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'NORTH WEST VIC',
                            days: 'Wednesday & Saturday',
                            time: 'Between 8am – 6pm',
                        },
                    ],
                },
                {
                    id: 8,
                    label: 'Southeast QLD region',
                    value: 'southeast-qld-region',
                    data: [
                        {
                            name: 'BRISBANE AREA',
                            days: 'Monday – Saturday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'GOLD COAST AREA',
                            days: 'Monday, Wednesday & Friday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'TWEED HEADS',
                            days: 'Monday & Wednesday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'SUNSHINE COAST',
                            days: 'Tuesday, Friday & Saturday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'TOOWOOMBA',
                            days: 'Monday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'JIMBOOMBA',
                            days: 'Wednesday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'KINGAROY',
                            days: 'Wednesday',
                            time: 'Between 8am – 6pm',
                        },
                    ],
                },
                {
                    id: 9,
                    label: 'Central QLD coast region',
                    value: 'central-qld-coast-region',
                    data: [
                        {
                            name: 'BUNDABERG',
                            days: 'Tuesday & Wednesday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'HERVEY BAY',
                            days: 'Monday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'AGNES WATER',
                            days: 'Tuesday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'MACKAY',
                            days: 'Monday & Tuesday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'MORANBAH',
                            days: 'Wednesday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'ROCKHAMPTON',
                            days: 'Wednesday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'GLADSTONE REGION',
                            days: 'Monday & Saturday',
                            time: 'Between 8am – 6pm',
                        },
                    ],
                },
                {
                    id: 10,
                    label: 'North QLD region',
                    value: 'north-qld-region',
                    data: [
                        {
                            name: 'CAIRNS & ATHERTON TABLELAND',
                            days: 'Tuesday & Wednesday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'PORT DOUGLAS AREA',
                            days: 'Wednesday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'INNISFAIL AREA',
                            days: 'Tuesday',
                            time: 'Between 8am – 6pm',
                        },
                        {
                            name: 'TOWNSVILLE REGION',
                            days: 'Wednesday',
                            time: 'Between 8am – 6pm',
                        },
                    ],
                },
            ],
            []
        );

    return deliveryRegionsGroupsOptions;
}
