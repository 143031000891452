import DeliveryRegionsResultType from '@/types/DeliveryRegionsResultType';

type DeliveryRegionsResultsProps = {
    selected?: string;
    results?: Array<DeliveryRegionsResultType>;
};

export default function DeliveryRegionsResults(
    props: DeliveryRegionsResultsProps
) {
    const { selected, results } = props;
    return (
        <div className="w-full">
            <h3 className="mt-5 text-2xl font-bold uppercase md:text-3xl">
                {selected}
            </h3>
            {/* <DeliveryRegionsResult results="regionsResults" /> */}
            <div className="grid w-full  grid-cols-1 gap-6 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                {results?.map((result) => (
                    <div className="mt-4" key={result.name}>
                        <h4 className="text-lg font-medium ">{result.name}</h4>
                        <p className="text-base">{result.days}</p>
                        <p className="text-base">{result.time}</p>
                        {result.note && (
                            <p className="text-base">{result.note}</p>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}
