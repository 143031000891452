import { Formik } from 'formik';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import AsSeenOn from '@/components/common/AsSeenOn';
import Wrapper from '@/components/common/Wrapper';
import PersonalizeGiftCardForm from '@/components/forms/giftCard/PersonalizeGiftCardForm';
import Breadcrumbs from '@/components/navigation/Breadcrumbs';
import PersonalizeGiftCardFormModel, {
    personalizeGiftCardFormSchema,
} from '@/models/PersonalizeGiftCardFormModel';
import { useGiftCardSettings } from '@/services/giftCardService';

const OGImage = `${window.cdn}smo/SMO-gift-card.png`;

const routes = [
    {
        route: '/gift-cards',
        title: 'Gift card',
    },
];

export default function GiftCardPersonalizePage() {
    const navigate = useNavigate();
    const [cookies] = useCookies(['giftCardAmount']);
    const [, setCookie] = useCookies(['personalizeGiftCardValues']);
    const { giftCardSettingsResult } = useGiftCardSettings();

    useEffect(() => {
        if (!cookies.giftCardAmount) {
            navigate('/gift-cards');
        }
    }, [cookies, navigate]);

    return (
        <Wrapper>
            <Helmet>
                <title>Gifts card | ButcherCrowd</title>
                <meta
                    name="description"
                    content="Gift the joy of ethical, high-quality, sustainably sourced meat & wild-caught seafood, with a ButcherCrowd gift card. Perfect for any occasion, our gift cards are valid for use on all ButcherCrowd boxes."
                />
                <meta
                    property="og:title"
                    content="Gifts card | ButcherCrowd"
                />
                <meta
                    property="og:description"
                    content="Gift the joy of ethical, high-quality, sustainably sourced meat & wild-caught seafood, with a ButcherCrowd gift card. Perfect for any occasion, our gift cards are valid for use on all ButcherCrowd boxes."
                />
                <meta property="og:image" content={OGImage} />
            </Helmet>
            <div className="px-4 pt-8 pb-8 sm:block sm:px-8 lg:mx-7">
                <Breadcrumbs crumbs={routes} />
            </div>
            <div className="flex flex-col items-center px-4">
                <h3 className="text-3xl font-bold text-center sm:text-4xl">
                    Personalise your gift card
                </h3>
            </div>
            <Formik<Partial<PersonalizeGiftCardFormModel>>
                initialValues={{
                    receiverMessage:
                        'I hope you enjoy your premium meat & wild-caught seafood! Bon Appetit!',
                    bonusReceiverMessage:
                        'I hope you enjoy your premium meat & wild-caught seafood! Bon Appetit!',
                }}
                validationSchema={personalizeGiftCardFormSchema}
                onSubmit={(values) => {
                    setCookie('personalizeGiftCardValues', values, {
                        maxAge: 3600 * 24,
                        path: '/',
                    });
                    navigate('/gift-cards/checkout');
                }}
            >
                <div className="flex items-start w-full pt-12">
                    <div className="grow lg:mr-2">
                        <PersonalizeGiftCardForm
                            amount={cookies.giftCardAmount / 100}
                            isGiftCardPromotion={giftCardSettingsResult}
                        />
                    </div>
                </div>
            </Formik>
            <div className="mt-20">
                <AsSeenOn />
            </div>
        </Wrapper>
    );
}
