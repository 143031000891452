import formatPhoneNumber from '@/utils/formatPhoneNumber';
import {
    customDateAdd10Minutes,
    customDateSet,
    isPassOrToday,
} from '@/utils/luxonFormats';
import CheckoutGiftCardFormModel from './CheckoutGiftCardFormModel';

type CheckoutGiftCardRequestModel = {
    billingFirstName: string;
    billingLastName: string;
    email?: string;
    billingPhone: string;
    password?: string;
    passwordConfirmation?: string;
    billingAddressLine_1: string;
    billingAddressLine_2?: string;
    billingCity: string;
    billingPostcode: string;
    billingState: string;
    billingCountry: string;
    number?: string;
    month?: number;
    year?: number;
    cvc?: string;
    paymentMethod?: string;
    sms?: boolean;
    newsletter?: boolean;
    value: number;
    senderName: string;
    senderEmail: string;
    receiverName: string;
    receiverEmail: string;
    receiverMessage: string;
    receiverDeliveryDate: string;
    bonusReceiverName?: string;
    bonusReceiverEmail?: string;
    bonusReceiverMessage?: string;
    bonusReceiverDeliveryDate?: string;
};

export default CheckoutGiftCardRequestModel;

export const makeCheckoutGiftCardRequest = (
    form: CheckoutGiftCardFormModel
): CheckoutGiftCardRequestModel => ({
    billingFirstName: form.firstName,
    billingLastName: form.lastName,
    email: form.email,
    billingPhone: formatPhoneNumber(form.phone),
    password: form.password,
    passwordConfirmation: form.password,
    billingAddressLine_1: form.billingAddressLine_1,
    billingAddressLine_2: form.suite,
    billingCity: form.billingCity,
    billingPostcode: form.postcode,
    billingCountry: 'AU',
    billingState: form.billingState,
    number: form.number,
    // eslint-disable-next-line no-nested-ternary
    month: form.expiration
        ? form.expiration?.length === 3
            ? Number(form.expiration.replace(/\//g, '').slice(0, 1))
            : Number(form.expiration.replace(/\//g, '').slice(0, 2))
        : undefined,
    // eslint-disable-next-line no-nested-ternary
    year: form.expiration
        ? 2000 + Number(form.expiration.replace(/\//g, '').slice(-2))
        : undefined,
    cvc: form.cvc,
    paymentMethod: form.paymentMethod,
    sms: form.sms,
    newsletter: form.newsletter,
    value: Math.round(form.value),
    senderName: form.senderName,
    senderEmail: form.senderEmail,
    receiverName: form.receiverName,
    receiverEmail: form.receiverEmail,
    receiverDeliveryDate: isPassOrToday(form.receiverDeliveryDate)
        ? customDateAdd10Minutes(form.receiverDeliveryDate)
        : customDateSet(form.receiverDeliveryDate),
    receiverMessage: form.receiverMessage || '',
    bonusReceiverName: form.bonusReceiverName || '',
    bonusReceiverEmail: form.bonusReceiverEmail || '',
    bonusReceiverMessage: form.bonusReceiverName
        ? form.bonusReceiverMessage
        : '',
    // eslint-disable-next-line no-nested-ternary
    bonusReceiverDeliveryDate: form.bonusReceiverDeliveryDate
        ? isPassOrToday(form.bonusReceiverDeliveryDate)
            ? customDateAdd10Minutes(form.bonusReceiverDeliveryDate)
            : customDateSet(form.bonusReceiverDeliveryDate)
        : '',
});
