import { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import AsSeenOn from '@/components/common/AsSeenOn';
import Button from '@/components/common/Button';
import { Grap, GrapMobile, PlayButton } from '@/components/common/Icons';
import Step from '@/components/common/Step';
import Wrapper from '@/components/common/Wrapper';
import Breadcrumbs from '@/components/navigation/Breadcrumbs';
import useBreakpoints from '@/utils/useBreakPoints';

const Certified = `${window.cdn}CertifiedHum.png`;
const BeefImageLabel = `${window.cdn}beefProgram.png`;
const PersonalPic = `${window.cdn}whyus/53061946-0-Personal-1.jpeg`;
const BeefPasture = `${window.cdn}whyus/beef-pasture.jpeg`;
const ChikensPasture = `${window.cdn}whyus/chickens-pasture.jpeg`;
const ManWalk = `${window.cdn}whyus/man-walking.jpeg`;
const Sunrise = `${window.cdn}whyus/sunrise.png`;
const LearnAboutUs = `${window.cdn}whyus/learn-about-us.png`;
const BirdImage = `${window.cdn}whyus/why-us-bird.png`;
const CowImage = `${window.cdn}whyus/why-us-cow.jpg`;
const LearnAboutUsVideoPlaceholder = `${window.cdn}whyus/learn-more-about-us-video-placeholder.jpg`;
const OGImage = `${window.cdn}smo/SMO-why-us.png`;

const routes = [
    {
        route: '/why-us',
        title: 'Why Us',
    },
];

export default function WhyUsPage() {
    const { tabletV } = useBreakpoints();
    const [isPlaying, setIsPlaying] = useState(false);

    const handleVideoPlay = () => {
        setIsPlaying(true);
    };

    const videoRef = useRef<HTMLVideoElement>(null);

    const handlePlay = () => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    };

    return (
        <>
            <Helmet>
                <title>Why us | ButcherCrowd</title>
                <meta
                    name="description"
                    content="Curated or customised meat & seafood boxes delivered for your everyday cooking essentials"
                />
                <meta property="og:title" content="Why us | ButcherCrowd" />
                <meta
                    property="og:description"
                    content="Curated or customised meat & seafood boxes delivered for your everyday cooking essentials"
                />
                <meta property="og:image" content={OGImage} />
            </Helmet>
            <div>
                <div
                    className="
                bg-[#fdfcfc]
                bg-[url('https://cdn.butchercrowd.com.au/static/whyus/farm-butcher-mobile.jpeg')]
                bg-contain
                bg-bottom
                bg-no-repeat
                pb-[60%]
                md:pb-0
                md:bg-white
                md:bg-[url('https://cdn.butchercrowd.com.au/static/whyus/why-us-sky.jpg')]
                md:bg-top
            "
                >
                    <Wrapper>
                        <div className="px-8 pt-8 pb-8 sm:block lg:mx-7">
                            <Breadcrumbs crumbs={routes} />
                        </div>
                    </Wrapper>
                    <div className="flex flex-col items-center px-4">
                        <h3 className="text-4xl font-medium text-center uppercase font-neue sm:normal-case md:text-5xl">
                            Join ButcherCrowd and help drive change!
                        </h3>
                        <div className="relative w-full pt-6 lg:w-[40%]">
                            <p className="text-center font-neue text-lg">
                                Joining ButcherCrowd helps grow a community focused
                                on connecting people with their food - food, as
                                nature intended, so we can all share better meals
                                together.
                            </p>
                            <img
                                className="hidden absolute top-0 -right-40 lg:block"
                                src={BirdImage}
                                alt="Flying bird"
                            />
                        </div>
                        <div className="relative flex justify-center pt-6 lg:w-[40%]">
                            <img
                                className="absolute -left-[165px] top-0 bottom-0 my-auto hidden h-auto w-40 -rotate-[20deg] lg:block xl:-left-[210px] xl:w-52 2xl:-left-[340px] 2xl:w-80"
                                src={LearnAboutUs}
                                alt="Learn more about us"
                            />
                            <video
                                ref={videoRef}
                                className="w-full"
                                controls={isPlaying}
                                poster={LearnAboutUsVideoPlaceholder}
                                onPlay={handleVideoPlay}
                            >
                                <source
                                    src="https://cdn.butchercrowd.com.au/static/whyus/learn-more-about-us-video.mp4"
                                    type="video/mp4"
                                />
                                <track default kind="captions" />
                                Your browser does not support the video tag.
                            </video>
                            {!isPlaying && (
                                <div className="absolute left-0 right-0 top-0 bottom-0 m-auto w-[15%] h-fit">
                                    <PlayButton
                                        className="w-full h-auto text-white transition-colors hover:cursor-pointer hover:text-primary"
                                        onClick={handlePlay}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="flex mt-6 mb-5 md:mb-10 ">
                            <img
                                className="h-12 sm:h-16"
                                src={Certified}
                                alt="Never Ever Beef Program"
                            />
                            <img
                                className="h-12 ml-9 sm:h-16"
                                src={BeefImageLabel}
                                alt="Beef label"
                            />
                        </div>
                        <Button asChild secondary size="xl">
                            <Link to="/get-started">Join now</Link>
                        </Button>
                        <p className="m-auto mt-5 w-[658px] max-w-full text-center font-neue text-xs md:mt-10">
                            * Certified Humane - Meets the Humane Farm Animal
                            Care Program standards. Which include nutritious
                            diet without antibiotics, or hormones, animals
                            raised with shelter, resting areas, sufficient space
                            and the ability to engage in natural behaviours.
                        </p>
                    </div>
                    <img
                        className="hidden w-full md:block"
                        src={CowImage}
                        alt="A field of cow"
                    />
                </div>
                <div>
                    {/* Step 1 - We source */}

                    <div className=" pb-28">
                        {/* Step 1 - We source */}
                        <Step
                            title="Our FARMERS
                    & FISHERMAN"
                            text="We are the marketplace for high quality meat and wild-caught seafood, sourced from Australian farming & fishing families. We only work with families that align with our values. We believe that if you know where your meat & seafood is from, how it has lived - then you'll feel more connected with your food, to help make better decisions for the health of yourself and loved ones."
                            imageSrc={ManWalk}
                            imageAlt="A man walking in a beef farm"
                            imageLeft
                        />
                        {/* Step 2 - Select your box */}
                        <Step
                            title="OUR ANIMALS"
                            text="All our beef, lamb, chicken & pork have access to roam on pastures, and our seafood is wild-caught from sustainable fisheries. They eat fresh grass, breathe fresh air, and are sourced from pristine waters - they have lived a life as nature intended. The animals are cared for and treated humanely, giving them a good quality of life. The grade of your meat is directly influenced by how the animal lived."
                            imageSrc={BeefPasture}
                            imageAlt="A field of chicken walking around."
                        />
                        {/* Step 3 - We deliver to your door */}
                        <Step
                            text="These are the big questions that led to ButcherCrowd's conception and exactly what we expect of every farmer we work with."
                            imageSrc={ChikensPasture}
                            imageAlt="Chickens on a farm"
                            imageLeft
                        >
                            <div className="font-normal list-none sm:font-medium">
                                <li className="m-0 list-none bg-[url('https://cdn.butchercrowd.com.au/static/check.svg')] bg-left-top bg-no-repeat pl-10 pb-2.5">
                                    Sustainably Sourced
                                </li>
                                <li className="m-0 list-none bg-[url('https://cdn.butchercrowd.com.au/static/check.svg')] bg-left-top bg-no-repeat pl-10 pb-2.5">
                                    Humanely raised & handled{' '}
                                </li>
                                <li className="m-0 list-none bg-[url('https://cdn.butchercrowd.com.au/static/check.svg')] bg-left-top bg-no-repeat pl-10 pb-2.5">
                                    100% Grass-fed & finished, free-range,
                                    pasture-raised
                                </li>
                                <li className="m-0 list-none bg-[url('https://cdn.butchercrowd.com.au/static/check.svg')] bg-left-top bg-no-repeat pl-10 pb-2.5">
                                    No hormones or antibiotics used for growth
                                    promotion
                                </li>
                                <li className="m-0 list-none bg-[url('https://cdn.butchercrowd.com.au/static/check.svg')] bg-left-top bg-no-repeat pl-10 pb-2.5">
                                    Better for you and the planet
                                </li>
                            </div>
                        </Step>
                        <div className="pb-8 pt-28">
                            <div className="px-5 m-auto sm:px-0">
                                <div className="relative mb-5">
                                    <h4 className="w-full m-auto text-2xl font-bold text-center font-neue lg:text-3xl">
                                        Connecting you with better food. A{' '}
                                        <span className="relative inline-block">
                                            better way
                                            <div className="absolute top-7 -left-5 h-3 w-28 bg-[url('../svg/Vector-line.svg')] bg-contain bg-no-repeat font-neue sm:top-[29px] lg:top-[38px]" />
                                        </span>{' '}
                                        of eating meat
                                    </h4>
                                </div>
                                <p className="m-auto mb-4 text-base text-center sm:mb-0 sm:text-lg md:w-2/4">
                                    Sourcing from Australian farming and fishing
                                    families and producers, means you enjoy
                                    high-quality proteins for less money.
                                </p>
                            </div>
                            {tabletV ? (
                                <GrapMobile className="pt-16 m-auto" />
                            ) : (
                                <Grap className="m-auto max-w-[calc(100%_-_40px)] lg:pt-20" />
                            )}
                        </div>
                        <div className="px-4 pt-8 pb-3 md:px-0">
                            {/* <div className="m-auto mb-5 mt-5 flex w-[1008px] flex-row justify-around">
                                <h3 className="w-4/12 text-center">
                                    Australian - Average Monthly Household Meat
                                    & Seafood Spend at Supermarkets
                                </h3>
                                <h3 className="flex items-end">
                                    ButcherCrowd Pricing
                                </h3>
                            </div> */}
                            <div className="mx-auto flex w-full flex-col items-center md:w-[1200px] md:max-w-[calc(100%_-_30px)] md:flex-row md:items-stretch md:justify-between">
                                <div className="flex w-full flex-col justify-end md:w-[calc(100%_-_300px)] lg:w-[calc(100%_-_380px)] xl:w-[calc(100%_-_400px)]">
                                    <h3 className="mt-5 mb-5 text-center">
                                        Australian - Average Monthly Household
                                        <br />
                                        Meat & Seafood Spend at Supermarkets
                                    </h3>
                                    <table className="w-full mx-auto border-2 border-black">
                                        <tbody>
                                            <tr className="text-xs text-center text-white uppercase sm:text-sm md:text-xs lg:text-sm">
                                                <th className="px-2 py-6 bg-black lg:px-4">
                                                    Household size (no. of
                                                    people)
                                                </th>
                                                <th className="px-2 py-6 bg-black lg:px-4">
                                                    Average weekly spend on meat
                                                </th>
                                                <th className="px-2 py-6 bg-black lg:px-4">
                                                    Average spend on meat
                                                    (Monthly)
                                                </th>
                                            </tr>
                                            <tr className="font-bold text-center ">
                                                <td className="py-5 text-center border-2 border-black">
                                                    1
                                                </td>
                                                <td className="py-5 text-center border-2 border-black">
                                                    $40
                                                </td>
                                                <td className="py-5 text-center border-2 border-black">
                                                    $173
                                                </td>
                                            </tr>
                                            <tr className="font-bold text-center">
                                                <td className="py-5 text-center border-2 border-black">
                                                    2
                                                </td>
                                                <td className="py-5 text-center border-2 border-black">
                                                    $53
                                                </td>
                                                <td className="py-5 text-center border-2 border-black">
                                                    $229
                                                </td>
                                            </tr>
                                            <tr className="font-bold text-center">
                                                <td className="py-5 text-center border-2 border-black">
                                                    3
                                                </td>
                                                <td className="py-5 text-center border-2 border-black">
                                                    $57
                                                </td>
                                                <td className="py-5 text-center border-2 border-black">
                                                    $247
                                                </td>
                                            </tr>
                                            <tr className="font-bold text-center">
                                                <td className="py-5 text-center border-2 border-black">
                                                    4
                                                </td>
                                                <td className="py-5 text-center border-2 border-black">
                                                    $75
                                                </td>
                                                <td className="py-5 text-center border-2 border-black">
                                                    $325
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="flex w-[340px] max-w-full flex-col justify-end md:w-[290px] lg:w-[350px]">
                                    <h3 className="mt-5 mb-5 text-center">
                                        ButcherCrowd Pricing
                                    </h3>
                                    <table className="w-full mx-auto border-2 border-primary">
                                        <tbody>
                                            <tr className="text-xs text-center text-white uppercase sm:text-sm md:text-xs lg:text-sm">
                                                <th className="px-4 py-6 border-t-4 border-x-primary border-t-primary bg-primary md:px-0">
                                                    ButcherCrowd Meat & Seafood
                                                    Subscription (Monthly)
                                                </th>
                                            </tr>
                                            <tr className="font-bold text-center">
                                                <td className="w-96 border-x-2 border-primary bg-[#F1F1F1] py-5">
                                                    $159 small box suits 1-3
                                                    people
                                                </td>
                                            </tr>
                                            <tr className="font-bold text-center">
                                                <td className="border-x-2 border-y-2 border-primary border-b-black border-t-black bg-[#F1F1F1] py-5">
                                                    $159 small box suits 1-3
                                                    people
                                                </td>
                                            </tr>
                                            <tr className="font-bold text-center">
                                                <td className="border-2  border-x-primary border-t-black  border-b-black bg-[#F1F1F1]  py-5">
                                                    $159 small box suit 1-3
                                                    people
                                                </td>
                                            </tr>
                                            <tr className="font-bold text-center">
                                                <td className="border-2 border-x-primary border-b-primary bg-[#F1F1F1] py-5">
                                                    $269 large box suits 4-5
                                                    people
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {/* <table
                                className="m-auto w-[970px] border-2 border-black"
                            >
                                <tbody>
                                    <tr className="text-sm text-center text-white uppercase">
                                        <th className="px-4 py-6 bg-black">
                                            Household size (no. of people)
                                        </th>
                                        <th className="px-4 py-6 bg-black">
                                            Average weekly spend on meat
                                        </th>
                                        <th className="px-4 py-6 bg-black">
                                            Average spend on meat (Monthly)
                                        </th>
                                        <th className="border-t-4 border-r-4 border-x-primary border-r-primary border-t-primary bg-primary>
                                            ButcherCrowd Meat & Seafood
                                            Subscription (Monthly)
                                        </th>
                                    </tr>
                                    <tr className="font-bold text-center">
                                        <td className="px-20 py-5 border-2 border-black">
                                            1
                                        </td>
                                        <td className="px-20 py-5 border-2 border-black">
                                            $40
                                        </td>
                                        <td className="px-20 py-5 border-2 border-y-black border-r-primary border-l-black>
                                            $173
                                        </td>
                                        <td className="w-96 border-x-2 border-primary bg-[#F1F1F1]">
                                            $159 small box suits 1-3 people
                                        </td>
                                    </tr>
                                    <tr className="font-bold text-center">
                                        <td className="px-20 py-5 border-2 border-black">
                                            2
                                        </td>
                                        <td className="px-20 py-5 border-2 border-black">
                                            $53
                                        </td>
                                        <td className="px-20 py-5 border-2 border-y-black border-r-primary border-l-black">
                                            $229
                                        </td>
                                        <td className="border-x-2 border-y-2 border-primary border-b-black border-t-black bg-[#F1F1F1]">
                                            $159 small box suits 1-3 people
                                        </td>
                                    </tr>
                                    <tr className="font-bold text-center">
                                        <td className="px-20 py-5 border-2 border-black 
                                            3
                                        </td>
                                        <td className="px-20 py-5 border-2 border-black">
                                            $57
                                        </td>
                                        <td className="px-20 py-5 border-2 border-y-black border-r-primary border-l-black>
                                            $247
                                        </td>
                                        <td className="border-2  border-x-primary border-t-black border-b-black  bg-[#F1F1F1]">
                                            $159 small box suit 1-3 people
                                        </td>
                                    </tr>
                                    <tr className="font-bold text-center">
                                        <td className="px-20 py-5 border-2 border-black">
                                            4
                                        </td>
                                        <td className="px-20 py-5 border-2 border-black">
                                            $75
                                        </td>
                                        <td className="px-20 py-5 border-2 border-y-black border-r-primary border-l-black">
                                            $325
                                        </td>
                                        <td className="border-2 border-x-primary border-b-primary bg-[#F1F1F1]">
                                            $269 large box suits 4-5 people
                                        </td>
                                    </tr>
                                </tbody>
                            </table> */}
                        </div>
                        <div className="flex flex-col items-center mt-5 mb-5 text-xs">
                            <span className="mb-12">
                                Source: Canstar Blue research, December 2021.
                            </span>
                            <Button asChild secondary size="xl">
                                <Link to="/get-started">Subscribe now</Link>
                            </Button>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col lg:flex-row">
                    <div className="flex w-full flex-col justify-center px-7 lg:w-1/2 xl:px-[107px] ">
                        <h2 className="text-5xl font-bold text-left uppercase mb-7 mt-7 xl:text-6xl ">
                            our story
                        </h2>

                        <p className="w-full mb-2 text-sm leading-6 lg:mb-0 xl:mb-7 xl:text-lg">
                            ButcherCrowd founders, brothers Damien and Rob
                            Moffatt, grew up on the Atherton Tablelands, with a
                            lifestyle that provided access to meat direct from
                            the family farm. To them, ethical farming practices
                            and real, quality meat without the additives were an
                            everyday occurrence.
                        </p>
                        <p className="w-full text-sm pb-7 leading-6 lg:mb-0 xl:mb-3 xl:text-lg">
                            They were disappointed to find that most meat
                            products were not free to roam, and that seafood was
                            not sustainably sourced from the wild; as nature
                            intended. They frequently contained harmful
                            chemicals to support mass production for reduced
                            cost, imported from overseas and were not health
                            driven - It was supporting profits for the large
                            corporations.
                        </p>
                    </div>
                    <div className="w-full lg:w-1/2">
                        <img src={PersonalPic} alt="Damian and his brother" />
                    </div>
                </div>
                <div className="flex flex-col-reverse lg:flex-row">
                    <div className="flex flex-col justify-center w-full lg:w-1/2">
                        <video width="100%" height="100%" controls>
                            <source
                                src="https://cdn.butchercrowd.com.au/static/whyus/video-butcher.mp4"
                                type="video/mp4"
                            />
                            <track default kind="captions" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="flex flex-col justify-center flex-1 w-full py-12 px-14 sm:p-0 sm:py-12 sm:pl-20 md:py-6 lg:py-0 xl:pl-40">
                        <img
                            className="h-20 w-36"
                            src={Sunrise}
                            alt="sunrise logo"
                        />
                        <h3 className="mt-4 text-3xl font-bold sm:w-1/2">
                            Did you catch us on Sunrise?
                        </h3>
                    </div>
                </div>
                <div>
                    <AsSeenOn />
                </div>
            </div>
        </>
    );
}
