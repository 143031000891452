import { useQuery } from '@tanstack/react-query';
import client from './client';

export const fetchFaq = () => client.get('/api/faq');

export function useFaq() {
    const query = useQuery(
        ['faq'],
        async () => {
            const { data } = await fetchFaq();
            return data;
        },
        {
            retry: false,
        }
    );
    const { data: faqResult, ...queryResult } = query;
    return { faqResult, ...queryResult };
}
