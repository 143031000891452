import { useFormikContext } from 'formik';

export default function FormStatus() {
    const { status } = useFormikContext<any>();
    if (status?.success) {
        const success = status.success as string;
        return <p className="my-4 text-sm text-green-600">{success}</p>;
    }
    if (status?.error) {
        const error = status.error as string;
        return <p className="my-4 text-sm text-red-600">{error}</p>;
    }
    return null;
}
