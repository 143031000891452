import { useEffect, useRef } from 'react';

export default function useWindowResize(callback: any) {
    // Keep a reference to our callback.
    const callbackRef = useRef(callback);

    // Update the callback ref.
    useEffect(() => {
        callbackRef.current = callback;
        callback(window.innerWidth, window.innerHeight);
    }, [callback]);

    // Subscribe to event listeners.
    useEffect(() => {
        function onResize() {
            callbackRef.current(window.innerWidth, window.innerHeight);
        }
        window.addEventListener('resize', onResize);
        return function cleanup() {
            window.removeEventListener('resize', onResize);
        };
    }, []);
}
