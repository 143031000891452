import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ChevronLeftIcon } from '@/components/common/Icons';
import ChooseYourCurratedBoxForm from '@/components/forms/getStarted/ChooseYourCurratedBoxForm';
import RedirectAfterErrorModal from '@/components/modals/RedirectAfterErrorModal';
import useHandleSubmit from '@/hooks/useHandleSubmit';
import BoxTypeFormModel, { boxTypeFormSchema } from '@/models/BoxTypeFormModel';
import { useTrackSelection, useTrackViewItemList } from '@/services/dataLayer';
import {
    useChildPackages,
    useModifyGetStartedPackages,
} from '@/services/deliveryZoneService';
import {
    useKlaviyoInitiateChooseCuratedBox,
    useKlaviyoRequestError,
} from '@/services/klaviyo';
import {
    setUnauthenticated,
    setUnexpectedError,
} from '@/store/getStarted/getStartedErrorSlice';
import { setLoading } from '@/store/getStarted/getStartedLoadingSlice';
import { setStep } from '@/store/getStarted/getStartedSlice';
import { useUser } from '@/store/user/userSlice';
import BoxType from '@/types/BoxType';

export default function ChoosCuratedBoxTypePage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [params] = useSearchParams();
    const type = params.get('type') as BoxType;
    const [user] = useUser();
    const [selected, setSelected] = useState<string>();
    const { childPackagesResult, error: getPackagesError } = useChildPackages(
        user?.subscription?.id,
        type
    );
    const { mutateAsync: modifyGetStartedPackages } =
        useModifyGetStartedPackages(user?.subscription?.id);

    const trackViewItemList = useTrackViewItemList;
    const trackSelection = useTrackSelection;
    const klaviyoInitiateChooseCuratedBox = useKlaviyoInitiateChooseCuratedBox;
    const klaviyoRequestError = useKlaviyoRequestError;

    window.onpageshow = function resetLoading(event) {
        if (event.persisted) {
            dispatch(setLoading(false));
        }
    };

    const handleSubmit = useHandleSubmit(
        boxTypeFormSchema,
        modifyGetStartedPackages,
        () => {
            trackSelection(
                childPackagesResult?.packages?.filter(
                    (e: any) => e.id === selected
                ),
                'Box-Type'
            );
            window.location.href = `/get-started/box-size?type=${selected}`;
        },
        dispatch
    );

    useEffect(() => {
        if (childPackagesResult) {
            trackViewItemList(childPackagesResult.packages, 'Box-Type');
        }
    }, [selected, childPackagesResult, trackViewItemList, trackSelection]);

    useEffect(() => {
        if ((getPackagesError as any)?.response?.status) {
            klaviyoRequestError(user, getPackagesError);
            if (
                (getPackagesError as any)?.response?.status === 401 ||
                (getPackagesError as any)?.response?.status === 419
            ) {
                dispatch(setUnauthenticated(true));
            } else {
                dispatch(setUnexpectedError(true));
            }
        }
        dispatch(setStep({ index: 1, label: 'Box size' }));
    }, [dispatch, getPackagesError, user, klaviyoRequestError]);
    // Navigate back if we didn't select a box type.
    useEffect(() => {
        if (!type) navigate('/get-started/your-box');
    }, [type, navigate]);

    useEffect(() => {
        klaviyoInitiateChooseCuratedBox(user);
    }, [user, klaviyoInitiateChooseCuratedBox]);

    // Do not render the page, we will navigate back shortly.
    if (!type) return null;

    // const nextStep = (values: Partial<BoxSizeFormModel>) => {
    //     if (type === 'custom') {
    //         navigate(`/get-started/your-items?size=${values.boxSize}`);
    //     } else {
    //         navigate(`/get-started/curated-box?size=${values.boxSize}`);
    //     }
    // };

    return (
        <>
            <Helmet>
                <title>Choose Your Box | ButcherCrowd</title>
                <meta
                    name="description"
                    content="Curated or customised meat & seafood boxes delivered for your everyday cooking essentials"
                />
                <meta
                    property="og:title"
                    content="Choose Your Box | ButcherCrowd"
                />
            </Helmet>
            <div>
                <a
                    href="/get-started/your-box"
                    className="flex justify-center mt-10 sm:ml-14 sm:justify-start"
                >
                    <ChevronLeftIcon />
                    <p className="ml-5 -mt-0.5 text-sm font-medium uppercase">
                        Choose Your Box
                    </p>
                </a>
                <div className="flex flex-col items-center max-w-3xl px-8 py-12 mx-auto lg:py-28">
                    <h1 className="text-4xl font-medium text-center uppercase mb-7 lg:text-5xl">
                        {childPackagesResult?.name}
                    </h1>
                    <p className="mb-24 text-center lg:mb-16 lg:text-lg lg:font-normal">
                        Love a surprise, or simply too busy? Let us handle the
                        details with our curated boxes. Contents change monthly,
                        so you&apos;ll never be bored with the same meals on
                        repeat. This is a great option to explore different cuts
                        of high-quality meat.
                    </p>
                    <Formik<Partial<BoxTypeFormModel>>
                        enableReinitialize
                        initialValues={{}}
                        validationSchema={boxTypeFormSchema}
                        onSubmit={handleSubmit}
                    >
                        <ChooseYourCurratedBoxForm
                            selected={selected}
                            setSelected={setSelected}
                            childPackagesResult={childPackagesResult}
                        />
                    </Formik>
                </div>
            </div>
            <RedirectAfterErrorModal />
        </>
    );
}
