import { useState } from 'react';
import { Helmet } from 'react-helmet';
import AsSeenOn from '@/components/common/AsSeenOn';
import DeliveryRegionsResults from '@/components/common/DeliveryRegionsResults';
import Wrapper from '@/components/common/Wrapper';
import DashboardSelect from '@/components/inputs/DashboardSelect';
import Breadcrumbs from '@/components/navigation/Breadcrumbs';
import useDeliveryRegionsGroups from '@/hooks/useDeliveryRegionsGroups';

const OGImage = `${window.cdn}smo/SMO-delivery.png`;

export default function DeliveryPage() {
    const routes = [
        {
            route: '/delivery',
            title: 'Our Delivery Regions',
        },
    ];
    const deliveryRegionsGroups = useDeliveryRegionsGroups();
    const [selectedRegionGroup, setSelectedRegionGroup] = useState(
        deliveryRegionsGroups[0]
    );

    const handleChangeRegion = (e: any) => {
        const selectedIndex = deliveryRegionsGroups.findIndex(
            (x) => x.value === e
        );
        setSelectedRegionGroup(deliveryRegionsGroups[selectedIndex]);
    };

    return (
        <>
            <Helmet>
                <title>Our Delivery Regions | ButcherCrowd</title>
                <meta
                    name="description"
                    content="Curated or customised meat & seafood boxes delivered for your everyday cooking essentials"
                />
                <meta
                    property="og:title"
                    content="Our Delivery Regions | ButcherCrowd"
                />
                <meta
                    property="og:description"
                    content="Curated or customised meat & seafood boxes delivered for your everyday cooking essentials"
                />
                <meta property="og:image" content={OGImage} />
            </Helmet>
            <Wrapper>
                <div className="px-8 pt-8 lg:mx-7">
                    <Breadcrumbs crumbs={routes} />
                </div>
                <div className="mx-auto max-w-[calc(100%_-_30px)] pt-14 sm:pt-20">
                    <h3 className="text-center text-4xl font-medium md:text-5xl">
                        Our Delivery Regions
                    </h3>
                    <p className="m-auto w-full pb-10 pt-5 text-center text-lg font-normal sm:w-[80%] xl:w-[43%]">
                        It is always FREE Delivery with ButcherCrowd!
                        <br />
                        No minimum order is required.
                    </p>
                </div>
                <div className="m-auto w-[660px] max-w-[calc(100%_-_30px)]">
                    <div className="bg-[#EFEFEF] p-6">
                        <p className="text-lg leading-tight">
                            <strong> COVID-19 Update: </strong>For the safety of
                            you and our drivers, we have introduced contact-free
                            delivery. Drivers will deliver your order to your
                            doorstep, lobby, or other area designated by you to
                            avoid any unnecessary contact. You will be notified
                            by SMS once delivery is made.
                        </p>
                        <p className="pt-4 text-[16px] leading-tight">
                            PLEASE NOTE: Delivery to a lobby means - an attempt
                            to enter the lobby will be made, in the event no
                            access is granted; it will be left at the lobby
                            entrance.
                        </p>
                    </div>
                    <div className="mt-10">
                        <p className="font-bold">
                            Select your preferred delivery date and enjoy the
                            convenience of FREE delivery across Australia,
                            covering all States and Territories. Confirm
                            delivery availability in your area by using our
                            postcode checker at{' '}
                            <a
                                href="https://www.butchercrowd.com.au/get-started"
                                className="white text-black transition hover:text-primary"
                            >
                                www.butchercrowd.com.au/get-started
                            </a>
                            &nbsp; or referring to the listed areas below.
                        </p>
                        <div className="mt-10">
                            <p className="pt-4">
                                To keep you informed, expect to receive a text
                                message on the evening before your scheduled
                                delivery. This message will include information
                                about the estimated delivery time for the
                                following day, along with tracking details. Once
                                your package has been successfully delivered,
                                you will receive a text notification confirming
                                its arrival at your specified address.
                            </p>
                            <p className="pt-4">
                                ** For our valued customers in the Northern
                                Territory, deliveries are conducted monthly.
                                Please check the postcode checker or the
                                provided schedule below.
                            </p>
                            <p className="pt-4">
                                For any enquiries, do not hesitate to contact us
                                at{' '}
                                <a
                                    className="font-bold transition hover:text-primary"
                                    href="mailto:support@butchercrowd.com.au"
                                >
                                    support@butchercrowd.com.au
                                </a>{' '}
                                or phone{' '}
                                <a
                                    className="font-bold transition hover:text-primary"
                                    href="tel:1300998999"
                                >
                                    1300 998 999
                                </a>
                                .
                            </p>
                        </div>
                    </div>
                </div>
                {/* gray box */}
            </Wrapper>
            <div className="mt-24 w-full bg-[#EFEFEF] py-20">
                <div className="mx-auto  w-[1167px] max-w-[calc(100%_-_30px)]">
                    <div className="mx-auto mb-10">
                        <h2 className="mb-7 text-center font-neue text-3xl font-bold">
                            Find your delivery region
                        </h2>
                        <div className="mx-auto w-[510px] max-w-full">
                            <DashboardSelect
                                placeholder="Choose a region"
                                onChange={handleChangeRegion}
                                value={selectedRegionGroup.value}
                                options={deliveryRegionsGroups}
                            />
                        </div>
                    </div>
                    <DeliveryRegionsResults
                        selected={selectedRegionGroup.label}
                        results={selectedRegionGroup.data}
                    />
                </div>
            </div>
            <div>
                <AsSeenOn />
            </div>
        </>
    );
}
