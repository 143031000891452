import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ChevronLeftIcon } from '@/components/common/Icons';
import ChooseYourBoxSizeForm from '@/components/forms/getStarted/ChooseYourBoxSizeForm';
import ThreeDotsLoader from '@/components/loaders/ThreeDotsLoader';
import RedirectAfterErrorModal from '@/components/modals/RedirectAfterErrorModal';
import useHandleSubmit from '@/hooks/useHandleSubmit';
import BoxSizeFormModel, { boxSizeFormSchema } from '@/models/BoxSizeFormModel';
import { useTrackSelection, useTrackViewItemList } from '@/services/dataLayer';
import {
    useChildPackages,
    useDeliveryFrequency,
    useModifyGetStartedPackages,
} from '@/services/deliveryZoneService';
import {
    useKlaviyoInitiateChooseBoxSize,
    useKlaviyoRequestError,
} from '@/services/klaviyo';
import {
    setUnauthenticated,
    setUnexpectedError,
} from '@/store/getStarted/getStartedErrorSlice';
import {
    selectLoading,
    setLoading,
} from '@/store/getStarted/getStartedLoadingSlice';
import { setStep } from '@/store/getStarted/getStartedSlice';
import { useUser } from '@/store/user/userSlice';
import BoxType from '@/types/BoxType';

export default function ChooseBoxSize() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [params] = useSearchParams();
    const type = params.get('type') as BoxType;
    const [user] = useUser();
    const [selected, setSelected] = useState<string>();
    const [deliveryFrequency, setDeliveryFrequency] = useState<any>([]);
    const loading = useSelector(selectLoading);
    const { deliveryFrequencyResult } = useDeliveryFrequency(
        user?.subscription?.id,
        true
    );
    const { childPackagesResult, error: getPackagesError } = useChildPackages(
        user?.subscription?.id,
        type
    );

    const { mutateAsync: modifyGetStartedPackages } =
        useModifyGetStartedPackages(user?.subscription?.id);

    const trackViewItemList = useTrackViewItemList;
    const trackSelection = useTrackSelection;

    const handleSubmit = useHandleSubmit(
        boxSizeFormSchema,
        modifyGetStartedPackages,
        () => {
            trackSelection(
                childPackagesResult?.packages?.filter(
                    (e: any) => e.id === selected
                ),
                'Box-Size'
            );
            if (childPackagesResult?.selection?.customizable) {
                window.location.href = `/get-started/your-items`;
            } else {
                window.location.href = `/get-started/addons`;
            }
        },
        dispatch
    );

    window.onpageshow = function resetLoading(event) {
        if (event.persisted) {
            dispatch(setLoading(false));
        }
    };

    const klaviyoInitiateChooseBoxSize = useKlaviyoInitiateChooseBoxSize;
    const klaviyoRequestError = useKlaviyoRequestError;

    useEffect(() => {
        setDeliveryFrequency(
            deliveryFrequencyResult?.map((e: any, index: number) => ({
                ...e,
                id: index + 1,
                label: `${e.days / 7} ${e.days === 7 ? 'week' : 'weeks'}`,
                value: `${e.days}`,
            }))
        );
    }, [deliveryFrequencyResult]);

    useEffect(() => {
        if ((getPackagesError as any)?.response?.status) {
            klaviyoRequestError(user, getPackagesError);
            if (
                (getPackagesError as any)?.response?.status === 401 ||
                (getPackagesError as any)?.response?.status === 419
            ) {
                dispatch(setUnauthenticated(true));
            } else {
                dispatch(setUnexpectedError(true));
            }
        }
        dispatch(setStep({ index: 1, label: 'Box size' }));
        localStorage.removeItem('subscriptionId');
    }, [dispatch, getPackagesError, user, klaviyoRequestError]);
    // Navigate back if we didn't select a box type.
    useEffect(() => {
        if (!type) navigate('/get-started/your-box');
    }, [type, navigate]);

    useEffect(() => {
        if (childPackagesResult?.packages) {
            trackViewItemList(childPackagesResult?.packages, 'Box-Size');
        }
        if (childPackagesResult?.name) {
            klaviyoInitiateChooseBoxSize(user, childPackagesResult?.name);
        }
    }, [
        user,
        klaviyoInitiateChooseBoxSize,
        childPackagesResult,
        trackViewItemList,
    ]);

    // Do not render the page, we will navigate back shortly.
    if (!type) return null;

    const initialValue = deliveryFrequencyResult?.filter(
        (e: any) => e.days === 28
    );

    return (
        <>
            <Helmet>
                <title>Choose Your Box | ButcherCrowd</title>
                <meta
                    name="description"
                    content="Curated or customised meat & seafood boxes delivered for your everyday cooking essentials"
                />
                <meta
                    property="og:title"
                    content="Choose Your Box | ButcherCrowd"
                />
            </Helmet>
            <div className="relative">
                <ThreeDotsLoader visible={loading} />
                <div className="mx-auto flex w-[calc(100%_-_60px)] justify-center md:justify-start">
                    <a
                        href="/get-started/your-box"
                        className="inline-flex justify-center mt-10 group sm:justify-start"
                    >
                        <ChevronLeftIcon />
                        <p className="ml-5 -mt-0.5 text-sm font-medium uppercase group-hover:underline">
                            Choose Your Box
                        </p>
                    </a>
                </div>
                <div className="mx-auto  flex w-[calc(100%_-_30px)] max-w-3xl flex-col items-center py-12 lg:py-28">
                    <h1 className="text-4xl font-medium text-center uppercase mb-7 lg:text-5xl">
                        {childPackagesResult?.name}
                    </h1>
                    <p className="mb-16 text-center lg:text-3xl lg:font-bold">
                        Time to choose your box size.
                    </p>
                    <Formik<Partial<BoxSizeFormModel>>
                        enableReinitialize
                        initialValues={{
                            frequency:
                                initialValue?.length > 0
                                    ? initialValue?.[0].days
                                    : deliveryFrequencyResult?.[0]?.days,
                        }}
                        validationSchema={boxSizeFormSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ isValid }) => (
                            <ChooseYourBoxSizeForm
                                isValid={isValid}
                                childPackagesResult={childPackagesResult}
                                deliveryFrequencyResult={deliveryFrequency}
                                selected={selected}
                                setSelected={setSelected}
                                startedAt={user?.subscription?.startedAt}
                                endedAt={user?.subscription?.endedAt}
                                defaultFrequency={
                                    initialValue?.length > 0
                                        ? initialValue?.[0].days
                                        : deliveryFrequencyResult?.[0]?.days
                                }
                            />
                        )}
                    </Formik>
                </div>
            </div>
            <RedirectAfterErrorModal />
        </>
    );
}
