import clsx from 'clsx';
import { NewProductIcon } from '@/components/common/Icons';

type ProductCardProps = {
    name: string;
    imgSrc: string;
    imgAlt: string;
    className?: string;
    isNew: boolean;
};

export default function ProductCard({
    name,
    imgSrc,
    imgAlt,
    className,
    isNew,
}: ProductCardProps) {
    return (
        <div className={clsx('w-full border border-system', className)}>
            <div className="flex h-20 flex-col justify-center bg-system px-3 text-xs font-medium uppercase !leading-tight text-white sm:text-sm lg:text-base">
                {name}
            </div>
            <div className="relative">
                <img
                    src={imgSrc}
                    alt={imgAlt}
                    aria-hidden="true"
                    className="aspect-[5_/_6] w-full object-cover object-center"
                />
                {isNew && (
                    <NewProductIcon className="absolute bottom-0 left-0" />
                )}
            </div>
        </div>
    );
}
