import clsx from 'clsx';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import BlogCard from '@/components/cards/BlogCard';
import RecipeCard from '@/components/cards/RecipeCard';
import AsSeenOn from '@/components/common/AsSeenOn';
import Button from '@/components/common/Button';
import Wrapper from '@/components/common/Wrapper';
import Breadcrumbs from '@/components/navigation/Breadcrumbs';
import {
    useFeatureRecipePosts,
    useHighlightRecipePost,
    useRecipePosts,
    useRecipesByCategory,
    useRecipesCategories,
} from '@/services/blogService';

const OGImage = `${window.cdn}smo/SMO-blog.png`;

interface Thumbnail {
    url: string;
    alt: string;
}

interface Author {
    id: number;
    avatar: string;
    name: string;
}

interface RecipeCardProps {
    id: number;
    title: string;
    slug: string;
    thumbnail: Thumbnail;
    author: Author;
    highlight: boolean;
    featured: boolean;
    categories?: Object[];
}

const routes = [
    {
        route: '/blog',
        title: 'Blog',
    },
];

const anchor = [
    { title: 'All', route: '' },
    { title: 'Recipes', route: 'recipes' },
    { title: 'News', route: 'news' },
];

export default function BlogCategoryPage() {
    const navigate = useNavigate();
    const { category } = useParams();
    const { recipesCategories } = useRecipesCategories();

    const categoryId = recipesCategories?.filter(
        (e: any) => e.slug === category
    );
    const { recipePostsResult } = useRecipePosts();
    const {
        recipesByCategoryPostsResult,
        fetchNextRecipesByCategoryPage,
        hasNextRecipesByCategoryPage,
    } = useRecipesByCategory(categoryId?.[0]?.id);
    // Return either one hightlight recipe or the recent one
    const { highlightRecipePostResult } = useHighlightRecipePost();

    const { featureRecipePostsResult } = useFeatureRecipePosts();
    // Return either one the recipes set to featured
    const featuredRecipes: any = () => {
        //  Return featured recipe
        if (featureRecipePostsResult?.length) {
            return featureRecipePostsResult;
        }
        // Fallback: return first recipe
        if (recipePostsResult?.pages[0]?.data) {
            return recipePostsResult.pages[0].data;
        }
        return null;
    };

    return (
        <Wrapper>
            <Helmet>
                <title>Blog | ButcherCrowd</title>
                <meta
                    name="description"
                    content="Curated or customised meat & seafood boxes delivered for your everyday cooking essentials"
                />
                <meta property="og:title" content="Blog | ButcherCrowd" />
                <meta
                    property="og:description"
                    content="Curated or customised meat & seafood boxes delivered for your everyday cooking essentials"
                />
                <meta property="og:image" content={OGImage} />
            </Helmet>
            <div className="hidden px-8 pt-8 sm:block lg:mx-7">
                <Breadcrumbs crumbs={routes} />
            </div>
            <div className="pt-12 text-5xl font-medium text-center uppercase sm:pt-0">
                Blog
            </div>
            <div className="flex flex-wrap justify-center mt-4 space-x-2 sm:mt-9">
                {anchor.map((e) => (
                    <button
                        key={e.title}
                        type="button"
                        className={clsx(
                            'bg-secondary px-5 py-1.5 font-agrandir text-sm font-bold uppercase text-white transition-colors hover:bg-red-600',
                            e.route === 'recipes' && 'bg-primary'
                        )}
                        onClick={() => {
                            navigate(`/blog/${e.route}`);
                        }}
                    >
                        {e.title}
                    </button>
                ))}
            </div>
            {recipesCategories && (
                <div className="flex flex-wrap justify-center mt-4 space-x-2 sm:mt-9">
                    {recipesCategories?.map((e: any) => (
                        <button
                            key={e.id}
                            type="button"
                            className={clsx(
                                'bg-secondary px-5 py-1.5 font-agrandir text-sm font-bold uppercase text-white transition-colors hover:bg-red-600',
                                category === e.slug && 'bg-primary'
                            )}
                            onClick={() => {
                                navigate(`/blog-recipes/${e.slug}`);
                            }}
                        >
                            {e.name}
                        </button>
                    ))}
                </div>
            )}
            <div className="flex flex-col-reverse md:grid md:grid-cols-3">
                <div className="mx-2 col-span-2 xs:mx-4 md:ml-3 md:mr-7 lg:mr-3 lg:ml-10 xl:ml-20">
                    <div>
                        {recipesByCategoryPostsResult?.pages?.map(
                            (group: any, i) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <div key={i}>
                                    {group?.data.map((e: RecipeCardProps) => (
                                        <BlogCard
                                            key={e.slug}
                                            title={e.title}
                                            slug={e.slug}
                                            thumbnail={e.thumbnail}
                                            fullName={e?.author?.name}
                                            avatar={e?.author?.avatar}
                                            categories={e?.categories}
                                            postType="Recipes"
                                            postUrl="recipe-post"
                                        />
                                    ))}
                                </div>
                            )
                        )}
                        <div className="flex justify-center mt-16 mb-16">
                            <Button
                                onClick={() => fetchNextRecipesByCategoryPage()}
                                disabled={!hasNextRecipesByCategoryPage}
                            >
                                Load more
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="col-span-1 mt-14 bg-[#212121] md:mt-0 md:-ml-10 md:mr-0 md:bg-white lg:ml-0 xl:mr-10">
                    {highlightRecipePostResult && (
                        <div>
                            <RecipeCard
                                title={highlightRecipePostResult?.title}
                                slug={highlightRecipePostResult?.slug}
                                thumbnail={highlightRecipePostResult?.thumbnail}
                            />
                        </div>
                    )}
                    {featuredRecipes() && (
                        <div className="mx-2 bg-white border border-black my-7 shadow-block xs:mx-4">
                            <div className="mx-2 mt-3 text-xl font-bold text-left uppercase border-b-4 border-black lg:text-2xl">
                                Recipes you&apos;ll love
                            </div>
                            {featuredRecipes().map(
                                (e: RecipeCardProps, i: number) => {
                                    if (i <= 4) {
                                        return (
                                            <a
                                                key={e.title}
                                                href={`/recipe-post/${e.slug}`}
                                                className="flex items-center mx-2 my-5"
                                            >
                                                <img
                                                    src={e.thumbnail.url}
                                                    alt={e.thumbnail.alt}
                                                    className="h-[100px] w-[145px] self-start object-cover md:h-[70px] md:w-[100px] lg:h-[100px] lg:w-[145px]"
                                                />
                                                <div className="ml-8 md:ml-4 xl:ml-8">
                                                    <div className="text-sm font-bold uppercase font-agrandir">
                                                        Porc
                                                    </div>
                                                    <div className="text-sm font-bold uppercase">
                                                        {e.title}
                                                    </div>
                                                </div>
                                            </a>
                                        );
                                    }
                                    return '';
                                }
                            )}
                        </div>
                    )}
                </div>
            </div>
            <AsSeenOn />
        </Wrapper>
    );
}
