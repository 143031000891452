import { useState } from 'react';
import Modal from '@/components/modals/Modal';
import ModalBoxSizeContent from '@/components/modals/ModalBoxSizeContent';
import { ChevronDownIcon } from './Icons';

type Props = {
    title: string;
    name?: string;
    description?: string;
    id: string;
    label?: string;
};

export default function HowItWorksBoxes({
    title,
    name,
    description,
    id,
    label,
}: Props) {
    const [showModal, setShowModal] = useState<boolean>(false);
    return (
        <>
            <div
                tabIndex={0}
                role="button"
                className="my-1 inline-flex h-9 items-center border border-solid border-black px-4 font-neue text-xs font-bold uppercase transition-colors hover:border-primary hover:text-primary"
                onClick={() => setShowModal(true)}
                onKeyDown={(event) => {
                    if (event.key === 'enter' || event.key === 'space')
                        setShowModal(true);
                }}
            >
                <span>{label}</span>
                <ChevronDownIcon className="ml-2 h-3 w-3 -rotate-90 transform" />
            </div>
            <Modal
                title={title}
                open={showModal}
                onClose={() => setShowModal(false)}
            >
                <ModalBoxSizeContent
                    description={description}
                    packageId={id}
                    childPackagesName={name}
                />
            </Modal>
        </>
    );
}
