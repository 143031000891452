import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ChooseYourBoxTypeForm from '@/components/forms/getStarted/ChooseYourBoxTypeForm';
import ThreeDotsLoader from '@/components/loaders/ThreeDotsLoader';
import RedirectAfterErrorModal from '@/components/modals/RedirectAfterErrorModal';
import useHandleSubmit from '@/hooks/useHandleSubmit';
import BoxTypeFormModel, { boxTypeFormSchema } from '@/models/BoxTypeFormModel';
import { useTrackSelection, useTrackViewItemList } from '@/services/dataLayer';
import {
    useModifyGetStartedPackages,
    usePackages,
} from '@/services/deliveryZoneService';
import {
    useKlaviyoInitiateChooseBoxType,
    useKlaviyoRequestError, // useKlaviyoSubmitChooseBoxTypeForm,
} from '@/services/klaviyo';
import {
    setUnauthenticated,
    setUnexpectedError,
} from '@/store/getStarted/getStartedErrorSlice';
import {
    selectLoading,
    setLoading,
} from '@/store/getStarted/getStartedLoadingSlice';
import { setStep } from '@/store/getStarted/getStartedSlice';
import { useUser } from '@/store/user/userSlice';

export default function ChooseBoxTypePage() {
    const [user] = useUser();
    const navigate = useNavigate();
    const [selected, setSelected] = useState<string>();
    const [selectedBox, setSelectedBox] = useState<string>();
    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const { packagesResult, error: getPackagesError } = usePackages(
        user?.subscription?.id,
        true
    );
    const { mutateAsync: modifyGetStartedPackages } =
        useModifyGetStartedPackages(user?.subscription?.id);

    const trackViewItemList = useTrackViewItemList;
    const trackSelection = useTrackSelection;
    const klaviyoInitiateChooseBoxType = useKlaviyoInitiateChooseBoxType;
    // const klaviyoSubmitChooseBoxTypeForm = useKlaviyoSubmitChooseBoxTypeForm;
    const klaviyoRequestError = useKlaviyoRequestError;

    window.onpageshow = function resetLoading(event) {
        if (event.persisted) {
            dispatch(setLoading(false));
        }
    };

    useEffect(() => {
        if (packagesResult) {
            trackViewItemList(packagesResult, 'Box-Type');
        }
    }, [selected, packagesResult, trackViewItemList, trackSelection]);

    useEffect(() => {
        if ((getPackagesError as any)?.response?.status) {
            klaviyoRequestError(user, getPackagesError);
            if (
                (getPackagesError as any)?.response?.status === 401 ||
                (getPackagesError as any)?.response?.status === 419
            ) {
                dispatch(setUnauthenticated(true));
            } else {
                dispatch(setUnexpectedError(true));
            }
        }
        dispatch(setStep({ index: 1, label: 'Your box' }));
    }, [dispatch, getPackagesError, user, klaviyoRequestError]);

    const handleSubmit = useHandleSubmit(
        boxTypeFormSchema,
        modifyGetStartedPackages,
        () => {
            trackSelection(
                packagesResult?.filter((e: any) => e.id === selected),
                'Box-Type'
            );
            // klaviyoSubmitChooseBoxTypeForm(user);
            if (selectedBox === 'Custom Box') {
                window.location.href = `/get-started/box-size?type=${selected}`;
            } else {
                window.location.href = `/get-started/curated-box?type=${selected}`;
            }
        },
        dispatch
    );

    useEffect(() => {
        if (!user?.subscription) {
            navigate('/get-started');
        } else {
            localStorage.setItem('subscriptionId', `${user?.subscription?.id}`);
        }
        if (
            user?.subscription?.postcode !==
            localStorage.getItem('subscriptionPostcode')
        ) {
            localStorage.removeItem('subscriptionPostcode');
        }
        klaviyoInitiateChooseBoxType(user);
    }, [user, navigate, klaviyoInitiateChooseBoxType]);
    // Do not render the page, we will navigate back shortly.
    if (!user?.subscription) return null;

    return (
        <>
            <Helmet>
                <title>Choose Your Box | ButcherCrowd</title>
                <meta
                    name="description"
                    content="Curated or customised meat & seafood boxes delivered for your everyday cooking essentials"
                />
                <meta
                    property="og:title"
                    content="Choose Your Box | ButcherCrowd"
                />
            </Helmet>
            <div className="relative">
                <ThreeDotsLoader visible={loading} />
                <div className="mx-auto flex w-[calc(100%_-_30px)] max-w-3xl flex-col items-center py-12 lg:py-28">
                    <h1 className="text-4xl font-medium text-center uppercase mb-7 lg:text-5xl">
                        Choose your box
                    </h1>
                    <div className="mb-16 text-center lg:text-lg">
                        ButcherCrowd sources from Australian farming & fishing
                        families, only the highest quality, ethically raised,
                        meat & seafood.
                    </div>
                    <Formik<Partial<BoxTypeFormModel>>
                        enableReinitialize
                        initialValues={{}}
                        validationSchema={boxTypeFormSchema}
                        onSubmit={handleSubmit}
                    >
                        <ChooseYourBoxTypeForm
                            selected={selected}
                            setSelected={setSelected}
                            setSelectedBox={setSelectedBox}
                            packagesResult={packagesResult}
                        />
                    </Formik>
                </div>
            </div>
            <RedirectAfterErrorModal />
        </>
    );
}
