import { Route } from 'react-router-dom';
import GetStartedLayout from '@/layouts/GetStartedLayout';
import ScrollToTopLayout from '@/layouts/ScrollToTopLayout';
import WebLayout from '@/layouts/WebLayout';
import AddonsPage from '@/pages/AddonsPage';
import BlogCategoryPage from '@/pages/BlogCategoryPage';
import BlogPage from '@/pages/BlogPage';
import CheckoutPage from '@/pages/CheckoutPage';
import ChooseBoxSizePage from '@/pages/ChooseBoxSizePage';
import ChooseBoxTypePage from '@/pages/ChooseBoxTypePage';
import ChooseCuratedBoxTypePage from '@/pages/ChooseCuratedBoxTypePage';
import ChooseItemsPage from '@/pages/ChooseItemsPage';
import DeliveryPage from '@/pages/DeliveryPage';
import ErrorPage from '@/pages/ErrorPage';
import FaqPage from '@/pages/FaqPage';
import ForgotPasswordPage from '@/pages/ForgotPasswordPage';
import GetStartedPage from '@/pages/GetStartedPage';
import GiftCardCheckoutPage from '@/pages/GiftCardCheckoutPage';
import GiftCardPage from '@/pages/GiftCardPage';
import GiftCardPersonalizePage from '@/pages/GiftCardPersonalizePage';
import HomePage from '@/pages/HomePage';
import HowItWorksPage from '@/pages/HowItWorksPage';
import NotFoundPage from '@/pages/NotFoundPage';
import PasswordResetPage from '@/pages/PasswordResetPage';
import PrivacyPolicyPage from '@/pages/PrivacyPolicyPage';
import RefundReturnsPolicyPage from '@/pages/RefundReturnsPolicyPage';
import SignInPage from '@/pages/SignInPage';
import SingleBlogPage from '@/pages/SingleBlogPage';
import SingleRecipePage from '@/pages/SingleRecipePage';
import SourcingPage from '@/pages/SourcingPage';
import TermsConditionsPage from '@/pages/TermsConditionsPage';
import WhyUsPage from '@/pages/WhyUsPage';

const webRoutes = (
    <Route
        path="/"
        errorElement={<ErrorPage />}
        element={<ScrollToTopLayout />}
    >
        <Route element={<WebLayout />}>
            <Route index element={<HomePage />} />
            <Route path="sign-in" element={<SignInPage />} />
            <Route path="forgot-password" element={<ForgotPasswordPage />} />
            <Route path="reset-password" element={<PasswordResetPage />} />
            <Route path="how-it-works" element={<HowItWorksPage />} />
            <Route path="why-us" element={<WhyUsPage />} />
            <Route path="sourcing" element={<SourcingPage />} />
            <Route path="delivery" element={<DeliveryPage />} />
            <Route path="blog" element={<BlogPage />}>
                <Route path=":filter" element={<BlogPage />} />
            </Route>
            <Route
                path="blog-recipes/:category"
                element={<BlogCategoryPage />}
            />
            <Route path="blog-post/:slug" element={<SingleBlogPage />} />
            <Route path="recipe-post/:slug" element={<SingleRecipePage />} />
            <Route path="faq" element={<FaqPage />} />
            <Route path="terms" element={<TermsConditionsPage />} />
            <Route
                path="refund-and-returns-policy"
                element={<RefundReturnsPolicyPage />}
            />
            <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="gift-cards" element={<GiftCardPage />}>
                <Route path=":filter" element={<GiftCardPage />}>
                    <Route path=":code" element={<GiftCardPage />} />
                </Route>
            </Route>
            <Route
                path="gift-cards/personalise"
                element={<GiftCardPersonalizePage />}
            />
            <Route
                path="gift-cards/checkout"
                element={<GiftCardCheckoutPage />}
            />
        </Route>
        <Route path="get-started" element={<GetStartedLayout />}>
            <Route index element={<GetStartedPage />} />
            <Route path="your-box" element={<ChooseBoxTypePage />} />
            <Route path="curated-box" element={<ChooseCuratedBoxTypePage />} />
            <Route path="box-size" element={<ChooseBoxSizePage />} />
            <Route path="your-items" element={<ChooseItemsPage />} />
            <Route path="addons" element={<AddonsPage />} />
            <Route path="checkout" element={<CheckoutPage />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
    </Route>
);

export default webRoutes;
