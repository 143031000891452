import { useMemo } from 'react';
import useWindowWidth from './useWindowWidth';

type Medias = {
    retinaL: string;
    retina: string;
    desktopXl: string;
    desktopL: string;
    desktop: string;
    tabletH: string;
    tabletV: string;
    mobile: string;
    mobileXS: string;
    mobileXSS: string;
};

const medias = {
    retinaL: '2200px',
    retina: '1920px',
    desktopXl: '1640px',
    desktopL: '1440px',
    desktop: '1220px',
    tabletH: '1024px',
    tabletV: '768px',
    mobile: '640px',
    mobileXS: '440px',
    mobileXSS: '370px',
};

export const breakpointsPx = Object.entries(medias).reduce(
    (obj, [k, v]) => ({
        ...obj,
        [k]: parseInt(v, 10),
    }),
    {}
) as { [key in keyof Medias]: number };

export default function useBreakpoints() {
    const width: number = useWindowWidth();
    const breakpoints = <Medias>useMemo(
        () =>
            Object.entries(breakpointsPx).reduce(
                (obj, [k, v]) => ({
                    ...obj,
                    [k]: width <= v,
                }),
                {}
            ),
        [width]
    );
    return breakpoints;
}
