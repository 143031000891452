import { useQuery } from '@tanstack/react-query';
import { Field, FieldProps, useFormikContext } from 'formik';
import { useEffect, useMemo, useRef, useState } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { useDispatch } from 'react-redux';
import Button from '@/components/common/Button';
import PaymentMethodButton from '@/components/common/PaymentMethodButton';
import { SingleCheckBox } from '@/components/inputs/CheckBoxGroup';
import DashboardSelect from '@/components/inputs/DashboardSelect';
import InputGroup from '@/components/inputs/InputGroup';
import TextInput from '@/components/inputs/TextInput';
import AlertModal from '@/components/modals/AlertModal';
import CheckoutGiftCardFormModel from '@/models/CheckoutGiftCardFormModel';
import { getPaymentMethods } from '@/services/paymentService';
import { setAttemptedGiftCardsCheckout } from '@/store/giftCards/giftCardsErrorSlice';
import { useUser } from '@/store/user/userSlice';
import Option from '@/types/Option';
import { makeDot } from '@/types/Path';
import StateType from '@/types/StateType';
import formatPlaceAddress from '@/utils/autoComplete';
import Form from '../Form';

type Props = {
    creditCardInvalid?: any;
    attemptedCheckout?: any;
};

const dot = makeDot<CheckoutGiftCardFormModel>();

export default function CheckoutGiftCardForm({
    creditCardInvalid,
    attemptedCheckout,
}: Props) {
    const dispatch = useDispatch();
    const [user] = useUser();
    const formik = useFormikContext();
    const { setFieldValue } = formik;
    const { data } = useQuery(['paymentMethods'], () => getPaymentMethods(), {
        enabled: user?.linked || attemptedCheckout,
        retry: false,
    });
    const paymentMethods = data?.data;
    const {
        placesService,
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
    } = usePlacesService({
        apiKey: (import.meta as any).env.VITE_GOOGLE_API_KEY,
    });

    const {
        values,
        errors: checkoutError,
        isValid,
        isSubmitting,
        setSubmitting,
    } = useFormikContext<CheckoutGiftCardFormModel>();

    const [confirmAddress, setConfirmAddress] = useState(false);
    const [openConfirmAddressModal, setConfirmAddressModal] = useState(false);

    const formatedAutoComplete: any = useRef({});
    const formatedPrediction: any = useRef({});

    useEffect(() => {
        if (paymentMethods?.length > 0) {
            setFieldValue(dot('existingCard'), true);
        }
    }, [paymentMethods, setFieldValue]);

    useEffect(() => {
        if (
            values?.expiration?.length === 1 &&
            values?.expiration[0] !== '1' &&
            values?.expiration[0] !== '0'
        ) {
            setFieldValue(dot('expiration'), `0${values?.expiration}`);
        }
    }, [values?.expiration, setFieldValue]);

    function setExpiration(e: any) {
        if (e.key !== 'Backspace' && e.key !== '/') {
            if (values?.expiration?.length === 2) {
                setFieldValue(dot('expiration'), `${values?.expiration}/`);
            }
        }
    }

    useEffect(() => {
        if (user.sms) {
            values.hasSms = user.sms;
        }
        if (user.newsletter) {
            values.hasNewsletter = user.newsletter;
        }
        if (attemptedCheckout === true) {
            values.hasPassword = attemptedCheckout;
            dispatch(setAttemptedGiftCardsCheckout(false));
            if (values.sms === true) {
                values.hasSms = attemptedCheckout;
            }
            if (values.newsletter === true) {
                values.hasNewsletter = attemptedCheckout;
            }
        }
    }, [values, user, attemptedCheckout, dispatch]);

    useEffect(() => {
        if (values?.existingCard === true && !values?.paymentMethod) {
            if (paymentMethods) {
                paymentMethods.forEach((e: any) => {
                    if (e.primary) {
                        setFieldValue(dot('paymentMethod'), e.id);
                    }
                });
            }
        } else if (values?.existingCard === false) {
            delete values.paymentMethod;
        }
    }, [values, paymentMethods, checkoutError, setFieldValue]);

    useEffect(() => {
        if (!isPlacePredictionsLoading) {
            getPlacePredictions({ input: values?.billingAddressLine_1 });
        }
        if (placePredictions.length) {
            placesService?.getDetails(
                {
                    placeId: placePredictions[0].place_id,
                },
                (placeDetails: any) => {
                    formatedPrediction.current =
                        formatPlaceAddress(placeDetails);
                }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values?.billingAddressLine_1]);

    useEffect(() => {
        if (
            Object.keys(formatedAutoComplete.current).length > 0 &&
            (values.postcode !== formatedAutoComplete.current?.postalCode ||
                values?.billingCity !== formatedAutoComplete.current?.city ||
                values?.billingState !== formatedAutoComplete.current?.state)
        ) {
            setConfirmAddress(true);
        } else if (
            Object.keys(formatedAutoComplete.current).length === 0 &&
            Object.keys(formatedPrediction.current).length > 0 &&
            (values.postcode !== formatedPrediction.current?.postalCode ||
                values?.billingCity !== formatedPrediction.current?.city ||
                values?.billingState !== formatedPrediction.current?.state)
        ) {
            setConfirmAddress(true);
        } else {
            setConfirmAddress(false);
        }
    }, [
        values.postcode,
        values?.billingState,
        values?.billingCity,
        formatedAutoComplete,
        formatedPrediction,
        isPlacePredictionsLoading,
    ]);

    const { ref: BillingPlaceAutoCompleteRef } = usePlacesWidget({
        apiKey: (import.meta as any).env.VITE_GOOGLE_API_KEY,
        onPlaceSelected: (place) => {
            formatedAutoComplete.current = formatPlaceAddress(place);
            if (values.postcode !== formatedAutoComplete.current?.postalCode) {
                setConfirmAddress(true);
            } else {
                setConfirmAddress(false);
            }
            if (formatedAutoComplete.current?.suite) {
                setFieldValue(
                    dot('suite'),
                    formatedAutoComplete.current?.suite
                );
            } else {
                setFieldValue(dot('suite'), '');
            }
            setFieldValue(
                dot('billingAddressLine_1'),
                formatedAutoComplete.current?.addressLine_1
            );
            setFieldValue(
                dot('billingState'),
                formatedAutoComplete.current?.state
            );
            setFieldValue(
                dot('postcode'),
                formatedAutoComplete.current?.postalCode
            );
            setFieldValue(
                dot('billingCity'),
                formatedAutoComplete.current?.city
            );
        },
        options: {
            types: ['address'],
            componentRestrictions: { country: 'au' },
        },
    });

    const stateOptions: Option<StateType>[] = useMemo(
        () => [
            {
                id: 1,
                label: 'Australian Capital Territory',
                value: 'ACT',
                precision: true,
            },
            {
                id: 2,
                label: 'New South Wales',
                value: 'NSW',
                precision: true,
            },
            {
                id: 3,
                label: 'Northern Territory',
                value: 'NT',
            },
            {
                id: 4,
                label: 'Queensland',
                value: 'QLD',
            },
            {
                id: 5,
                label: 'South Australia',
                value: 'SA',
            },
            {
                id: 6,
                label: 'Tasmania',
                value: 'TAS',
            },
            {
                id: 7,
                label: 'Victoria',
                value: 'VIC',
            },
            {
                id: 8,
                label: 'Western Australia',
                value: 'WA',
            },
        ],
        []
    );

    return (
        <>
            {!isValid && (
                <AlertModal
                    open={isSubmitting && checkoutError !== undefined}
                    onClose={setSubmitting}
                    title={`${Object.values(checkoutError)[0]}`}
                >
                    <div className="mt-8 grid grid-cols-2 gap-2">
                        <Button
                            type="button"
                            className="flex justify-center rounded bg-black !font-neue text-base font-medium normal-case"
                            onClick={() => setSubmitting(false)}
                        >
                            Ok
                        </Button>
                    </div>
                </AlertModal>
            )}
            <AlertModal
                open={openConfirmAddressModal}
                onClose={setConfirmAddressModal}
                title="We had trouble verifying your address. Continue if it's correct."
            >
                <div className="mt-8 flex flex-col">
                    <div className="bg-gray-200 p-3">
                        {(formatedAutoComplete || formatedPrediction) && (
                            <div className="text-sm text-primary">
                                {values?.billingAddressLine_1}
                                {', '}
                                {values?.billingCity}
                                {', '}
                                {values?.billingState}
                                {', '}
                                {values?.postcode}
                            </div>
                        )}
                        <Button
                            type="button"
                            className="mt-2 flex w-full justify-center rounded bg-black !font-neue text-base font-medium normal-case text-black hover:text-white"
                            onClick={() => {
                                setConfirmAddress(false);
                                setConfirmAddressModal(false);
                                const input = document.querySelector(
                                    `label[for=billingAddressLine_1_input], #billingAddressLine_1_input, [name=billingAddressLine_1]`
                                );
                                input?.scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'nearest',
                                    inline: 'nearest',
                                });
                                return Boolean(input);
                            }}
                        >
                            Change my address
                        </Button>
                    </div>
                    <Button
                        type="button"
                        className="mt-2 flex justify-center rounded !font-neue text-base font-medium normal-case"
                        onClick={() => {
                            formik.submitForm();
                            setConfirmAddressModal(false);
                        }}
                    >
                        Continue
                    </Button>
                </div>
            </AlertModal>
            <Form className="mx-3.5 border border-black py-2 px-4 sm:py-4 sm:px-8">
                <div className="mb-3 pt-3.5 text-base font-bold uppercase">
                    Your info
                </div>
                <div className="block grid-cols-6 gap-[0.7rem_1rem] md:grid">
                    <div className="col-span-3">
                        <InputGroup name={dot('firstName')}>
                            <TextInput placeholder="First name" />
                        </InputGroup>
                    </div>
                    <div className="col-span-3">
                        <InputGroup
                            className="pt-1.5 md:pt-0"
                            name={dot('lastName')}
                        >
                            <TextInput placeholder="Surname" />
                        </InputGroup>
                    </div>
                    <div className="col-span-3">
                        <InputGroup
                            className="pt-1.5 md:pt-0"
                            name={dot('phone')}
                        >
                            <TextInput placeholder="Mobile number" />
                        </InputGroup>
                    </div>
                    <div className="col-span-3">
                        <InputGroup
                            className="pt-1.5 md:pt-0"
                            name={dot('email')}
                        >
                            <TextInput
                                placeholder="Email"
                                type="email"
                                autoComplete="email"
                                disabled={!!user.email}
                            />
                        </InputGroup>
                    </div>
                    {!values?.hasPassword && (
                        <>
                            <div className="col-span-3">
                                <InputGroup
                                    className="pt-1.5 md:pt-0"
                                    name={dot('password')}
                                >
                                    <TextInput
                                        placeholder="Choose your password"
                                        type="password"
                                        autoComplete="password"
                                    />
                                </InputGroup>
                            </div>
                            <div className="col-span-3">
                                <InputGroup
                                    className="pt-1.5 md:pt-0"
                                    name={dot('passwordConfirmation')}
                                >
                                    <TextInput
                                        placeholder="Confirm your password"
                                        type="password"
                                        autoComplete="password"
                                    />
                                </InputGroup>
                            </div>
                        </>
                    )}
                </div>
                <div className="mt-6">
                    {!values?.hasSms && (
                        <InputGroup className="pt-2" name={dot('sms')}>
                            <SingleCheckBox label="I’m happy to receive SMS for exclusive promotions from ButcherCrowd" />
                        </InputGroup>
                    )}
                    {!values?.hasNewsletter && (
                        <InputGroup className="pt-2" name={dot('newsletter')}>
                            <SingleCheckBox label="Be part of the Crowd, to receive deals and promo codes by email." />
                        </InputGroup>
                    )}
                </div>
                <div
                    role="separator"
                    className="my-8 h-[5px] shrink-0 bg-secondary"
                />
                <div className="mb-3 text-base font-bold uppercase">
                    Billing address
                </div>
                <div className="block grid-cols-6 gap-[0.7rem_1rem] md:grid">
                    <div className="col-span-3">
                        <InputGroup name={dot('billingAddressLine_1')}>
                            <TextInput
                                id="billingAddressLine"
                                autoComplete="address"
                                placeholder="Street address"
                                placeAutoCompleteRef={
                                    BillingPlaceAutoCompleteRef
                                }
                            />
                        </InputGroup>
                    </div>
                    <div className="col-span-3">
                        <InputGroup
                            className="pt-1.5 md:pt-0"
                            name={dot('suite')}
                        >
                            <TextInput placeholder="Apartment, unit, or suite number" />
                        </InputGroup>
                    </div>
                    <div className="col-span-3">
                        <InputGroup
                            className="pt-2 sm:pt-0"
                            name={dot('billingCity')}
                        >
                            <TextInput placeholder="Suburb" />
                        </InputGroup>
                    </div>
                    <div className="col-span-3">
                        <InputGroup
                            className="pt-1.5 md:pt-0"
                            name={dot('postcode')}
                        >
                            <TextInput placeholder="Postal code" />
                        </InputGroup>
                    </div>
                    <div className="col-span-3">
                        <InputGroup
                            className="pt-2 sm:pt-0"
                            name={dot('country')}
                        >
                            <TextInput placeholder="Country" disabled />
                        </InputGroup>
                    </div>
                    <div className="col-span-3">
                        <Field name={dot('billingState')}>
                            {({ field, form }: FieldProps) => (
                                <InputGroup
                                    className="pt-2 sm:pt-0"
                                    name={dot('billingState')}
                                >
                                    <DashboardSelect
                                        placeholder="State"
                                        value={field.value}
                                        onChange={(value) =>
                                            form.setFieldValue(
                                                dot('billingState'),
                                                value
                                            )
                                        }
                                        options={stateOptions}
                                    />
                                </InputGroup>
                            )}
                        </Field>
                    </div>
                </div>
                <div
                    role="separator"
                    className="my-8 h-[5px] shrink-0 bg-secondary"
                />
                {paymentMethods?.length > 0 && !values?.existingCard && (
                    <Field name={dot('existingCard')}>
                        {({ form }: FieldProps) => (
                            <div className="pb-4">
                                <PaymentMethodButton
                                    name="Use saved card"
                                    onClick={() => {
                                        form.setFieldValue(
                                            dot('existingCard'),
                                            true
                                        );
                                    }}
                                />
                            </div>
                        )}
                    </Field>
                )}
                {values?.existingCard ? (
                    <div>
                        {paymentMethods.map((e: any) => (
                            <div key={e.id} className="pb-3">
                                <Field name={dot('paymentMethod')}>
                                    {({ form }: FieldProps) => (
                                        <PaymentMethodButton
                                            cardType={e.type}
                                            fourDigit={e.lastFour}
                                            month={e.month}
                                            year={e.year}
                                            selected={
                                                values?.paymentMethod === e.id
                                            }
                                            onClick={() => {
                                                form.setFieldValue(
                                                    dot('paymentMethod'),
                                                    e.id
                                                );
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                        ))}
                        <Field name={dot('existingCard')}>
                            {({ form }: FieldProps) => (
                                <PaymentMethodButton
                                    name="Use different credit card"
                                    onClick={() => {
                                        form.setFieldValue(
                                            dot('existingCard'),
                                            false
                                        );
                                    }}
                                />
                            )}
                        </Field>
                        {creditCardInvalid?.error?.number && (
                            <div className="mt-3 text-sm text-primary">
                                {creditCardInvalid?.error?.number}
                            </div>
                        )}
                    </div>
                ) : (
                    <div>
                        <div className="mb-3 text-base font-bold uppercase">
                            New credit card
                        </div>
                        <div className="block grid-cols-6 gap-[0.7rem_1rem] md:grid">
                            <div className="col-span-6">
                                <InputGroup name={dot('number')}>
                                    <TextInput
                                        placeholder="Credit card number"
                                        maxLength={16}
                                    />
                                </InputGroup>
                                {creditCardInvalid?.error?.number && (
                                    <div className="mt-3 text-sm text-primary">
                                        {creditCardInvalid?.error?.number}
                                    </div>
                                )}
                            </div>
                            <div className="col-span-3">
                                <InputGroup
                                    name={dot('expiration')}
                                    className="pt-1.5 md:pt-0"
                                >
                                    <TextInput
                                        onKeyUp={(e) => setExpiration(e)}
                                        maxLength={5}
                                        placeholder="MM/YY"
                                    />
                                </InputGroup>
                            </div>
                            <div className="col-span-3">
                                <InputGroup
                                    name={dot('cvc')}
                                    className="pt-1.5 md:pt-0"
                                >
                                    <TextInput
                                        placeholder="CVC"
                                        maxLength={4}
                                    />
                                </InputGroup>
                            </div>
                        </div>
                    </div>
                )}
                <div className="mt-8 flex flex-col items-center">
                    {confirmAddress ? (
                        <Button
                            wide
                            onClick={() =>
                                Object.keys(checkoutError).length > 0
                                    ? setSubmitting(true)
                                    : setConfirmAddressModal(true)
                            }
                        >
                            SUBMIT ORDER
                        </Button>
                    ) : (
                        <Button
                            type="submit"
                            wide
                            onClick={() =>
                                Object.keys(checkoutError).length > 0
                                    ? setSubmitting(true)
                                    : setSubmitting(false)
                            }
                        >
                            SUBMIT ORDER
                        </Button>
                    )}
                </div>
            </Form>
            <div className="mx-4 mb-4 pt-4 pb-8 text-[14px]">
                With this order, I agreed to the{' '}
                <a href="/terms" className="text-primary">
                    terms and conditions
                </a>
                , and{' '}
                <a href="/privacy-policy" className="text-primary">
                    privacy policy
                </a>{' '}
                or ButcherCrowd
            </div>
        </>
    );
}
