import { Helmet } from 'react-helmet';
import AsSeenOn from '@/components/common/AsSeenOn';
import Step from '@/components/common/Step';
import Wrapper from '@/components/common/Wrapper';
import Breadcrumbs from '@/components/navigation/Breadcrumbs';

const OGImage = `${window.cdn}smo/SMO-sourcing-page.png`;

const routes = [
    {
        route: '/sourcing',
        title: 'Sourcing',
    },
];

const beefList = [
    { text: '100% grass-fed and grass-finished' },
    { text: 'Pasture-raised' },
    { text: 'Humanely raised' },
    { text: 'No antibiotics or hormones used for growth promotion' },
];
const chickenList = [
    { text: 'Free-range & Pasture-raised' },
    {
        text: 'Humanely raised, access to roam, eating bugs, worms, insects, and grass, whilst being able to perch and have dust baths, expressing their natural tendencies',
    },
    { text: 'No antibiotics or hormones used for growth promotion, Non-GMO' },
    {
        text: 'Shelters provided for protection against predators and elements',
    },
];
const lambList = [
    { text: '100% grass-fed and grass-finished' },
    { text: 'Pasture-raised' },
    { text: 'Humanely raised' },
    { text: 'No antibiotics or hormones used for growth promotion' },
];
const porkList = [
    { text: 'Pasture-raised' },
    {
        text: 'Humanely raised, without the use of; Nose rings, teeth clipping, tail docking, or surgical castration',
    },
    { text: 'No antibiotics or hormones used for growth promotion' },
    {
        text: 'Protection from the elements, with insulated huts filled with deep straw bedding for comfort',
    },
];
const seafoodList = [
    { text: 'Wild-caught' },
    { text: 'Sustainably sourced' },
    { text: 'No artificial colouring' },
    { text: 'No antibiotics' },
];

export default function SourcingPage() {
    const CrewOfChicken = `${window.cdn}sourcing/crew-of-chicken.jpg`;
    const Boat = `${window.cdn}sourcing/boat.jpg`;
    const CrewOfCow = `${window.cdn}sourcing/crew-of-cow.jpg`;
    const CrewOfLamb = `${window.cdn}sourcing/crew-of-lamb.jpg`;
    const CrewOfPig = `${window.cdn}sourcing/crew-of-pig.jpg`;

    return (
        <>
            <Helmet>
                <title>Sourcing | ButcherCrowd</title>
                <meta
                    name="description"
                    content="Curated or customised meat & seafood boxes delivered for your everyday cooking essentials"
                />
                <meta property="og:title" content="Sourcing | ButcherCrowd" />
                <meta
                    property="og:description"
                    content="Curated or customised meat & seafood boxes delivered for your everyday cooking essentials"
                />
                <meta property="og:image" content={OGImage} />
            </Helmet>
            <div>
                <div
                    className="
                bg-[url(https://cdn.butchercrowd.com.au/static/sourcing/background-hero-section-portrait.jpg)]
                bg-contain
                bg-bottom
                bg-no-repeat
                pb-[100%]
                sm:h-[848px]
                sm:bg-[url(https://cdn.butchercrowd.com.au/static/sourcing/background-hero-section-landscape.jpg)]
                sm:bg-cover
                sm:bg-center
                sm:pb-0
                md:h-[1088px]
                xl:h-[1188px]
            "
                >
                    <Wrapper>
                        <div className="px-8 pt-8 pb-8 sm:block lg:mx-7">
                            <Breadcrumbs crumbs={routes} />
                        </div>
                    </Wrapper>
                    <div className="flex flex-col items-center px-4">
                        <div className="flex flex-col max-w-md mx-auto space-y-6 xl:max-w-2xl">
                            <p className="text-3xl font-bold text-center uppercase sm:text-4xl sm:normal-case">
                                Our Sourcing
                            </p>
                            <p className="text-base font-normal text-center sm:text-l">
                                We partner with Australian farming & fishing
                                families and producers who follow regenerative
                                and sustainable practices. Sourcing from
                                partners with aligned values ensures that our
                                animals have lived a life as nature intended,
                                stress free. They&apos;re raised humanely, with
                                no added nasties. Discover why we&apos;re the
                                trusted source of high-quality meat and seafood
                                for families across Australia.
                            </p>
                        </div>
                    </div>
                </div>
                <div>
                    <Step
                        title="100% Grass-Fed, Grass-Finished Beef"
                        text="Beef"
                        imageSrc={CrewOfCow}
                        imageAlt="A field of cow"
                        titleClassName="!text-base !font-bold pt-12 sm:pt-0"
                        descriptionClassName="!text-5xl !font-bold uppercase"
                        imageLeft
                        imageReverse
                        textLarge
                    >
                        <div className="pt-4 font-normal list-checkbox leading-5 sm:list-disc sm:font-medium">
                            {beefList.map((e, index) => (
                                <li
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={index}
                                    className="m-0 list-none bg-[url('https://cdn.butchercrowd.com.au/static/check.svg')] bg-left-top bg-no-repeat pl-10 pb-2.5"
                                >
                                    {e.text}
                                </li>
                            ))}
                        </div>
                    </Step>

                    <Step
                        title="FREE-RANGE & PASTURE-RAISED CHICKEN"
                        text="Chicken"
                        imageSrc={CrewOfChicken}
                        imageAlt="A field of chicken"
                        titleClassName="!text-base !font-bold pt-12 sm:pt-0"
                        descriptionClassName="!text-5xl !font-bold uppercase"
                        imageReverse
                        textLarge
                    >
                        <div className="pt-4 font-normal list-checkbox leading-5 sm:list-disc sm:font-medium xl:mr-0">
                            {chickenList.map((e, index) => (
                                <li
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={index}
                                    className="m-0 list-none bg-[url('https://cdn.butchercrowd.com.au/static/check.svg')] bg-left-top bg-no-repeat pl-10 pb-2.5"
                                >
                                    {e.text}
                                </li>
                            ))}
                        </div>
                    </Step>
                    <Step
                        title="100% Grass-Fed, Grass-Finished Lamb"
                        text="Lamb"
                        imageSrc={CrewOfLamb}
                        imageAlt="A field of lamb"
                        titleClassName="!text-base !font-bold pt-12 sm:pt-0"
                        descriptionClassName="!text-5xl !font-bold uppercase"
                        imageLeft
                        imageReverse
                        textLarge
                    >
                        <div className="pt-4 font-normal list-checkbox leading-5 sm:list-disc sm:font-medium">
                            {lambList.map((e, index) => (
                                <li // eslint-disable-next-line react/no-array-index-key
                                    key={index}
                                    className="m-0 list-none bg-[url('https://cdn.butchercrowd.com.au/static/check.svg')] bg-left-top bg-no-repeat pl-10 pb-2.5"
                                >
                                    {e.text}
                                </li>
                            ))}
                        </div>
                    </Step>
                    <Step
                        title="Free-Range, Pasture-Raised Pork"
                        text="Pork"
                        imageAlt="A field of pig"
                        imageSrc={CrewOfPig}
                        titleClassName="!text-base !font-bold pt-12 sm:pt-0"
                        descriptionClassName="!text-5xl !font-bold uppercase"
                        imageReverse
                        textLarge
                    >
                        <div className="pt-4 font-normal list-checkbox leading-5 sm:list-disc sm:font-medium xl:mr-0">
                            {porkList.map((e, index) => (
                                <li // eslint-disable-next-line react/no-array-index-key
                                    key={index}
                                    className="m-0 list-none bg-[url('https://cdn.butchercrowd.com.au/static/check.svg')] bg-left-top bg-no-repeat pl-10 pb-2.5"
                                >
                                    {e.text}
                                </li>
                            ))}
                        </div>
                    </Step>
                    <Step
                        title="Wild-Caught Seafood"
                        text="Seafood"
                        imageAlt="A boat sailing in the ocean"
                        imageSrc={Boat}
                        titleClassName="!text-base !font-bold pt-12 sm:pt-0"
                        descriptionClassName="!text-5xl !font-bold uppercase"
                        imageLeft
                        imageReverse
                        textLarge
                    >
                        <div className="pt-4 font-normal list-checkbox leading-5 sm:list-disc sm:font-medium">
                            {seafoodList.map((e, index) => (
                                <li // eslint-disable-next-line react/no-array-index-key
                                    key={index}
                                    className="m-0 list-none bg-[url('https://cdn.butchercrowd.com.au/static/check.svg')] bg-left-top bg-no-repeat pl-10 pb-2.5"
                                >
                                    {e.text}
                                </li>
                            ))}
                        </div>
                    </Step>
                </div>
                <AsSeenOn />
            </div>
        </>
    );
}
