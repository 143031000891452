import { Helmet } from 'react-helmet';
import FAQAccordion from '@/components/common/FAQAccordion';
import Wrapper from '@/components/common/Wrapper';
import Breadcrumbs from '@/components/navigation/Breadcrumbs';
import { useFaq } from '@/services/faqService';

const OGImage = `${window.cdn}smo/SMO-faq.png`;

const routes = [
    {
        route: '/faq',
        title: 'FAQ',
    },
];

export default function FaqPage() {
    const { faqResult } = useFaq();
    const faqCategories = [
        { slug: 'product', label: 'Product' },
        { slug: 'delivery', label: 'Delivery' },
        { slug: 'order', label: 'Order' },
        { slug: 'subscription', label: 'Subscription' },
    ];
    return (
        <Wrapper>
            <Helmet>
                <title>FAQ | ButcherCrowd</title>
                <meta
                    name="description"
                    content="Curated or customised meat & seafood boxes delivered for your everyday cooking essentials"
                />
                <meta property="og:title" content="FAQ | ButcherCrowd" />
                <meta
                    property="og:description"
                    content="Curated or customised meat & seafood boxes delivered for your everyday cooking essentials"
                />
                <meta property="og:image" content={OGImage} />
            </Helmet>
            <div className="hidden px-8 pt-8 sm:block lg:mx-7">
                <Breadcrumbs crumbs={routes} />
            </div>
            <div className="flex flex-col items-center px-4">
                <div className="sm:flex sm:flex-col-reverse">
                    <div className="mt-12 text-center text-5xl font-medium sm:mt-8">
                        Frequently Asked Questions
                    </div>
                    <div className="mt-5 flex flex-wrap justify-center">
                        <div className="border border-black px-3 py-1 text-center font-agrandir text-sm font-extrabold uppercase">
                            FAQ
                        </div>
                    </div>
                </div>
                <div className="mx-auto mt-16 w-[1200px] max-w-[calc(100%_-_30px)]">
                    <div className="mx-auto w-full max-w-[800px] text-center">
                        <div>
                            Please review our frequently asked questions below.
                            If you still have questions that aren&apos;t
                            addressed here, feel free to reach out via email at:{' '}
                            <a
                                className="font-bold"
                                href="mailto:support@butchercrowd.com.au"
                            >
                                support@butchercrowd.com.au
                            </a>{' '}
                            or phone:{' '}
                            <a className="font-bold" href="tel:1300998999">
                                1300 998 999
                            </a>
                        </div>
                    </div>
                    <div className="mt-4 flex flex-wrap items-start justify-center space-x-2 sm:mt-9">
                        {faqCategories?.map((e: any) => (
                            <a
                                className="mb-2 bg-secondary px-5 py-1.5 font-agrandir text-sm font-bold uppercase text-white transition-colors hover:bg-red-600"
                                key={`categories-label-${e.slug}`}
                                href={`#${e.slug}`}
                            >
                                {e.label}
                            </a>
                        ))}
                    </div>
                    <div className="pt-12 pb-20">
                        {faqCategories?.map((e: any) => (
                            <div
                                id={e.slug}
                                className="mb-20"
                                key={`faq-accordion-${e.slug}`}
                            >
                                <h4 className="mb-8 w-full border-b-4 border-black py-2 font-bold uppercase">
                                    {e.label}
                                </h4>
                                <FAQAccordion>
                                    <div>
                                        {faqResult
                                            ?.filter(
                                                (faqGroups: any) =>
                                                    faqGroups.category ===
                                                    e.slug
                                            )
                                            .map((singleFaq: any) => (
                                                <FAQAccordion.Section
                                                    value={String(singleFaq.id)}
                                                    key={`${singleFaq.category}-${singleFaq.id}`}
                                                    title={singleFaq.question}
                                                >
                                                    <div
                                                        className="font-normal"
                                                        // eslint-disable-next-line react/no-danger
                                                        dangerouslySetInnerHTML={{
                                                            __html: singleFaq?.answer,
                                                        }}
                                                    />
                                                </FAQAccordion.Section>
                                            ))}
                                    </div>
                                </FAQAccordion>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}
