import * as RadioGroup from '@radix-ui/react-radio-group';
import clsx from 'clsx';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '@/components/common/Button';
import { CheckIcon } from '@/components/common/Icons';
import Modal from '@/components/modals/Modal';

type Props = {
    title: string;
    text: string;
    value: string;
    className?: string;
    image: string;
    imageAlt: string;
    children?: React.ReactNode;
    withButton?: boolean;
    withCheckBox?: boolean;
    withShadow?: boolean;
    topSvg?: boolean;
    primary?: boolean;
};

export default function BoxCTACard({
    title,
    image,
    value,
    className,
    imageAlt,
    text,
    children,
    withButton,
    withCheckBox,
    withShadow,
    topSvg,
    primary,
}: Props) {
    const [showModal, setShowModal] = useState<boolean>(false);

    return (
        <div
            className={clsx(
                'md-auto relative mb-4 flex h-full w-full flex-col justify-between border border-black bg-white md:w-[340px] lg:w-[405px]',
                withShadow && 'sm:shadow-block',
                topSvg &&
                    'md:after:bg-[url("../svg/best-value-down-arrow.svg" dm:after:h-[95px] dm:after:right-[13px] after:absolute after:right-full after:top-[-71px] after:left-[-80px] after:h-[110px] after:w-[110px] after:bg-[url("../svg/best-value-left-arrow.svg")] after:bg-cover after:bg-no-repeat after:content-[""]'
            )}
        >
            <div className="mb-3">
                <div
                    className={clsx(
                        'w-full py-2',
                        primary ? 'bg-primary' : 'bg-black'
                    )}
                >
                    <h2 className="relative ml-4 font-neue text-2xl font-bold uppercase text-white">
                        {title}
                    </h2>
                </div>
                <img
                    src={image}
                    alt={imageAlt}
                    className="border-b-[1px] border-black"
                />
                <div className="relative flex flex-col bg-white">
                    {withCheckBox ? (
                        <div className="mt-4 flex flex-row justify-between p-8">
                            <div>
                                <h4 className="font-neue text-2xl font-medium">
                                    {text}
                                </h4>
                                <button
                                    type="button"
                                    className="mt-2 inline-block font-neue text-sm font-normal uppercase tracking-wider underline"
                                    onClick={() => setShowModal(true)}
                                >
                                    Learn more
                                </button>
                            </div>
                            <RadioGroup.Item
                                value={value}
                                className={clsx(
                                    'cursor-pointer active:outline active:outline-black',
                                    className
                                )}
                            >
                                <div className="flex flex-1 flex-col justify-between px-1 py-1">
                                    <div className="flex flex-row items-end">
                                        <div className="relative m-2 h-10 w-10 border border-black">
                                            <RadioGroup.Indicator>
                                                <CheckIcon className="absolute -top-4 -right-4 h-14 w-14" />
                                            </RadioGroup.Indicator>
                                        </div>
                                    </div>
                                </div>
                            </RadioGroup.Item>
                            <Modal
                                title={title}
                                open={showModal}
                                onClose={() => setShowModal(false)}
                            >
                                <h3 className="mb-2 text-2xl font-medium ">
                                    Build Your Own
                                </h3>
                                <p className="font-neue text-base text-black">
                                    This box allows for you to pick from the
                                    menu (8) selections for a Small box, and
                                    (16) selections for a Large box. Your chosen
                                    selections will auto-save, along with any
                                    optional Add-ons, and be billed to you on
                                    your chosen delivery frequency. These
                                    selections can be changed anytime prior to
                                    the bill date through your account
                                </p>
                                <ul className="ml-4 mt-4 list-disc font-neue text-base">
                                    <li>100% wild seafood</li>
                                    <li>Grass-Finished Beef 100% Grass-Fed</li>
                                    <li>100% Grass-Fed Lamb Free Range,</li>
                                    <li> Free Range, Pasture Raised Chicken</li>
                                    <li> Free Range, Pasture Raised Pork</li>
                                </ul>
                                <p className="mt-8 font-neue text-base">
                                    PLUS all ButcherCrowd packaging is 100%
                                    environmentally friendly, with certified
                                    carbon neutral products.
                                </p>
                            </Modal>
                        </div>
                    ) : (
                        <p className="m-auto mt-6 w-[calc(100%_-_30px)] font-neue sm:w-[calc(100%_-_50px)]">
                            {text}
                        </p>
                    )}
                </div>
            </div>
            <div className="relative flex flex-col bg-white">
                {children}
                {withButton && (
                    <div className="mx-auto mt-16 mb-4 flex w-[calc(100%_-_30px)] justify-center font-agrandir text-sm font-bold sm:mt-20 sm:mb-6 sm:w-[calc(100%_-_50px)]">
                        <Button asChild>
                            <Link
                                to="/get-started"
                                className="m-0 mt-8 flex w-full justify-center px-2 text-center sm:px-14 md:px-2 lg:px-12"
                            >
                                <span>Choose&nbsp;your&nbsp;plan</span>
                            </Link>
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}
