import { Helmet } from 'react-helmet';
import Wrapper from '@/components/common/Wrapper';
import Breadcrumbs from '@/components/navigation/Breadcrumbs';

const OGImage = `${window.cdn}smo/SMO-homepage.png`;

const routes = [
    {
        route: '/privacy-policy',
        title: 'Privacy Policy',
    },
];

const privacyPolicy = [
    {
        id: 1,
        title: 'We respect your privacy',
        paragraph: [
            {
                number: '1.1',
                text: 'ButcherCrowd respects your right to privacy and is committed to safeguarding the privacy of our customers and website visitors. We adhere to the Australian Privacy Principles contained in the Privacy Act 1988 (Cth). This policy sets out how we collect  and treat your personal information.',
            },
            {
                number: '1.2',
                text: '"Personal information" is information we hold which is identifiable as being about you.',
            },
        ],
    },
    {
        id: 2,
        title: 'Collection of personal information',
        paragraph: [
            {
                number: '2.1',
                text: ' ButcherCrowd will, from time to time, receive and store personal information you enter onto our website, provided to us directly or given to us in other forms.',
            },
            {
                number: '2.2',
                text: 'You may provide basic information such as your name, phone number, address and email address to enable us to send information, provide updates and process your product or service order. We may collect additional information at other times, including  but not limited to, when you provide feedback, when you provide information about your personal or business affairs, change your content or email preference, respond to surveys and/or promotions, provide financial or credit card information, or communicate  with our customer support.',
            },
            {
                number: '2.3',
                text: 'Additionally, we may also collect any other information you provide while interacting with us.',
            },
        ],
    },
    {
        id: 3,
        title: 'How we collect your personal information',
        paragraph: [
            {
                number: '3.1',
                text: 'ButcherCrowd collects personal information from you in a variety of ways, including when you interact with us electronically or in person, when you access our website and when we provide our services to you. We may receive personal information from  third parties. If we do, we will protect it as set out in this Privacy Policy.',
            },
        ],
    },
    {
        id: 4,
        title: 'Use of your personal information',
        paragraph: [
            {
                number: '4.1',
                text: 'ButcherCrowd may use personal information collected from you to provide you with information, updates and our services. We may also make you aware of new and additional products, services and opportunities available to you. We may use your personal  information to improve our products and services and better understand your needs.',
            },
            {
                number: '4.2',
                text: 'ButcherCrowd may contact you by a variety of measures including, but not limited to telephone, email, sms or mail.',
            },
        ],
    },
    {
        id: 5,
        title: 'Disclosure of your personal information',
        paragraph: [
            {
                number: '5.1',
                text: 'We may disclose your personal information to any of our employees, officers, insurers, professional advisers, agents, suppliers or subcontractors insofar as reasonably necessary for the purposes set out in this Policy. Personal information is only  supplied to a third party when it is required for the delivery of our services.',
            },
            {
                number: '5.2',
                text: 'We may from time to time need to disclose personal information to comply with a legal requirement, such as a law, regulation, court order, subpoena, warrant, in the course of a legal proceeding or in response to a law enforcement agency request.',
            },
            {
                number: '5.3',
                text: 'We may also use your personal information to protect the copyright, trademarks, legal rights, property or safety of ButcherCrowd ,www.butchercrowd.com.au, its customers or third parties.',
            },
            {
                number: '5.4',
                text: 'Information that we collect may from time to time be stored, processed in or transferred between parties located in countries outside of Australia.',
            },
            {
                number: '5.5',
                text: 'If there is a change of control in our business or a sale or transfer of business assets, we reserve the right to transfer to the extent permissible at law our user databases, together with any personal information and non-personal information contained  in those databases. This information may be disclosed to a potential purchaser under an agreement to maintain confidentiality. We would seek to only disclose information in good faith and where required by any of the above circumstances.',
            },
            {
                number: '5.6',
                text: 'By providing us with personal information, you consent to the terms of this Privacy Policy and the types of disclosure covered by this Policy. Where we disclose your personal information to third parties, we will request that the third party follow  this Policy regarding handling your personal information.',
            },
        ],
    },
    {
        id: 6,
        title: 'Security of your personal information',
        paragraph: [
            {
                number: '6.1',
                text: 'ButcherCrowd is committed to ensuring that the information you provide to us is secure. In order to prevent unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure information  and protect it from misuse, interference, loss and unauthorised access, modification and disclosure.',
            },
            {
                number: '6.2',
                text: 'The transmission and exchange of information is carried out at your own risk. We cannot guarantee the security of any information that you transmit to us, or receive from us. Although we take measures to safeguard against unauthorised disclosures of  information, we cannot assure you that personal information that we collect will not be disclosed in a manner that is inconsistent with this Privacy Policy.',
            },
        ],
    },
    {
        id: 7,
        title: 'Access to your personal information',
        paragraph: [
            {
                number: '7.1',
                text: 'You may request details of personal information that we hold about you in accordance with the provisions of the Privacy Act 1988 (Cth). A small administrative fee may be payable for the provision of information. If you would like a copy of the information,  which we hold about you or believe that any information we hold on you is inaccurate, out of date, incomplete, irrelevant or misleading, please email us at Info@butchercrowd.com.au.',
            },
            {
                number: '7.2',
                text: 'We reserve the right to refuse to provide you with information that we hold about you, in certain circumstances set out in the Privacy Act.',
            },
        ],
    },
    {
        id: 8,
        title: 'Complaints about privacy',
        paragraph: [
            {
                number: '8.1',
                text: 'If you have any complaints about our privacy practices, please feel free to send in details of your complaints to PO Box 404, Smithfield , Queensland, 4878. We take complaints very seriously and will respond shortly after receiving written notice of  your complaint.',
            },
        ],
    },
    {
        id: 9,
        title: 'Changes to Privacy Policy',
        paragraph: [
            {
                number: '9.1',
                text: 'Please be aware that we may change this Privacy Policy in the future. We may modify this Policy at any time, in our sole discretion and all modifications will be effective immediately upon our posting of the modifications on our website or notice board.  Please check back from time to time to review our Privacy Policy.',
            },
        ],
    },
];

export default function PrivacyPolicyPage() {
    return (
        <>
            <Helmet>
                <title>Privacy policy | ButcherCrowd</title>
                <meta
                    name="description"
                    content="Curated or customised meat & seafood boxes delivered for your everyday cooking essentials"
                />
                <meta
                    property="og:title"
                    content="Privacy policy | ButcherCrowd"
                />
                <meta
                    property="og:description"
                    content="Curated or customised meat & seafood boxes delivered for your everyday cooking essentials"
                />
                <meta property="og:image" content={OGImage} />
            </Helmet>
            <Wrapper>
                <div className="hidden px-8 pt-8 sm:block lg:mx-7">
                    <Breadcrumbs crumbs={routes} />
                </div>
                <div className="mx-4 mt-12 text-2xl font-medium sm:mt-8 md:mx-16 md:text-5xl lg:mx-40 xl:mx-80">
                    <h3 className="text-center text-3xl font-bold sm:text-4xl sm:normal-case">
                        Butchercrowd&apos;s privacy&nbsp;policy
                    </h3>
                    <div className="w-full pt-12 font-neue text-lg">
                        {privacyPolicy.map((e) => (
                            <div key={e.id} className="pb-12">
                                <div className="text-base font-bold leading-tight sm:pt-0 sm:leading-7">
                                    {e.title}
                                </div>
                                {e.paragraph.map((p, index) => (
                                    <div
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={index}
                                        className="mt-5 font-normal"
                                    >
                                        <span className="text-lg font-bold uppercase">
                                            {p.number}{' '}
                                        </span>
                                        <span className="text-base font-normal">
                                            {p.text}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        ))}

                        <div className="pb-12">
                            <div className="text-base font-bold  uppercase leading-tight sm:pt-0 sm:leading-7">
                                Website
                            </div>
                            <div className="mt-5 text-base font-normal">
                                When you come to our website (
                                <span className="font-bold">
                                    www.butchercrowd.com.au
                                </span>
                                ) we may collect certain information such as
                                browser type, operating system, website visited
                                immediately before coming to our site, etc. This
                                information is used in an aggregated manner to
                                analyse how people use our site, such that we
                                can improve our service. Cookies We may from
                                time to time use cookies on our website. Cookies
                                are very small files which a website uses to
                                identify you when you come back to the site and
                                to store details about your use of the site.
                                Cookies are not malicious programs that access
                                or damage your computer. Most web browsers
                                automatically accept cookies but you can choose
                                to reject cookies by changing your browser
                                settings. However, this may prevent you from
                                taking full advantage of our website. Our
                                website may from time to time use cookies to
                                analyses website traffic and help us provide a
                                better website visitor experience. In addition,
                                cookies may be used to serve relevant ads to
                                website visitors through third party services
                                such as Google Adwords. These ads may appear on
                                this website or other websites you visit.
                            </div>
                        </div>
                        <div className="pb-12">
                            <div className="text-base font-bold  uppercase leading-tight sm:pt-0 sm:leading-7">
                                Third party sites
                            </div>
                            <div className="mt-5 text-base font-normal">
                                Our site may from time to time have links to
                                other websites not owned or controlled by us.
                                These links are meant for your convenience only.
                                Links to third party websites do not constitute
                                sponsorship or endorsement or approval of these
                                websites. Please be aware that ButcherCrowd is
                                not responsible for the privacy practises of
                                other such websites. We encourage our users to
                                be aware, when they leave our website, to read
                                the privacy statements of each and every website
                                that collects personal identifiable information.
                                <p className="mt-10">Updated 01 July 2020</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Wrapper>
        </>
    );
}
